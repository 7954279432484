import React from "react";
import styles from "./styles.scss";

export const CardStatus = ({ color, text }) => (
  <strong className={styles.CardStatus} style={{ color }}>
    {text}
  </strong>
);

export default CardStatus;
