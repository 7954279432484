import React from "react";
import styles from "./styles.module.scss";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

export const Component = () => {
  return (
    <section className={styles.EmptyBasket}>
      <main>
        <FeatherIcon icon={"shopping-bag"} size={36} />
        <h1>Oh no, your bag is empty!</h1>
        <p>
          Continue shopping and add some items to your bag or save items in your
          wish list for later
        </p>

        <Link to="/saved-list" className={styles.ButtonPrimary}>
          View saved list
        </Link>
        <Link to="/shop" className={styles.ContinueShopping}>
          <FeatherIcon icon={"arrow-left"} size={16} />
          Continue shopping
        </Link>
      </main>
    </section>
  );
};

const EmptyBasket = () => {
  return <Component />;
};

export default EmptyBasket;
