import React from "react";
import styles from "./styles.module.scss";
import CommentList from "CommentList";
import { useParams } from "react-router";
import { likePost } from "actions";
import {
  useAuthToken,
  useIsOwnProfile,
  useLoggedInStatus,
  usePost,
} from "hooks";
import RelatedPosts from "RelatedPosts";
import Like from "Like";
import { ViewProfile } from "ProductPage";
import MailingList from "MailingList";

export const Component = ({
  post = {},
  author = {},
  image,
  body,
  comments = [],
  postId,
  isOwnProfile = false,
}) => {
  return (
    <section className={styles.PostPage}>
      <main>
        <img src={image} className={styles.Left} />
        <div className={styles.Right}>
          <ViewProfile
            username={author.username}
            profilePicture={author.profilePicture}
            deletePost={isOwnProfile}
            postId={postId}
          />
          <h1 className={styles.Title}>{body}</h1>
          <Like post={post} viewAll={false} />
          <CommentList
            comments={comments}
            desktop={true}
            postId={postId}
            limit={8}
          />
        </div>
      </main>
      <footer>
        <RelatedPosts />
        <MailingList />
      </footer>
    </section>
  );
};

const PostPage = () => {
  const { id } = useParams();
  const post = usePost(id);
  const isOwnProfile = useIsOwnProfile(post?.author?.username);
  return (
    <Component {...post} postId={id} post={post} isOwnProfile={isOwnProfile} />
  );
};

export default PostPage;
