import React from "react";
import { connect } from "react-redux";
import styles from "./styles.scss";
import { follow, unfollow } from "actions";
import { withRouter } from "react-router";

export const Follow = ({
  onFollow,
  onUnfollow,
  iAmFollowingThisUser,
  profileToFollow,
  isLoggedIn
}) => {
  if (!isLoggedIn) {
    return <></>
  }
  return <div className={styles.Follow}>
    {iAmFollowingThisUser ? (
      <button className="button" onClick={() => onUnfollow(profileToFollow)}>
        stop following
      </button>
    ) : (
      <button className="button" onClick={() => onFollow(profileToFollow)}>
        Follow
      </button>
    )}
  </div>
}

const mapStateToProps = (
  { profile, users, auth },
  {
    match: {
      params: { username }
    }
  }
) => {
  const userProfile = users[username] || profile;
  return {
    isLoggedIn: Boolean(auth.token),
    iAmFollowingThisUser: Boolean(
      profile.following.indexOf(userProfile._id) >= 0
    ),
    profileToFollow: userProfile._id
  };
};
const mapDispatchToProps = {
  onFollow: follow,
  onUnfollow: unfollow
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Follow)
);
