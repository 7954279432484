import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import styles from "../styles.module.scss";
import { post } from "App/api";
import Logo from "Logo";

const resetPasswordWithToken =
  ({ passwordResetCode, history, email }) =>
  (dispatch, getState) =>
    dispatch({
      type: "PASSWORD_RESET",
      payload: post({
        url: "/password-reset",
        body: {
          passwordResetCode,
          email,
          ...getState().form.passwordReset.values,
        },
      }).then((data) => {
        history.push("/login");
        return data;
      }),
    });

export const PasswordReset = ({
  onSubmit,
  history,
  pristine,
  submitting,
  passwordResetCode,
  email,
}) => (
  <form
    className={styles.PasswordReset}
    onSubmit={(event) => {
      event.preventDefault();
      onSubmit({ history, passwordResetCode, email });
    }}
  >
    <Logo />
    <h1>Reset your password</h1>
    <p>
      If you know your password <Link to={"/login"}>click here log in</Link>.
    </p>
    <section>
      <label htmlFor="password">Type a new password</label>
      <Field name="password" type="password" component="input" />
      <label htmlFor="confirm-password">Confirm your password</label>
      <Field name="confirm-password" type="password" component="input" />
      <button
        type="submit"
        disabled={pristine || submitting}
        className={"primary"}
      >
        Submit
      </button>
    </section>
    <p>
      Don't have an account? <Link to={"/sign-up"}>Sign up</Link>. .
    </p>
  </form>
);

const mapStateToProps = (state, { match: { params } }) => ({
  passwordResetCode: params.resetToken,
  email: params.email,
});
const mapDispatchToProps = {
  onSubmit: resetPasswordWithToken,
};

const Form = reduxForm({ form: "passwordReset" })(PasswordReset);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Form));
