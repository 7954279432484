import React from "react";
import CheckoutSection from "Payment/CheckoutSection";
import { CardElement } from "@stripe/react-stripe-js";
import styles from "./styles.module.scss";

let style = {
  base: {
    fontSize: "14px",
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a",
  },
};

const Main = ({ handleSubmit, loading }) => {
  return (
    <div>
      <div className={styles.CardElement}>
        <CardElement options={{ hidePostalCode: true }} />
      </div>
      {/* <CardNumberElement options={{ style }} />
      <CardExpiryElement options={{ style }} />
      <CardCvcElement options={{ style }} /> */}
      {loading ? (
        <button className={styles.ButtonPrimary} disabled={true}>
          Loading
        </button>
      ) : (
        <button className={styles.ButtonPrimary} onClick={handleSubmit}>
          Place order
        </button>
      )}
    </div>
  );
};

const Header = () => {
  return <span>Payment Details</span>;
};

export const Component = (props) => {
  return (
    <CheckoutSection Header={Header}>
      <Main {...props} />
    </CheckoutSection>
  );
};

const PaymentDetails = (props) => {
  return <Component {...props} />;
};

export default PaymentDetails;
