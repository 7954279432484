import React, { useState } from "react";
import styles from "./styles.module.scss";
import FeatherIcon from "feather-icons-react";
import { useNavigate } from "hooks";

export const initialState = [
  {
    name: "Shop",
    link: "/shop",
  },
  {
    name: "Collections",
    items: [
      {
        name: "Originals",
        link: "/shop/collections/originals",
      },
      {
        name: "Influencers",
        link: "/shop/collections/influencers",
      },
      {
        name: "Independents",
        link: "/shop/collections/independents",
      },
      {
        name: "Designers",
        link: "/shop/collections/designers",
      },
    ],
  },
  {
    name: "Ending soon",
    link: "/shop/ending-soon",
  },
  {
    name: "Follow",
    link: "/follow/gallery",
  },
  {
    name: "About",
    link: "/about-us",
  },
  {
    name: "Sustainability",
    link: "/sustainability",
  },
  // {
  //   name: "Press",
  //   link: "/press",
  // },
];

export const Component = ({ navState, toggle, open, navigate }) => {
  return (
    <nav className={styles.Nav}>
      <ul>
        {navState.map((item) => {
          if (item.items && item.items.length > 0) {
            return (
              <li key={item.name}>
                <button onClick={toggle}>
                  {item.name}
                  <FeatherIcon icon={"chevron-down"} size={12} />
                </button>
                {item.items && open ? (
                  <ul className={styles.SubMenu} onMouseLeave={toggle}>
                    {item.items.map((item) => {
                      return (
                        <li key={item.name}>
                          <button
                            onClick={() => {
                              toggle();
                              navigate(item.link);
                            }}
                          >
                            {item.name}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <></>
                )}
              </li>
            );
          } else {
            return (
              <li key={item.name}>
                <button
                  onClick={() => {
                    navigate(item.link);
                  }}
                >
                  {item.name}
                </button>
              </li>
            );
          }
        })}
      </ul>
    </nav>
  );
};

const Nav = () => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((state) => !state);

  const navigate = useNavigate();

  return (
    <Component
      navState={initialState}
      toggle={toggle}
      open={open}
      navigate={navigate}
    />
  );
};

export default Nav;
