import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import FeatherIcon from "feather-icons-react";
import { get } from "App/api";
import { toCurrency } from "helpers";
import { useNavigate } from "hooks";

export const Component = ({
  suggestions = [],
  results = [],
  search,
  clear,
  open,
  toggle,
  navigateAndHide,
  term,
}) => {
  const showPanel = suggestions.length + results.length > 0;
  if (!open && window.innerWidth < 600) {
    return <></>;
  }

  return (
    <div className={open ? styles.ContainerOpen : styles.ContainerClosed}>
      <header className={styles.MobileHeader}>
        <div>
          <button
            onClick={() => {
              clear();
              toggle();
            }}
          >
            <FeatherIcon icon={"x"} size={"16"} />
            Close
          </button>
        </div>
        <div>Search</div>
        <div></div>
      </header>
      <section
        className={showPanel ? styles.SearchBarExpanded : styles.SearchBar}
      >
        <div className={styles.Input}>
          <FeatherIcon icon={"search"} size={"16"} />
          <input
            onChange={search}
            placeholder={"Search for an item or artist..."}
            name="search"
            data-cy="search-bar"
          />

          {(term && term.length > 0) || showPanel ? (
            <FeatherIcon
              icon={"x"}
              size={"16"}
              className={styles.X}
              onClick={() => {
                clear();
                toggle();
                document.querySelector(`input[name="search"]`).value = "";
              }}
              role={"button"}
            />
          ) : (
            <></>
          )}
        </div>
        {showPanel && (
          <div className={styles.SearchResultsContainer}>
            <div className={styles.Suggestions}>
              <h3>Search suggestions</h3>
              <ul>
                {suggestions.map((s) => (
                  <li>
                    <button
                      data-cy="search-suggestion"
                      onClick={navigateAndHide(s.link)}
                    >
                      {s.username}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.SearchResults}>
              <ul>
                {results.map((r) => (
                  <li>
                    <div
                      data-cy="search-product"
                      role={"button"}
                      className={styles.Product}
                      onClick={navigateAndHide(`/products/${r._id}`)}
                    >
                      <img src={r.picture} />
                      <div>
                        <span className={styles.Title}>{r.title}</span>
                        <span className={styles.Price}>
                          {toCurrency(r.price)}
                        </span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

// TODO: what happens when no suggestions
// TODO: throttle onChange

const api = {
  search: (term) =>
    new Promise(async (resolve) => {
      if (!term) {
        resolve({});
      }

      const params = new URLSearchParams({
        term,
      });

      const data = await get({
        url: "/search?" + params,
      });
      resolve(data);
    }),
};

const useSearch = () => {
  const [suggestions, setSuggestions] = useState([]);
  const [results, setResults] = useState([]);
  const [term, setTerm] = useState("");
  const search = (e) => setTerm(e.target.value);
  const clear = () => setTerm("");
  const [throttle, setThrottle] = useState(new Date());

  useEffect(() => {
    // if (Math.abs(new Date().getTime() - throttle.getTime()) < 1000) {
    // debugger;
    // return;
    // }

    const act = async (term) =>
      await api.search(term).then(({ suggestions, results }) => {
        setSuggestions(suggestions);
        setResults(results);
      });

    if (term) {
      act(term);
      setThrottle(new Date());
    } else {
      setSuggestions([]);
      setResults([]);
    }
  }, [term]);
  return { suggestions, results, search, term, clear };
};

const SearchBar = ({ open, toggle }) => {
  const props = useSearch();
  const navigate = useNavigate();
  const navigateAndHide = (path) => () => {
    navigate(path);
    toggle();
  };
  return (
    <Component
      {...props}
      open={open}
      toggle={toggle}
      navigateAndHide={navigateAndHide}
    />
  );
};

export default SearchBar;
