import React from "react";
import styles from "./styles.scss";
import { connect } from "react-redux";

export const LoadingSpinner = ({ message = `Loading...`, style }) => (
  <main className={styles.LoadingSpinner} style={style}>
    <h1>{message}</h1>
    <i className="fa fa-spinner fa-5x" />
  </main>
);

export class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.regex = this.props.regex || new RegExp(/PENDING/);
  }

  render() {
    return this.props.loading &&
      this.regex &&
      this.regex.test(this.props.loading) ? (
      <LoadingSpinner
        message={this.props.message}
        style={{ height: this.props.autoHeight ? "auto" : "90vh" }}
      />
    ) : (
      this.props.children
    );
  }
}

export default connect(({ loading }) => ({ loading }))(Loading);
