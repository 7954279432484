import React from "react";
import { connect } from "react-redux";
import PhotoGrid from "PhotoGrid";

export const CampaignArchive = ({ onClick, archive }) => (
  <PhotoGrid
    images={archive.map((a) => ({
      image: a.design,
      link: `/product/${a._id}`,
    }))}
  />
);

const mapStateToProps = ({ campaigns }) => ({
  archive: campaigns.filter((c) => new Date(c.ends_at) < new Date()),
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(CampaignArchive);
