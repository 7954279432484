export const initialState = {};

const actionHandlers = {
  LOGOUT: (state, payload) => initialState,
  GET_ABOUT_US_FULFILLED: (state, { data }) => ({
    ...state,
    "about-us": data
  }),
  GET_TERMS_AND_CONDITIONS_FULFILLED: (state, { data }) => ({
    ...state,
    "terms-and-conditions": data
  })
};

export const reducer = (state = initialState, { type, payload }) =>
  actionHandlers[type] ? actionHandlers[type](state, payload) : state;

export default reducer;
