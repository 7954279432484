import React from "react";
import styles from "./styles.module.scss";
import CheckoutSection from "Payment/CheckoutSection";
import { TwoColInput, Select } from "FormStuff";
import { useToggle } from "hooks";
import { COUNTRIES_FOR_SELECT } from "listOfCountries";
import { Field } from "react-final-form";

const Main = () => {
  return (
    <form className={styles.Form}>
      <div className={styles.Main}>
        <label>
          <Field
            component={TwoColInput}
            name="first-name"
            placeholder="First name"
          />
          <Field
            component={TwoColInput}
            name="last-name"
            placeholder="Last name"
          />
        </label>
        {/* <label>
Calculate Postage
<Field component={TwoColInput}
name="postcode"
placeholder="Type your postcode or first line of address"
/>
</label> */}
        {/* <button onClick={toggle}>Enter address manually</button> */}
        {true && (
          <div>
            <div className={styles.SelectContainer}>
              <Field
                component={Select}
                name="delivery-address.country"
                options={COUNTRIES_FOR_SELECT}
              />
            </div>
            <div>
              <Field
                component={TwoColInput}
                name="delivery-address.line-1"
                placeholder="Address line 1"
              />
            </div>
            <div>
              <Field
                component={TwoColInput}
                name="delivery-address.line-2"
                placeholder="Address line 2"
              />
            </div>
            <div>
              <Field
                component={TwoColInput}
                name="delivery-address.city"
                placeholder="City"
              />
            </div>
            <div>
              <Field
                component={TwoColInput}
                name="delivery-address.postcode"
                placeholder="Postcode"
              />
            </div>
            <div>
              <Field
                component={TwoColInput}
                name="phone-number"
                placeholder="Phone number"
              />
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

const Header = () => {
  return <span>Delivery Address</span>;
};

export const Component = (props) => {
  return (
    <CheckoutSection Header={Header}>
      <Main {...props} />
    </CheckoutSection>
  );
};

const DeliveryAddress = (props) => {
  const [open, toggle] = useToggle(false);
  return <Component open={open} toggle={toggle} {...props} />;
};

export default DeliveryAddress;
