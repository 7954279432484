import React from "react";
import styles from "./styles.module.scss";

export const Component = ({ onDecrement, onIncrement, value }) => {
  return (
    <div className={styles.QuantityPicker}>
      <button
        className={styles.Button}
        onClick={onDecrement}
        data-cy="decrement"
      >
        -
      </button>
      <div className={styles.QuantityValue}>{value}</div>
      <button
        className={styles.Button}
        onClick={onIncrement}
        data-cy="increment"
      >
        +
      </button>
    </div>
  );
};

const QuantityPicker = (props) => {
  return <Component {...props} />;
};

export default QuantityPicker;
