import React from "react";
import styles from "./styles.module.scss";
import FeatherIcon from "feather-icons-react";
import { moveToBag } from "actions";
import { useNavigate } from "hooks";
import { useDispatch } from "react-redux";

export const Component = ({ moveToBag }) => {
  return (
    <button onClick={moveToBag} className={styles.MoveToBag}>
      <FeatherIcon icon={"shopping-bag"} size={12} />
      <span>Move&nbsp;to&nbsp;bag</span>
    </button>
  );
};

const MoveToBag = ({ product }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return <Component moveToBag={moveToBag(dispatch, product, navigate)} />;
};

export default MoveToBag;
