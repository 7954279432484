import React from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import notextlogo from "../Logo/notextlogo.jpg";
import FeatherIcon from "feather-icons-react";
import useContentful from "useContentful";
import { useLoggedInStatus } from "hooks";

export const sections = [
  {
    title: "About",
    links: [
      { name: "About us", link: "/about-us" },
      { name: "Blog", link: "https://www.medium.com/makethread" },
      // { name: "Press", link: "/press" },
    ],
  },
  {
    title: "Help",
    links: [
      { name: "size guide", link: "sizeguide", type: "contentful" },
      {
        name: "printing guidelines",
        link: "makethread printing guidelines",
        type: "contentful",
      },
      { name: "sustainability", link: "/sustainability" },
      { name: "terms and conditions", link: "/terms-and-conditions" },
      { nmae: "Returns policy", link: "/returns" },
    ],
  },
  {
    title: "Get in contact",
    links: [
      { name: "info@makethread.com", link: "mailto:info@makethread.com" },
    ],
  },
];

const SocialMediaLinks = () => (
  <section className={styles.Socials}>
    <a href={"https://www.facebook.com/makethread"}>
      <FeatherIcon size={18} icon={"facebook"} />
    </a>
    <a href={"https://www.instagram.com/makethread"}>
      <FeatherIcon size={18} icon={"instagram"} />
    </a>
    <a href={"https://www.twitter.com/makethread"}>
      <FeatherIcon size={18} icon={"twitter"} />
    </a>
  </section>
);

const Logo = () => {
  return (
    <div className={styles.LogoContainer}>
      <img className={styles.Logo} src={notextlogo} />
    </div>
  );
};

/* TODO: First button is secondary, second one is tertiary */
const JoinTheMarketplace = () => {
  const isLoggedIn = useLoggedInStatus();

  return isLoggedIn ? (
    <></>
  ) : (
    <section className={styles.JoinTheMarketPlace}>
      <header>Join the marketplace</header>
      <main>
        <p>We're looking for designers to join our Make Thread Marketplace</p>
        <div className={styles.Buttons}>
          <Link className={styles.ButtonSecondary} to={"/sign-up"}>
            start selling
          </Link>
          <Link to={"/about-us"}>How it works</Link>
        </div>
      </main>
    </section>
  );
};

const Bottom = () => (
  <section className={styles.Bottom}>
    <p>
      <span>
        <a
          target={"_blank"}
          href="https://makethread.s3.eu-west-1.amazonaws.com/privacy_policy.pdf"
          rel="noopener noreferrer"
        >
          Privacy policy
        </a>
      </span>
      <span style={{ padding: "0 1em" }}>|</span>
      <span>
        <Link to="/about-us">Contact us</Link>
      </span>
    </p>
    <p>Copyright Make Thread Ltd 2021. All rights reserved</p>
  </section>
);

const getContentfulLink = (link, documents) => {
  const url = documents?.find((d) => d?.fields?.title === link)?.fields.file
    ?.url;
  return url ? `https:${url}` : "";
};

export const Component = ({
  sections = [],
  join = true,
  logo = true,
  documents,
}) => {
  return (
    <footer style={{ borderTop: "1px solid lightgray", marginTop: "4em" }}>
      {logo && <Logo />}
      <div className={styles.Footer}>
        {sections.map((s) => (
          <section key={s.title}>
            <header className={styles.FooterSectionHeader}>{s.title}</header>
            <main>
              <ul>
                {s.links.map((link, i) => (
                  <li key={`${link.link}_${i}`}>
                    {s.link && s.link[0] === "/" ? (
                      <Link to={link.link}>{link.name}</Link>
                    ) : (
                      <a
                        href={
                          link.type === "contentful"
                            ? getContentfulLink(link.link, documents)
                            : link.link
                        }
                      >
                        {link.name}
                      </a>
                    )}
                  </li>
                ))}
                {s.title === "Get in contact" && <SocialMediaLinks />}
              </ul>
            </main>
          </section>
        ))}
        <div></div>
        {join && <JoinTheMarketplace />}
      </div>
      <Bottom />
    </footer>
  );
};

// TODO : Social links n icons
// TODO : there's a sign up to mailing list when on desktop?

const Footer = (props) => {
  const data = useContentful("pdfDocuments");
  return (
    <Component sections={sections} {...props} documents={data.documents} />
  );
};

export default Footer;
