import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import styles from "./styles.scss";
import { post } from "../App/api";
import renderField from "App/Forms";

const login = history => (dispatch, getState) =>
  dispatch({
    type: "LOGIN",
    payload: post({
      url: "/admin/login",
      body: getState().form.Login.values
    }).then(data => {
      setTimeout(function() {
        history.push("/admin");
      }, 200);
      return data;
    })
  });

export const Login = ({ onSubmit, history }) => (
  <>
    <form
      className={styles.HomePage}
      onSubmit={event => {
        event.preventDefault();
        onSubmit(history);
      }}
    >
      <h1>Admin Console</h1>
      <label htmlFor="email">email</label>
      <Field name="email" type="text" component={renderField} />
      <label htmlFor="password">password</label>
      <Field name="password" type="password" component={renderField} />
      <button type="submit">Log in</button>
    </form>
    <Link to="/password-reset">forgot your password?</Link>
  </>
);

const mapStateToProps = state => ({});
const mapDispatchToProps = {
  onSubmit: login
};

const Form = reduxForm({ form: "Login" })(Login);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Form));
