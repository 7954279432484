import React from "react";
import styles from "./styles.module.scss";
import renderField from "App/Forms";
import { Field } from "redux-form";
import { Link } from "react-router-dom";
import Logo from "Logo";
import { LoginHeader } from "Login";

export const UploadWork = () => (
  <div className={styles.UploadWork}>
    <label>Upload Work</label>
    <p>
      Show us where we can find some examples of your work. All designs will be
      reviewed by the make thread team before they go live.
    </p>
    <ol>
      <li>
        <Field name="work.1" type="text" component={renderField} />
      </li>
      <li>
        <Field name="work.2" type="text" component={renderField} />
      </li>
      <li>
        <Field name="work.3" type="text" component={renderField} />
      </li>
    </ol>
  </div>
);

export const MarketingPermissions = () => (
  <label className={styles.AgreeToTerms}>
    <div>
      We want to tell you about cool designers and new clothes.
      <br />
      Uncheck this box if you don't want any updates.
    </div>
    <Field
      name="marketing_permissions"
      type="checkbox"
      component={renderField}
    />
  </label>
);

export class UploadFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "",
    };
  }

  setImage(event) {
    this.setState({ image: URL.createObjectURL(event.target.files[0]) });
  }

  render() {
    return (
      <div className={styles.Upload}>
        <input
          type="file"
          hidden
          onChange={this.setImage.bind(this)}
          accept=".jpg, .png, .jpeg"
        />
        {this.state.image ? (
          <img
            src={this.state.image}
            alt="this will be displayed on your profile"
          />
        ) : (
          <i className="fa fa-user fa-2x" />
        )}
        <button
          className="primary"
          onClick={(e) => {
            e.preventDefault();
            document.querySelector("input[type='file']").click();
          }}
        >
          Upload Logo or photo
        </button>
      </div>
    );
  }
}

export const AgreeToTerms = () => (
  <label className={styles.AgreeToTerms}>
    <div>
      I agree to the{" "}
      <a href="/terms-and-conditions" target={"_blank"}>
        terms and conditions here
      </a>
    </div>
    <Field name="terms" type="checkbox" component={renderField} />
  </label>
);

export const SignUpDesigner = ({
  onSubmit,
  history,
  pristine,
  submitting,
  reset,
  withVoucherCode,
}) => (
  <div className={styles.SignUp}>
    <section>
      <LoginHeader active={"join"} />
      <main>
        <Logo />
        <form
          onSubmit={(event) => {
            event.preventDefault();
            onSubmit({ history, role: "designer", reset, withVoucherCode });
          }}
          className={styles.DesignerSignUpForm}
        >
          <h2>Designer sign up</h2>
          <div>
            <label htmlFor="firstName">First name</label>
            <Field name="firstName" type="text" component={renderField} />
          </div>
          <div>
            <label htmlFor="secondName">Surname</label>
            <Field name="secondName" type="text" component={renderField} />
          </div>
          <div>
            <label htmlFor="username">Pick a username</label>
            <Field name="username" type="text" component={renderField} />
          </div>
          <label htmlFor="email">Enter your email</label>
          <Field name="email" type="text" component={renderField} />
          <UploadWork />
          <UploadFile />

          <label htmlFor="bio">Write a bio</label>
          <Field
            name="bio"
            type="textarea"
            component={renderField}
            placeholder="Tell us about yourself..."
          />

          <label htmlFor="how-they-found-makethread">
            How did you hear about us?
          </label>
          <Field
            name="how-they-found-makethread"
            type="text"
            component={renderField}
          />

          <div className={styles.Social}>
            <label>Social media</label>
            <div>
              <label htmlFor="instagram">Instagram</label>
              <Field name="instagram" type="text" component={renderField} />
            </div>
            <div>
              <label htmlFor="twitter">Twitter</label>
              <Field name="twitter" type="text" component={renderField} />
            </div>
            <div>
              <label htmlFor="facebook">Facebook</label>
              <Field name="facebook" type="text" component={renderField} />
            </div>
          </div>

          <label htmlFor="password">Enter a password:</label>
          <Field name="password" type="password" component={renderField} />
          <label htmlFor="confirm-password">Repeat:</label>
          <Field
            name="confirm-password"
            type="password"
            component={renderField}
          />
          <MarketingPermissions />
          <AgreeToTerms />
          <button
            type="submit"
            className="primary"
            disabled={pristine || submitting}
          >
            Sign Up
          </button>
        </form>
      </main>
    </section>
  </div>
);

export default SignUpDesigner;
