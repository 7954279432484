export const initialState = {};

const actionHandlers = {
  LOGOUT: (state, payload) => initialState,
  GET_VOUCHER_CODE_DATA_FULFILLED: (state, data) => data,
  GET_VOUCHER_CODE_DATA_REJECTED: () => initialState
};

export const reducer = (state = initialState, { type, payload }) =>
  actionHandlers[type] ? actionHandlers[type](state, payload) : state;

export default reducer;
