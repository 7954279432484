import { possibleSizes } from "config";
import React, { useState } from "react";
import styles from "./styles.module.scss";

export const Component = ({
  sizes = [],
  selectSize,
  selected,
  onChange = () => {},
  dropNumber,
  sizeGuide,
}) => {
  return (
    <div className={styles.SizePickerContainer}>
      <h3>Select size</h3>
      <ol className={styles.SizePicker}>
        {possibleSizes
          .filter((s) => sizes.includes(s))
          .map((s) => (
            <li>
              <button
                data-cy="size-picker"
                className={s === selected ? styles.SelectedSize : styles.Size}
                onClick={() => {
                  selectSize(s);
                  onChange(s);
                }}
              >
                {s}
              </button>
            </li>
          ))}
      </ol>
      {Boolean(sizeGuide) && sizeGuide !== "undefined" && (
        <>
          <h4>Size guide</h4>
          <p>{sizeGuide}</p>
        </>
      )}
      <div className={styles.DropNumber}>Drop number: {dropNumber}</div>
    </div>
  );
};

const SizePicker = ({
  onChange,
  sizes = possibleSizes,
  dropNumber,
  sizeGuide,
}) => {
  const [size, selectSize] = useState(sizes[Math.floor(sizes.length / 2)]);

  return (
    <Component
      sizes={sizes}
      selected={size}
      selectSize={selectSize}
      onChange={onChange}
      dropNumber={dropNumber}
      sizeGuide={sizeGuide}
    />
  );
};

export default SizePicker;
