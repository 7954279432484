import React from "react";
import styles from "./styles.scss";

export const BrokenDisplay = () => (
  <div className={styles.BrokenDisplay}>
    <h1>
      Whoops{" "}
      <span role="img" aria-label="woman facepalming">
        🤦🏽‍♀️
      </span>
    </h1>
    <h2>Looks like something went wrong</h2>
    <button
      onClick={() => {
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.href = "/";
      }}
    >
      reload the app and try again
    </button>
  </div>
);

export default BrokenDisplay;
