import React from "react";
import styles from "./styles.scss";
import { connect } from "react-redux";
import ReactMarkdown from "react-markdown";

import { getTermsAndConditions } from "actions";

import Fetch from "App/api/Fetch";

export const Terms = ({ copy, onFetch }) => (
  <Fetch actions={[onFetch]}>
    <div className={styles.Terms}>
      <ReactMarkdown source={copy} />
    </div>
  </Fetch>
);

export default connect(
  ({ staticFiles }) => ({
    copy: staticFiles["terms-and-conditions"],
  }),
  {
    onFetch: getTermsAndConditions,
  }
)(Terms);
