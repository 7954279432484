export const validate = (values) => {
  const errors = {
    "delivery-address": {},
  };

  if (!values["first-name"]) {
    errors["first-name"] = "Enter your first name";
  }
  if (!values["last-name"]) {
    errors["last-name"] = "Enter your last name";
  }

  if (!values || !values["delivery-address"]) {
    errors["delivery-address"]["line-1"] = "Enter your address";
  } else {
    if (!values["delivery-address"]["line-1"]) {
      errors["delivery-address"]["line-1"] = "Enter your address";
    }
    if (!values["delivery-address"]["city"]) {
      errors["delivery-address"]["city"] = "Enter a town or city";
    }
    if (!values["delivery-address"]["postcode"]) {
      errors["delivery-address"]["postcode"] = "Enter a post code";
    }
    if (!values["email"]) {
      errors["email"] =
        "we need to send you a receipt, please enter an email address";
    }
    if (!values["phone-number"]) {
      errors["phone-number"] = "Enter a phone number for delivery updates.";
    }
    // TODO valid regex for phone number, and for email.
  }

  return errors;
};

export default validate;
