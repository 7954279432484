import React from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { useDesignerRole, useLoggedInStatus } from "hooks";

const size = 24;
export const Component = ({ isLoggedIn, isDesigner }) => {
  return (
    <ul className={styles.BottomMenu}>
      <li>
        <Link to="/shop">
          <FeatherIcon icon={"shopping-cart"} size={size} />
          <span>shop</span>
        </Link>
      </li>
      <li>
        <Link to="/follow/gallery">
          <FeatherIcon icon={"user-plus"} size={size} />
          <span>follow</span>
        </Link>
      </li>
      <li>
        <Link to={isLoggedIn ? "/camera" : "/login"}>
          <FeatherIcon
            icon={"plus-circle"}
            size={1.25 * size}
            className={styles.Post}
            fill={"#0e4d4c"}
          />
          <span>Post</span>
        </Link>
      </li>
      <li>
        {isDesigner ? (
          <Link to={"/draft-products"}>
            <FeatherIcon icon={"upload"} size={size} />
            <span>My items</span>
          </Link>
        ) : (
          <Link to={isLoggedIn ? "/feed" : "/login"}>
            <FeatherIcon icon={"tv"} size={size} />
            <span>Feed</span>
          </Link>
        )}
      </li>
      <li>
        <Link to={isLoggedIn ? "/my-profile" : "/login"}>
          <FeatherIcon icon={"user"} size={size} />
          <span>profile</span>
        </Link>
      </li>
    </ul>
  );
};

const BottomMenu = () => {
  const isLoggedIn = useLoggedInStatus();
  const isDesigner = useDesignerRole();
  return <Component isLoggedIn={isLoggedIn} isDesigner={isDesigner} />;
};

export default BottomMenu;
