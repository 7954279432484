const initialState = {};

const setToken = (state, { data: { token } }) => ({
  token
});

const actionHandlers = {
  LOGOUT: state => initialState,
  LOGIN_FULFILLED: setToken,
  REGISTER_FULFILLED: setToken,
  CONFIRM_PHONE_FULFILLED: (state, { token, two_factor_token }) => ({
    token,
    two_factor_token
  }),
  SET_STRIPE_TOKEN: (state, token) => ({ ...state, stripeToken: token }),
  PURCHASE_COMPLETED: state => ({ ...state, stripeToken: "" })
  // GET_PAYMENT_SECRE: state => ({ ...state, stripeToken: "" }),
  // GET_PAYMENT_SECRET_FULFILLED: (state, { secret }) => ({ ...state, paymentSecret: secret })
};

export const reducer = (state = initialState, { type, payload }) =>
  actionHandlers[type] ? actionHandlers[type](state, payload) : state;

export default reducer;
