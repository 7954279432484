import SortBy from "SortBy";
import Filters from "Filters";
import styles from "./styles.module.scss";
import ProductCard from "ProductCard";
import Breadcrumbs from "Breadcrumbs";
import Share from "Share";

const LayoutHeader = ({ Banner, title, subtitle, breadcrumbs }) => (
  <header className={styles.LayoutHeader}>
    {Banner && <Banner />}
    {breadcrumbs && <Breadcrumbs />}
    {/* {breadcrumbs && <BreadcrumbBanner filters={filters} />} */}
    {title && (
      <div className={styles.Titles}>
        <h1>{title}</h1>
        {subtitle && <h2 className={styles.SubTitle}>{subtitle}</h2>}
      </div>
    )}
    {/* <div className={styles.Right}> */}
    {/* <div className={styles.FiltersAndSortBy}>
        <Filters />
        <SortBy />
      </div>
      <Share /> */}
    {/* </div> */}
  </header>
);
const Layout = ({
  productSet = [],
  title,
  subtitle,
  filters = false,
  breadcrumbs = false,
  setSortBy,
  setFilters,
  Banner,
  largeMobile,
  ...props
}) => {
  return (
    <section>
      <LayoutHeader
        title={title}
        subtitle={subtitle}
        Banner={Banner}
        breadcrumbs={breadcrumbs}
        filters={filters}
      />
      <main className={largeMobile ? styles.GridLargeMobile : styles.Grid}>
        {productSet.map((p) => (
          <ProductCard
            key={p._id}
            product={p}
            {...props}
            largeMobile={largeMobile}
          />
        ))}
      </main>
    </section>
  );
};

export const GenericGrid = ({
  children,
  title,
  subtitle,
  showFilters = false,
  small = false,
  Banner,
  breadcrumbs = false,
}) => {
  const filters = showFilters;

  return (
    <section>
      <LayoutHeader
        title={title}
        subtitle={subtitle}
        Banner={Banner}
        breadcrumbs={breadcrumbs}
      />
      <main className={small ? styles.SmallGrid : styles.Grid}>{children}</main>
    </section>
  );
};

export default Layout;
