import CheckoutSection from "Payment/CheckoutSection";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { Field, useFormState } from "react-final-form";
import CheckBox from "CheckBox";
import { useLoggedInStatus } from "hooks";

const Main = ({ email, setEmail, values }) => {
  const formState = useFormState();
  const error = formState.errors.email;

  return (
    <div className={styles.Form}>
      <div className={styles.Container}>
        {!email ? (
          <>
            <label className={styles.Email}>
              Enter your email to check out as a guest
            </label>
            <div>
              {error && (
                <div
                  className={styles.ErrorBox}
                  style={{ marginBottom: "1em" }}
                >
                  <i className="fa fa-exclamation-triangle" />
                  {error}
                </div>
              )}
              <Field
                name="email"
                component="input"
                placeholder="Email address"
              />
            </div>
            <button
              className={styles.ButtonSecondary}
              onClick={() => setEmail(values.email)}
            >
              Add my email
            </button>
          </>
        ) : (
          <div>
            Your email <b>{email}.</b>
            <button
              className={styles.ButtonSecondary}
              onClick={() => setEmail("")}
            >
              Edit
            </button>
          </div>
        )}
      </div>
      <Field
        name={"opt-in-newsletter"}
        id="opt-in-newsletter"
        type="checkbox"
        label={"Tick to opt-in to our newsletter"}
        component={CheckBox}
      />
    </div>
  );
};

const Header = () => {
  const isLoggedIn = useLoggedInStatus();
  if (isLoggedIn) {
    return <span>Your Details</span>;
  }
  return (
    <>
      <span>Your Details</span>
      <span>
        Already have a profile? <Link to="/login">sign in </Link>
      </span>
    </>
  );
};

export const Component = (props) => {
  return (
    <CheckoutSection Header={Header} expandable={false}>
      <Main {...props} />
    </CheckoutSection>
  );
};

const YourDetails = (props) => {
  return <Component {...props} />;
};

export default YourDetails;
