import React from "react";
import styles from "./styles.module.scss";
import img from "./img.svg";

export const Component = () => {
  return (
    <div className={styles.Planet}>
      <img src={img} />
      <div>
        <h2>We contribute 1% of earnings to fight for the planet</h2>
        <p>
          Putting people and the planet over profit, we work in synergy with 1%
          For The Planet and their network of businesses, individuals and
          environmental organisations to tackle our planet's most pressing
          environmental issues.
        </p>
      </div>
    </div>
  );
};

const Planet = () => {
  return <Component />;
};

export default Planet;
