import React, { useRef, useState } from "react";
import styles from "./styles.module.scss";
import FeatherIcon from "feather-icons-react";

export const Component = ({ photos, setPhoto, refs, deletePhoto }) => {
  return (
    <section className={styles.MultiPhotoSelect}>
      <ul className={styles.PhotoGrid}>
        {photos.map((photo, i) => (
          <li key={`photo_${i}`}>
            {photo ? (
              <>
                <button
                  className={styles.WithPhoto}
                  onClick={(e) => {
                    refs.current[i].click();
                  }}
                >
                  <img src={photo} />
                </button>
                <button onClick={deletePhoto(i)} className={styles.Delete}>
                  <FeatherIcon size={18} icon={"trash-2"} />
                </button>
              </>
            ) : (
              <div>
                <button
                  className={styles.EmptyPhoto}
                  onClick={(e) => {
                    refs.current[i].click();
                  }}
                >
                  <FeatherIcon size={36} icon={"camera"} />
                </button>
              </div>
            )}
            <input
              type="file"
              accept=".jpg, .png, .jpeg"
              style={{ display: "none" }}
              id={`photo_${i}`}
              onChange={setPhoto(i)}
              ref={(el) => {
                refs.current[i] = el;
              }}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};

const MultiPhotoSelect = ({ photos, setPhotos, refs }) => {
  const setPhoto = (i) => (e) => {
    if (!e.target.files || e.target.files.length <= 0) {
      return;
    }

    const photo = URL.createObjectURL(e.target.files[0]);
    setPhotos((photos) => {
      const newPhotos = [...photos];
      newPhotos[i] = photo;
      return newPhotos;
    });
  };
  const deletePhoto = (i) => (e) =>
    setPhotos((photos) => {
      const newPhotos = [...photos];
      newPhotos[i] = "";
      return newPhotos;
    });

  return (
    <Component
      photos={photos}
      setPhoto={setPhoto}
      refs={refs}
      deletePhoto={deletePhoto}
    />
  );
};

export default MultiPhotoSelect;
