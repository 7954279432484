import { v4 as uuidv4 } from "uuid";

const initialState = {
  canTrackUser: false,
  showCookieBanner: true,
  uuid: null
};

const actionHandlers = {
  USER_CONSENTED_TO_TRACKING: (state, canTrackUser) => ({
    ...state,
    canTrackUser,
    showCookieBanner: false,
    uuid: canTrackUser ? uuidv4() : null
  })
};

export const reducer = (state = initialState, { type, payload }) =>
  actionHandlers[type] ? actionHandlers[type](state, payload) : state;

export default reducer;
