import { useToggle } from "hooks";
import React from "react";
import styles from "./styles.module.scss";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

const AccordionSection = ({ title, Body }) => {
  const [open, toggle] = useToggle(false);
  return (
    <section
      className={open ? styles.AccordionSectionOpen : styles.AccordionSection}
    >
      <header onClick={toggle} role="button">
        {title}
        <button>
          <FeatherIcon icon={open ? "chevron-up" : "chevron-down"} size={20} />
        </button>
      </header>
      {open && (
        <main>
          <Body></Body>
        </main>
      )}
    </section>
  );
};

export const Component = ({ sections = [] }) => {
  return sections.map((s, i) => <AccordionSection key={i} {...s} />);
};

const Accordion = ({ product }) => {
  const sections = [
    {
      title: "What i'm made of",
      Body: () => <div>{`${product.madeFrom}`}</div>,
    },
    {
      title: "Where i'm made",
      Body: () => (
        <div>
          We work with suppliers based in India and Bangladesh. Both of which
          are organically certified by the Global Organic Textile Standard
          (G.O.T.S.), and ethically monitored by Fair Wear Foundation.{" "}
          <Link to="/sustainability">See more information here</Link>
        </div>
      ),
    },
    {
      title: "What i'm packed in",
      Body: () => (
        <div>
          All our garments are caringly packaged in acid free biodegradable
          packaging, made here in the UK from materials that are from
          sustainably sourced. We print all of our packaging using Soy-based
          inks, which are FSC (Forest Stewardship Council) certified.{" "}
          <Link to="/sustainability">See more information here</Link>
        </div>
      ),
    },
    {
      title: "Delivery info",
      Body: () => (
        <div>
          <p>
            We will make and ship your order within 7-10 days from date of
            purchase.
          </p>

          <p>
            Please be aware the current delivery time is 3-5 days for the UK
            Mainland and can take longer for international deliveries.
          </p>

          <p>Postage cost:</p>
          <p>£2.90 to mainland UK</p>
          <p>£6.50 International</p>

          <Link to="/terms-and-conditions">See more information here</Link>
        </div>
      ),
    },
    {
      title: "Returns",
      Body: () => (
        <div>
          <p>
            At Make Thread we do things a little differently. All of our items
            are made to order which means zero waste, so therefore we only
            process returns for faulty items.
          </p>
          <p>
            It's our social responsibility to do this to protect our future 🌍
          </p>
          <Link to="/returns">See more info here</Link>`
        </div>
      ),
    },
    // { title: "Terms and conditions", body: "lorem" },
  ];
  return <Component sections={sections} />;
};

export default Accordion;
