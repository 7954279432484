export const sku = (item) => `${item._id}_${item.style}_${item.size}`;

export const calculateDelivery = (country) => {
  return country === "United Kingdom" ? 2.9 : 6.5;
};

export const hasMultiplePhotos = (campaign) =>
  Boolean(Array.isArray(campaign.photos) && campaign.photos.length > 1);

export const toCurrency = (n) => `£${(0.01 * n).toFixed(2)}`;

export const getContentfulImage = (x) =>
  x ? `https:${x.fields.file.url}` : "";
