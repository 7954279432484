import React, { Fragment } from "react";
import styles from "./styles.module.scss";
import FeatherIcon from "feather-icons-react";
import { useToggle } from "hooks";

const CheckoutSection = ({
  Header = () => {},
  Main = () => Fragment,
  expandable = true,
  children,
}) => {
  const [open, toggle] = useToggle(true);

  return (
    <section className={styles.CheckoutSection}>
      <header onClick={expandable ? toggle : () => {}}>
        <Header />
        {expandable && <FeatherIcon icon={`chevron-${open ? "up" : "down"}`} />}
      </header>
      {open && (
        <main>
          <Main />
          {children}
        </main>
      )}
    </section>
  );
};

export default CheckoutSection;
