import React from "react";
import styles from "./styles.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import logo from "Logo/Green_MakeThread.png";
import Fetch from "App/api/Fetch";
import { getVoucherCodeData } from "actions";

const VoucherCodePopup = ({
  shouldShowPopup,
  hidePopup,
  history,
  onFetch,
  activeVoucher
}) => (
  <Fetch actions={[onFetch]}>
    {shouldShowPopup && activeVoucher === "TEN_PERCENT_SIGNUP" ? (
      <div className={styles.Container}>
        <div className={styles.Popup}>
          <img src={logo} alt={"makethread logo"} />
          <h1>Hello fashionita, enjoy</h1>
          <h1 className={styles.Big}>10% off</h1>
          <p>On your first purchase</p>
          <h2>When you register for an account and join our newsletter</h2>
          <button
            className="primary"
            onClick={() => {
              hidePopup();
              history.push({
                pathname: "/sign-up",
                state: {
                  withVoucherCode: true
                }
              });
            }}
          >
            Get my 10% off
          </button>
          <button className={styles.Close} onClick={hidePopup}>
            <i>x</i>
          </button>
        </div>
      </div>
    ) : (
      <></>
    )}
  </Fetch>
);

export const Popup = props => <VoucherCodePopup {...props} />;

export default withRouter(
  connect(
    state => ({
      shouldShowPopup:
        state.notifications.showOfferPopup &&
        (state.profile && !state.profile.voucherCode),
      activeVoucher: state.vouchers.activeVoucher
    }),
    {
      hidePopup: () => dispatch => dispatch({ type: "HIDE_OFFER_POPUP" }),
      onFetch: getVoucherCodeData
    }
  )(Popup)
);
