import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router";
import { Link } from "react-router-dom";

import EditSettings from "./Edit";
import styles from "./styles.module.scss";

const ReadOnlySettingsComponent = ({ profile = {} }) => (
  <div className={styles.Settings}>
    <dl>
      <dt>username:</dt>
      <dd>{profile.username}</dd>
    </dl>
    <dl>
      <dt>name:</dt>
      <dd>
        {profile.firstName}
        {profile.secondName}
      </dd>
    </dl>
    <dl>
      <dt>bio:</dt>
      <dd>{profile.bio}</dd>
    </dl>
    <dl>
      <dt>profile pic:</dt>
      <dd>
        <img src={profile.profilePicture} alt="profile thumbnail" />
      </dd>
    </dl>
    <Link to="/settings/edit" className={styles.ButtonPrimary}>
      Edit
    </Link>
  </div>
);

const ReadOnlySettings = connect(({ profile }) => ({ profile }))(
  ReadOnlySettingsComponent
);

export const Settings = () => (
  <div className={styles.Container}>
    <Route exact path={"/settings"} component={ReadOnlySettings} />
    <Route exact path={"/settings/edit"} component={EditSettings} />
  </div>
);

export default Settings;
