export const initialState = {};

const actionHandlers = {
  ADD_TO_SAVED_LIST: (state, sku) => ({ ...state, [sku._id]: sku }),
  REMOVE_FROM_SAVED_LIST: (state, product) => {
    const newState = { ...state };
    delete newState[product._id];
    return newState;
  },
  CLEAR_SAVED_LIST: () => initialState,
};

export const reducer = (state = initialState, { type, payload }) =>
  actionHandlers[type] ? actionHandlers[type](state, payload) : state;

export default reducer;
