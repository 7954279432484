import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reduxForm, Field } from "redux-form";
import styles from "./styles.module.scss";
import { post } from "../App/api";
import Loading from "App/Loading";
import { UploadWork } from "SignUp/designer";

export const Apply = ({ onSubmit, history }) => (
  <Loading
    regex={new RegExp(/APPLY_TO_BECOME_DESIGNER_PENDING/)}
    message={"sending over your application"}
  >
    <form
      className={styles.Apply}
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit(history);
      }}
    >
      <h1>How it works</h1>
      <p>
        To become a designer you need to be able to show us some of your work.
      </p>
      <p>
        When you click apply, your profile and information will be looked over
        by the makethread team.
      </p>
      <p>
        If you're successful, you'll be automatically registered as a designer
        and be able to start a campaign
      </p>
      <h2>Why do you want to be a designer with makethread?</h2>
      <Field type="input" component="textarea" name="reason_for_application" />
      <UploadWork />

      <button type="submit" className="primary">
        Apply
      </button>
    </form>
  </Loading>
);

const mapStateToProps = ({ profile }) => ({
  initalValues: { work: profile.exampleWork },
});
const mapDispatchToProps = {
  onSubmit: (history) => (dispatch, getState) =>
    dispatch({
      type: "APPLY_TO_BECOME_DESIGNER",
      payload: post({
        url: "/apply",
        body: getState().form.Apply.values,
        token: getState().auth.token,
      }).then((data) => {
        history.push("/my-profile");
        return data;
      }),
    }),
};

const Form = reduxForm({ form: "Apply" })(Apply);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Form));
