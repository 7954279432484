export const initialState = {};

const actionHandlers = {
  LOGOUT: () => initialState,
  LOGIN_FULFILLED: (state, { data: { profile } }) => ({
    ...state,
    [profile.username]: profile,
  }),
  REGISTER_FULFILLED: (state, { data: { profile } }) => ({
    ...state,
    [profile.username]: profile,
  }),
  GET_PROFILE_FULFILLED: (state, profile) => ({
    ...state,
    [profile.username]: profile,
  }),
  GET_ADMIN_DATA_FULFILLED: (state, { data: { users } }) => {
    const newState = { ...state };
    for (var i = 0; i < users.length; i++) {
      if (users[i].username) {
        newState[users[i].username] = users[i];
      }
    }
    return newState;
  },
  SEARCH_FULFILLED: (state, { results }) => {
    const newState = { ...state };
    const resultsThatAreUsers = results.filter((r) => Boolean(r.username));
    for (var i = 0; i < resultsThatAreUsers.length; i++) {
      newState[resultsThatAreUsers[i].username] = resultsThatAreUsers[i];
    }
    return newState;
  },
  UPDATE_FEED_FULFILLED: (state, { feed }) => {
    const newState = { ...state };
    const resultsThatAreUsers = feed.filter((r) => Boolean(r.username));
    for (var i = 0; i < resultsThatAreUsers.length; i++) {
      newState[resultsThatAreUsers[i].username] = resultsThatAreUsers[i];
    }
    return newState;
  },
  DEACTIVATE_USER_FULFILLED: (state, user) => {
    const newState = { ...state };
    newState[user.username] = user;
    return newState;
  },
  REACTIVATE_USER_FULFILLED: (state, user) => {
    const newState = { ...state };
    newState[user.username] = user;
    return newState;
  },
  APPROVE_APPLICATION_FULFILLED: (state, { data }) => {
    const newState = { ...state };
    newState[data.username] = data;
    return newState;
  },
  REJECT_APPLICATION_FULFILLED: (state, { data }) => {
    const newState = { ...state };
    newState[data.username] = data;
    return newState;
  },
  GET_DESIGNERS_FULFILLED: (state, designers) => {
    const newState = { ...state };
    for (var i = 0; i < designers.length; i++) {
      newState[designers[i].username] = designers[i];
    }
    return newState;
  },
  GET_STAR_PICKS_FULFILLED: (state, { data }) => {
    const newState = { ...state };
    const designers = data.map((d) => d.designer);
    for (var i = 0; i < designers.length; i++) {
      newState[designers[i].username] = designers[i];
    }
    return newState;
  },
};

export const reducer = (state = initialState, { type, payload }) =>
  actionHandlers[type] ? actionHandlers[type](state, payload) : state;

export default reducer;
