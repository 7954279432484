import Bag from "Bag";
import EmptyBasket from "EmptyBasket";
import { useBasket } from "hooks";
import CheckoutSection from "Payment/CheckoutSection";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import FeatherIcon from "feather-icons-react";
import { toCurrency } from "helpers";
import { FREE_DELIVERY_THRESHOLD } from "config";

const Main = ({ subTotal, total }) => {
  return (
    <div className={styles.Total}>
      <div>
        <div>sub-total</div>
        <div className={styles.SmallPrice}>{toCurrency(subTotal)}</div>
      </div>
      <div>
        <div>Postage</div>
        <div className={styles.SmallText}>
          {total > FREE_DELIVERY_THRESHOLD
            ? "Free delivery"
            : "Calculated at next step"}
        </div>
      </div>
      <div>
        <div>Total</div>
        <div className={styles.BigPrice}>{toCurrency(total)}</div>
      </div>
      <Link className={styles.ButtonPrimary} to={"/checkout"}>
        Checkout securely
      </Link>
    </div>
  );
};
const Total = (props) => {
  return (
    <CheckoutSection Header={() => <div>Total</div>} expandable={false}>
      <Main {...props} />
    </CheckoutSection>
  );
};

const ContinueShopping = () => {
  return Fragment;
};

const ViewSavedList = () => {
  return (
    <section className={styles.ViewSavedList}>
      <FeatherIcon icon={"shopping-bag"} size={72} />
      <div>
        <h1>Wondering where your items have gone?</h1>
        <p>Not to worry, you'll find them in your Saved List.</p>
      </div>
      <Link to="/saved-list" className={styles.ButtonSecondary}>
        View saved list
      </Link>
    </section>
  );
};

const FreeDeliveryMessage = ({ total }) => {
  if (total > FREE_DELIVERY_THRESHOLD) {
    return (
      <div className={styles.FreeDeliveryApplies}>
        Congratulations, you've qualified for <b>free delivery!</b>
      </div>
    );
  }
  return (
    <div className={styles.FreeDeliveryMessage}>
      Spend a further <b>{toCurrency(FREE_DELIVERY_THRESHOLD - total)}</b> to
      qualify for <b>FREE Standard UK mainland delivery.</b>
    </div>
  );
};

export const Component = ({ basket = [], quantity, total, subTotal }) => {
  if (quantity === 0) {
    return <EmptyBasket />;
  }

  // TODO do i need an updateBag function?
  return (
    <div className={styles.Container}>
      <div className={styles.Page}>
        <div className={styles.Left}>
          <Bag />
          <ContinueShopping />
          <ViewSavedList />
        </div>
        <aside>
          <FreeDeliveryMessage total={total} />
          <Total total={total} subTotal={subTotal} />
        </aside>
      </div>
    </div>
  );
};

const BasketPage = () => {
  const props = useBasket();
  return <Component {...props} />;
};

export default BasketPage;
