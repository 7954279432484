import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import styles from "./styles.module.scss";
import { post } from "../App/api";
import renderField from "App/Forms";

const login = (history) => (dispatch, getState) => {
  let { email, password } = getState().form.Login.values;
  email = email.replace(/ /g, "");
  dispatch({
    type: "LOGIN",
    payload: post({
      url: "/login",
      body: { email, password },
    }).then((data) => {
      setTimeout(function () {
        history.push("/feed");
      }, 200);
      return data;
    }),
  });
};

export const LoginHeader = ({ active = "login" }) => (
  <header className={styles.LoginHeader}>
    <Link className={active === "login" ? styles.Active : ""} to="/login">
      Sign in
    </Link>
    <Link to="/sign-up" className={active === "join" ? styles.Active : ""}>
      Join
    </Link>
  </header>
);

export const Login = ({ onSubmit, history, pristine, submitting }) => (
  <div className={styles.Login}>
    <section>
      <LoginHeader active="login" />
      <main>
        <h1>Sign in</h1>
        <span>Use your credentials to sign into your account</span>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            onSubmit(history);
          }}
        >
          <fieldset>
            <Field
              name="email"
              type="text"
              component={renderField}
              placeholder="email"
            />
            <Field
              name="password"
              type="password"
              component={renderField}
              placeholder="password"
            />
            <button
              type="submit"
              className={styles.ButtonPrimary}
              disabled={pristine || submitting}
            >
              Sign in
            </button>
          </fieldset>
        </form>
        <div className={styles.Reset}>
          <span>Having trouble signing in?</span>
          <Link to="/password-reset">Reset your password</Link>
        </div>
      </main>
    </section>
  </div>
);

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  onSubmit: login,
};

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Enter your email";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      values.email.replace(/ /g, "")
    )
  ) {
    errors.email = "This doesn't look like an email address";
  }
  if (!values.password) {
    errors.password = "Enter your password";
  }
  return errors;
};

const Form = reduxForm({ form: "Login", validate })(Login);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Form));
