import React from "react";
import styles from "./styles.module.scss";
import { connect } from "react-redux";
import Fetch from "App/api/Fetch";
import { getUserById, deactivateUser, reactivateUser } from "actions";
import { Link } from "react-router-dom";
import blankprofile from "ProfileHeader/blankprofile.jpg";

const AdminOtions = ({ user, onDeactivate, onReactivate }) => (
  <div className={styles.AdminOtions}>
    {user.deactivated_at ? (
      <button onClick={() => onReactivate(user._id)}>
        reactivate this user
      </button>
    ) : (
      <button onClick={() => onDeactivate(user._id)}>
        deactivate this user
      </button>
    )}
  </div>
);

export const UserCard = ({
  user,
  onFetch,
  currentUserIsAdmin,
  onDeactivate,
  onReactivate,
}) => (
  <Fetch actions={typeof user === "string" ? [() => onFetch(user)] : []}>
    {user ? (
      <div className={styles.UserCard}>
        <Link to={`/profile/${user.username}/products`}>
          <img
            src={user.thumbnail || blankprofile}
            alt={`${user.firstName} ${user.secondName}`}
          />
          <div>
            <div>@{user.username}</div>
            <div className={styles.name}>
              {user.firstName} {user.secondName}
            </div>
            {currentUserIsAdmin ? (
              <>
                <div>{user.email}</div>
                {user.socialMediaHandles ? (
                  <div>
                    <div>insta: {user.socialMediaHandles.instagram}</div>
                    <div>fb: {user.socialMediaHandles.facebook}</div>
                    <div>twitter: {user.socialMediaHandles.twitter}</div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </Link>
        {currentUserIsAdmin ? (
          <AdminOtions
            user={user}
            onDeactivate={onDeactivate}
            onReactivate={onReactivate}
          />
        ) : null}
      </div>
    ) : (
      <></>
    )}
  </Fetch>
);

const mapStateToProps = ({ users, profile: { roles } }, { user }) => ({
  user:
    typeof user === "string"
      ? Object.values(users).filter((u) => u._id === user)[0]
      : user,
  currentUserIsAdmin: roles && roles.indexOf("admin") >= 0,
});

export default connect(mapStateToProps, {
  onFetch: getUserById,
  onDeactivate: deactivateUser,
  onReactivate: reactivateUser,
})(UserCard);
