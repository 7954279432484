import { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import {
  getCollection,
  getLiveProducts,
  getProductById,
  getEndingSoonProducts,
  getPosts,
} from "actions";
import { useHistory } from "react-router-dom";
import { get, post } from "App/api";
import useContentful from "useContentful";

export const useAction = (action, ...deps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(action());
  }, [dispatch, ...deps]);
};

export const useProducts = () => {
  const products = useSelector(({ products }) => products);
  useAction(getLiveProducts);
  return products;
};

export const useEndingSoonProducts = () => {
  const products = useSelector(({ products }) => products);
  useAction(getEndingSoonProducts);
  return products;
};

export const useProductById = (id) => {
  const product = useSelector(({ products }) =>
    products.find((c) => c._id === id)
  );
  useAction(() => getProductById(id));
  return product || { photos: [], designer: {} };
};
export const useCollection = (name) => {
  const collection = useSelector(({ collections }) => collections[name]);
  useAction(getCollection(name), name);
  return collection;
};

export const useAdminDrafts = () => {
  const token = useSelector((state) => state.auth.token);
  const [data, setData] = useState([]);
  useEffect(() => {
    const act = async () => {
      const response = await get({
        url: "/admin/drafts",
        token,
      });
      setData(response);
    };
    act();
  }, [token]);
  return data;
};

export const useProductDashboardData = () => {
  const token = useSelector((state) => state.auth.token);
  const [data, setData] = useState({ numberSold: 0, numberOfOrders: 0 });
  useEffect(() => {
    const act = async () => {
      const response = await get({
        url: "/product-dashboard",
        token,
      });
      setData(response);
    };
    act();
  }, [data.numberSold, data.numberOfOrders, token, setData]);
  return data;
};

export const useOrderDashboard = () => {
  const token = useSelector((state) => state.auth.token);
  const [data, setData] = useState([]);
  useEffect(() => {
    const act = async () => {
      const response = await get({
        url: "/order-dashboard",
        token,
      });
      setData(response);
    };
    act();
  }, [token, setData]);
  return data;
};

const calculateCountdown = (ends_at) => {
  const end = new Date(ends_at).getTime();
  const now = new Date();
  const diff = new Date(end - now);
  const days = Math.floor(diff / 86400000);
  const hours = diff.getUTCHours();
  const mins = diff.getUTCMinutes();
  const secs = diff.getUTCSeconds();
  return { days, hours, mins, secs };
};

export const useCountdown = (ends_at = "") => {
  const [time, setTime] = useState(calculateCountdown(ends_at));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(calculateCountdown(ends_at));
    }, 1000);
    return () => clearInterval(intervalId);
  }, [ends_at]);

  return time;
};

export const useTimeLeft = (ends_at = "") => {
  const [time, setTime] = useState(calculateCountdown(ends_at));
  return time;
};

export const useToggle = (initial = true) => {
  const [open, setOpen] = useState(initial);
  const toggle = () => setOpen((open) => !open);
  return [open, toggle];
};

export const useNavigate = () => {
  const history = useHistory();
  const navigate = (link, state) => history.push(link, state);
  return navigate;
};

export const usePaginate = (startPage = 1) => {
  const [page, setPage] = useState(startPage);
  const nextPage = () => setPage(page + 1);
  const previousPage = () => setPage(Math.max(page - 1, 0));
  return { page, nextPage, previousPage, setPage };
};

export const usePosts = (startPage = 1) => {
  const { page, nextPage, previousPage, setPage } = usePaginate(startPage);
  const posts = useSelector((state) => state.posts);
  useAction(getPosts(page), page);
  return { posts, nextPage, previousPage, page, setPage };
};

export const useAuthToken = () => {
  return useSelector((state) => state.auth.token);
};

export const useLoggedInStatus = () => {
  return useSelector((state) => Boolean(state.auth.token));
};

export const useProfile = () => {
  return useSelector((state) => state.profile);
};

export const usePost = (id) => {
  const [post, setPost] = useState({});
  useEffect(() => {
    const act = async () => {
      const data = await get({
        url: `/post/${id}`,
      });
      setPost(data);
    };
    act();
  }, [id]);
  return post;
};

export const useStarPicks = () => {
  const [starPicks, setStarPicks] = useState([]);

  useEffect(() => {
    const act = async () => {
      const { data } = await get({
        url: `/star-picks`,
      });
      setStarPicks(data);
    };
    act();
  }, []);

  // TODO
  const dispatch = useDispatch();
  const token = useAuthToken();
  const setStarPick = ({ product, position }) =>
    dispatch({
      type: "SET_STAR_PICKS",
      payload: post({
        url: "/admin/star-picks",
        body: { product, position },
        token,
      }),
    });

  return { starPicks, setStarPick };
};

export const useHeroImage = () => {
  const data = useContentful("landingPageHeroImageDesktop");
  const [image, setImage] = useState("");

  if (data.items) {
    const size = window.screen.width < 600 ? "mobileImage" : "desktopImage";
    return `https:${data.items[0].fields[size].fields.file.url}`;
  }

  return "";
};

export const useSortAndFilter = () => {
  const [sortBy, setSortBy] = useState({});
  const [filters, setFilters] = useState({});
  const [query, setQuery] = useState({});
  // I think we're only gonna search from the search component for now and allow filters on the pageo
  // justification: there's no search results page anywhere
  // const searchTerm = useSelector((state) => state.search);

  // useEffect(() => {
  //   const act = async () => {
  //     const { filters, sortBy } = await get({
  //       url: `/filters`,
  //     });
  //     setFilters(filters);
  //     setSortBy(sortBy);
  //   };
  //   act();
  // }, [query]);

  return { sortBy, filters, query, setQuery };
};

export const useAdmin = () => {
  const isAdmin = useSelector(
    (state) => state.profile.roles && state.profile.roles.includes("admin")
  );
  return isAdmin;
};

export const useDesignerRole = () => {
  const isDesigner = useSelector(
    (state) =>
      state.profile.roles &&
      (state.profile.roles.includes("designer") ||
        state.profile.roles.includes("admin"))
  );
  return isDesigner;
};

const trackAddToBag = (product) => {
  // ReactGA.plugin.execute("ecommerce", "addItem", {
  //   id: getState().analytics.uuid,
  //   name: campaign.title,
  //   sku: sku(campaign),
  //   price: campaign.price,
  //   category: "Campaigns",
  //   quantity: formValues.quantity,
  // });
  // ReactPixel.track("AddToCart", {
  //   content_name: campaign.title,
  //   content_ids: [campaign._id],
  //   content_type: "product",
  //   value: campaign.price,
  //   currency: "GBP",
  // });
};

export const useBasket = () => {
  const basket = useSelector((state) =>
    Object.values(state.basket).sort(
      (a, b) => new Date(a.created_at) - new Date(b.created_at)
    )
  );
  const { canTrackUser } = useSelector((state) => state.analytics);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addToBag = (item) => {
    // TODO validate func
    dispatch({
      type: "ADD_TO_BASKET",
      payload: item,
    });
    navigate("/basket");
    canTrackUser && trackAddToBag(item);
  };

  const subTotal = useMemo(
    () =>
      basket.reduce((total, item) => (total += item.price * item.quantity), 0),
    [basket]
  );
  const quantity = useMemo(
    () =>
      Object.values(basket).reduce(
        (total, item) => (total += item.quantity),
        0
      ),
    [basket]
  );
  return { basket, addToBag, quantity, subTotal, total: subTotal };
};

export const usePaymentIntent = (stripe) => {
  const [paymentIntent, setPaymentIntent] = useState();

  // useEffect(() => {
  //   if (!stripe) {
  //     return;
  //   }

  //   const clientSecret = new URLSearchParams(window.location.search).get(
  //     "payment_intent_client_secret"
  //   );

  //   if (!clientSecret) {
  //     return;
  //   }

  //   stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
  //     switch (paymentIntent.status) {
  //       case "succeeded":
  //         console.log("Payment succeeded!");
  //         break;
  //       case "processing":
  //         console.log("Your payment is processing.");
  //         break;
  //       case "requires_payment_method":
  //         console.log("Your payment was not successful, please try again.");
  //         break;
  //       default:
  //         console.log("Something went wrong.");
  //         break;
  //     }
  //   });
  // }, [stripe]);

  return [paymentIntent, setPaymentIntent];
};

export const usePaymentRequest = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { total } = useBasket();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [paymentIntent, setPaymentIntent] = usePaymentIntent(stripe);

  useEffect(() => {
    if (stripe) {
      // TODO add basket items here
      const pr = stripe.paymentRequest({
        country: "GB",
        currency: "gbp",
        total: {
          label: "Demo total",
          amount: total,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  return {
    stripe,
    paymentRequest,
    setPaymentRequest,
    elements,
    paymentIntent,
    setPaymentIntent,
  };
};

export const useVoucherCode = (email) => {
  const [voucherCode, setVoucherCode] = useState("");
  const [voucherData, setVoucherData] = useState({});
  const [voucherError, setVoucherError] = useState("");

  useEffect(() => {
    const act = async () => {
      try {
        const data = await post({
          url: "/apply-voucher",
          body: {
            voucherCode,
            email,
          },
        });
        setVoucherData(data);
        setVoucherError("");
      } catch ({ error }) {
        setVoucherData({});
        setVoucherError(error);
      }
    };
    if (voucherCode && email) {
      act();
    }
    if (voucherCode && !email) {
      setVoucherError(
        "You need to add an email before you can apply a voucher"
      );
      setVoucherCode("");
      setVoucherData({});
    }
  }, [voucherCode, email]);

  return {
    voucherCode,
    setVoucherCode,
    voucherData,
    setVoucherData,
    voucherError,
    setVoucherError,
  };
};

export const useFiles = () => {
  const [files, setFiles] = useState([]);
  const clearFiles = () => setFiles([]);
  const onImageSelect = (event) => {
    setFiles(Array.from(event.target.files).map(URL.createObjectURL));
  };
  return { files, onImageSelect, clearFiles };
};

export const useIsOwnProfile = (username) => {
  const profile = useProfile();
  return profile.username === username;
};
