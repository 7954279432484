import React from "react";
import logo from "./Green_MakeThread.png";

export const Logo = ({
  style = {
    height: "10em",
    width: "10em",
    margin: "0 auto",
  },
}) => (
  <img id="logo" src={logo} alt="makethread logo and title" style={style} />
);
export default Logo;
