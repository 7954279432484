export const initialState = {};

const actionHandlers = {
  LOGOUT: (state, payload) => initialState,
  GET_COLLECTION_FULFILLED: (state, payload) => ({
    ...state,
    [payload.name]: payload.collection,
  }),
};

export const reducer = (state = initialState, { type, payload }) =>
  actionHandlers[type] ? actionHandlers[type](state, payload) : state;

export default reducer;
