import { getPosts } from "actions";
import { GenericGrid } from "Grid";
import { useAction, usePosts } from "hooks";
import { PostCardHeader } from "PostCard";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

export const Component = ({ posts = [] }) => {
  return (
    <section className={styles.RelatedPosts}>
      <header>
        <h2>Related posts</h2>
        <h4>More designs from MAKE THREAD ORIGINALS</h4>
      </header>
      <main>
        <GenericGrid breadcrumbs={false} showFilters={false} small={true}>
          {posts.map((post) => (
            <Link to={`/post/${post._id}`}>
              <PostCardHeader user={post.author} />
              <img src={post.image} className={styles.Image} />
            </Link>
          ))}
        </GenericGrid>
      </main>
    </section>
  );
};

const useRelatedPosts = (post) => {
  useAction(getPosts, post);
  const { posts } = usePosts();
  return posts.slice(0, 3);
};

const RelatedPosts = ({ post = {} }) => {
  const relatedPosts = useRelatedPosts(post);

  return <Component posts={relatedPosts} />;
};

export default RelatedPosts;
