import React, { useRef, useState } from "react";
import styles from "./styles.module.scss";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { publishProduct } from "actions";
import { possibleSizes, PRODUCT_TYPES } from "config";
import { useAuthToken, useFiles, useProductById } from "hooks";
import { Form, Field } from "react-final-form";
import CheckBox from "CheckBox";
import { Input, TextArea } from "FormStuff";
import MultiPhotoSelect from "MultiPhotoSelect";

export const Publish = ({
  product = {},
  history,
  files = [],
  onImageSelect,
  values,
  clearFiles,
  handleSubmit,
  isReissue,
  refs,
  setPhotos,
  photos,
  errors,
  ...rest
}) => {
  return (
    <section className={styles.Publish}>
      <header>
        <h1>
          {isReissue ? "Publish" : "Edit"} {product.title}
        </h1>
        {Object.values(errors).length > 0 &&
          Object.values(errors).map((e, i) => (
            <div
              style={{ fontWeight: "bold", color: "red" }}
              key={"error_" + i}
            >
              {e}
            </div>
          ))}
      </header>
      <main>
        {/* {files.length === 0 && (
          <div>
            {hasMultiplePhotos(product) ? (
              <div className={styles.MultiplePhotoContainer}>
                {product.photos.map((p) => (
                  <img src={p} alt={"this product design"} key={p} />
                ))}
              </div>
            ) : (
              <img
                src={product.design || product.photos[0]}
                alt={"this product design"}
              />
            )}
          </div>
        )} */}
        {/* <PhotoUploadContainer
          files={files}
          clearFiles={clearFiles}
          isPublish={true}
        /> */}
        <h3>Title</h3>
        <Field name="title" type="text" component={Input} />
        <h3>Story</h3>
        <Field name="body" type="textarea" component={TextArea} />
        <h3>Size guide</h3>
        <Field name="sizeGuide" type="text" component={Input} />
        {/* <section className={styles.WarningText}>
          <header>Important</header>
          <ul>
            <li>Some important</li>
            <li>information</li>
          </ul>
        </section> */}
        {/* <input
          name="design"
          id="design"
          type="file"
          accept=".jpg, .png, .jpeg"
          multiple={true}
          onChange={onImageSelect}
          style={{ display: "none" }}
        /> */}
        <h3>Photos</h3>
        <MultiPhotoSelect photos={photos} setPhotos={setPhotos} refs={refs} />
        <div>
          <h3 htmlFor="price">
            Price (£)
            <Field name="price" type="currency" component={Input} />
          </h3>
          <h3 htmlFor="product_type">Product type</h3>
          <Field
            name="product_type"
            type="text"
            component="select"
            style={{ fontSize: "18px", padding: "0.5em" }}
          >
            {PRODUCT_TYPES.map((s) => (
              <option key={s} value={s}>
                {s}
              </option>
            ))}
          </Field>
          <h3>Sizes</h3>
          <div className={styles.CheckboxLabelContainer}>
            {possibleSizes.map((size) => (
              <label key={size} htmlFor={`sizes.${size}`}>
                <Field
                  name={`sizes.${size}`}
                  id={`sizes.${size}`}
                  type="checkbox"
                  component={CheckBox}
                  label={size}
                  selected={values.sizes[size]}
                />
              </label>
            ))}
          </div>
          <h3 htmlFor="ends_at">When should this end?</h3>
          <Field name="ends_at" type="datetime-local" component="input" />
          <div className={styles.Buttons}>
            <button className={styles.ButtonSecondary} onClick={history.goBack}>
              Cancel
            </button>
            <button className={styles.ButtonPrimary} onClick={handleSubmit}>
              Publish
            </button>
          </div>
        </div>
      </main>
    </section>
  );
};

const Container = (parentProps) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const product = useProductById(id);
  const history = useHistory();
  let initialPhotos = [...product.photos];
  while (initialPhotos.length < 5) {
    initialPhotos.push("");
  }
  const [photos, setPhotos] = useState(initialPhotos);
  const refs = useRef([]);
  const token = useAuthToken();

  const { files, clearFiles, onImageSelect } = useFiles();
  const validate = (values) => {
    const errors = {};
    // if (photos.filter(Boolean).length === 0) {
    //   errors.design = "Add at least one photo";
    // }
    if (!Object.values(values.sizes).some((s) => s)) {
      errors.size = "Add a size";
    }
    if (!values.ends_at || new Date(values.ends_at) === "Invalid Date") {
      errors.ends_at = "Enter a date";
    } else if (new Date(values.ends_at) <= new Date()) {
      errors.ends_at = "Date must me in the future";
    }
    return errors;
  };

  const sizes = product.sizes
    ? product.sizes.reduce((obj, size) => {
        obj[size] = true;
        return obj;
      }, {})
    : {};

  const { isReissue } = parentProps;
  const onSubmit = (values) =>
    dispatch(
      publishProduct(
        values,
        token,
        history,
        isReissue,
        refs?.current
          ?.filter((r) => r?.files?.length > 0)
          .map((r) => r.files[0]) || [],
        photos
      )
    );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        ...product,
        sizes,
        ends_at: new Date(product.ends_at).toti,
        price: (0.01 * product.price).toFixed(2),
        product_type: PRODUCT_TYPES[0],
        sizeGuide: product.size_guide,
      }}
      validate={validate}
    >
      {(formProps) => (
        <Publish
          {...formProps}
          {...parentProps}
          product={product}
          history={history}
          // onImageSelect={onImageSelect}
          // files={files}
          // clearFiles={clearFiles}
          setPhotos={setPhotos}
          refs={refs}
          photos={photos}
        />
      )}
    </Form>
  );
};

export default Container;
