import React from "react";
import LazyLoad from "react-lazyload";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

const useWornBy = () => {
  const data = [
    {
      image:
        "https://images.ctfassets.net/k05clop0362m/7LNBx92IPFi8BqBxS8vONf/7ebf7d230a2a0e15b30308d822205f8b/Worn-By--2.jpg",
      name: "Jenny Tattum",
      link: "/feed",
    },
    {
      image:
        "https://s3-eu-west-1.amazonaws.com/makethread-development-local/1629712446265_A85FDF98-6721-4766-BDA5-913C6589831C.jpeg",
      name: "Jenny Tattum",
      link: "/feed",
    },
    {
      image:
        "https://s3-eu-west-1.amazonaws.com/makethread-development-local/1629712446265_A85FDF98-6721-4766-BDA5-913C6589831C.jpeg",
      name: "Nicholas Pinnock",
      link: "/feed",
    },
  ];
  return data.slice(0, 3);
};

export const Component = ({
  wornByLeftPhoto,
  wornByTopPhoto,
  wornByBottomPhoto,
  wornByLeftLink = "/",
  wornByTopLink = "/",
  wornByBottomLink = "/",
  wornByLeftName,
  wornByTopName,
  wornByBottomName,
}) => {
  return (
    <LazyLoad once>
      <div className={styles.WornByContainer}>
        <section className={styles.WornBy}>
          <header>
            <h2 className={styles.LandingPageTitle}>Worn by</h2>
            <h3 className={styles.SubTitle}>
              Hand selected t-shirts, worn by celebs
            </h3>
          </header>
          <main className={styles.WornByImages}>
            <Link to={wornByLeftLink}>
              <img src={wornByLeftPhoto} />
              <div className={styles.WornByName}>
                <div>As worn by</div>
                <div className={styles.Name}>{wornByLeftName}</div>
                <button className={styles.ButtonSecondary}>View item</button>
              </div>
            </Link>
            <Link to={wornByTopLink}>
              <img src={wornByTopPhoto} />
              <div className={styles.WornByName}>
                <div>As worn by</div>
                <div className={styles.Name}>{wornByTopName}</div>
                <button className={styles.ButtonSecondary}>View item</button>
              </div>
            </Link>
            <Link to={wornByBottomLink}>
              <img src={wornByBottomPhoto} />
              <div className={styles.WornByName}>
                <div>As worn by</div>
                <div className={styles.Name}>{wornByBottomName}</div>
                <button className={styles.ButtonSecondary}>View item</button>
              </div>
            </Link>
          </main>
        </section>
      </div>
    </LazyLoad>
  );
};

const WornBy = (data) => {
  return <Component {...data} />;
};

export default WornBy;
