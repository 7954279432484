const initialState = [];

const actionHandlers = {
  CHANGE_IMAGES: (state, payload) => payload,
  LOGOUT: (state) => initialState,
  LOGIN_FULFILLED: (state) => initialState,
  CREATE_POST_FULFILLED: (state) => initialState,
  CREATE_PRODUCT_FULFILLED: (state) => initialState,
  CLEAR_FILES: (state) => initialState,
};

export const reducer = (state = initialState, { type, payload }) =>
  actionHandlers[type] ? actionHandlers[type](state, payload) : state;

export default reducer;
