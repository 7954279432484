import React from "react";
import styles from "./styles.module.scss";
import PostCard from "PostCard";
import { usePosts } from "hooks";
import { GenericGrid } from "Grid";

export const Component = ({ posts = [] }) => {
  return (
    <GenericGrid
      title={"Follow"}
      subtitle={"live posts from the community"}
      breadcrumbs={false}
    >
      {posts.map((p) => (
        <PostCard post={p} withinGrid={true} />
      ))}
    </GenericGrid>
  );
};

const Gallery = () => {
  const paginatedPosts = usePosts();
  return <Component {...paginatedPosts} />;
};

export default Gallery;
