const initialState = false;

export const reducer = (state = initialState, { type }) => {
  if (new RegExp(/.*PENDING$/g).test(type)) {
    return type;
  }
  if (
    new RegExp(/.*REJECTED/g).test(type) ||
    new RegExp(/.*FULFILLED/g).test(type)
  ) {
    return false;
  }
  return state;
};

export default reducer;
