import React from "react";
import styles from "./styles.module.scss";
import { useCountdown } from "hooks";

export const Component = ({ days = 0, hours = 0, mins = 0, secs = 0 }) => {
  const time = [
    [days, "days"],
    [hours, "hours"],
    [mins, "mins"],
    [secs, "secs"],
  ];
  return (
    <section className={styles.Countdown}>
      <header>Thread ends in</header>
      <main>
        {isNaN(days) ? (
          <></>
        ) : (
          time.map(([duration, label]) => (
            <div className={styles.CountdownItem}>
              <div>{duration}</div>
              <div>{label}</div>
            </div>
          ))
        )}
      </main>
    </section>
  );
};

const Countdown = ({ product }) => {
  const time = useCountdown(product.ends_at);
  return <Component {...time} />;
};

export default Countdown;
