import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Layout from "Grid";
import styles from "./styles.module.scss";

export const DraftCampaigns = ({ drafts }) => {
  return drafts.length > 0 ? (
    <main>
      <Layout productSet={drafts} />
    </main>
  ) : (
    <main className={styles.DraftCampaigns}>
      <h1>You haven't got any draft products</h1>
      <Link className={styles.ButtonSecondary} to="/new-product">
        Make a product
      </Link>
    </main>
  );
};

const mapStateToProps = ({ profile: { products = [] } }) => ({
  drafts: products.filter((c) => typeof c !== "string" && !c.live),
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(DraftCampaigns);
