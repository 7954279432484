import { get } from "App/api";
import { useAuthToken } from "hooks";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

export const Component = ({ products = [] }) => {
  return (
    <section className={styles.AdminEnded}>
      <main style={{ display: "flex", flexDirection: "column" }}>
        {products.map((a) => (
          <Link to={`/products/${a._id}`} style={{ color: "#333" }}>
            <div style={{ marginBottom: "1em" }}>{a.title}</div>
          </Link>
        ))}
      </main>
    </section>
  );
};

const AdminEnded = () => {
  const [products, setProducts] = useState(null);
  const token = useAuthToken();

  useEffect(() => {
    get({
      url: `/admin/ended`,
      token,
    }).then(setProducts);
  }, []);

  if (!products) {
    return <h1>Loading</h1>;
  }
  return <Component products={products} />;
};

export default AdminEnded;
