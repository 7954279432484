import React from "react";
import styles from "./styles.module.scss";

export const Select = ({
  name,
  options = [],
  label = "",
  input = {},
  meta: { touched, error, warning } = {},
  onChange = () => {},
}) => (
  <section className={styles.Select}>
    {label && <header>{label}</header>}
    <main>
      <select
        name={name}
        onChange={(e) => {
          onChange(e);
          input.onChange(e);
        }}
        value={input.value}
      >
        {options.map(({ value, label }) => (
          <option value={value}>{label}</option>
        ))}
      </select>
      {(error && (
        <span className={styles.FormError}>
          <i className="fa fa-exclamation-triangle" />
          {error}
        </span>
      )) ||
        (warning && (
          <span className={styles.FormWarning}>
            <i className="fa fa-exclamation" />
            {warning}
          </span>
        ))}
    </main>
  </section>
);

export const Number = (props) => <input type="number" min={0} {...props} />;

export const InputComponent = ({
  input,
  type = "text",
  meta: { touched, error, warning },
  placeholder,
  className,
}) => {
  return (
    <div className={styles[className]}>
      <div>
        <input
          className={touched && error ? styles.InputError : styles.Input}
          type={type}
          placeholder={placeholder}
          {...input}
        />
      </div>
      {touched &&
        ((error && (
          <span className={styles.FormError}>
            <i className="fa fa-exclamation-triangle" />
            {error}
          </span>
        )) ||
          (warning && (
            <span className={styles.FormWarning}>
              <i className="fa fa-exclamation" />
              {warning}
            </span>
          )))}
    </div>
  );
};

export const Input = (props) => (
  <InputComponent {...props} className={"InputAndErrorWrapper"} />
);
export const TwoColInput = (props) => (
  <InputComponent {...props} className={"TwoColErrorWrapper"} />
);

export const TextArea = ({
  input,
  meta: { touched, error, warning },
  placeholder,
}) => {
  return (
    <div className={styles.InputAndErrorWrapper}>
      <div>
        <textarea
          className={touched && error ? styles.InputError : styles.Input}
          placeholder={placeholder}
          {...input}
        />
      </div>
      {touched &&
        ((error && (
          <span className={styles.FormError}>
            <i className="fa fa-exclamation-triangle" />
            {error}
          </span>
        )) ||
          (warning && (
            <span className={styles.FormWarning}>
              <i className="fa fa-exclamation" />
              {warning}
            </span>
          )))}
    </div>
  );
};
