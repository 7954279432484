import CommentList from "CommentList";
import { useNavigate } from "hooks";
import Like from "Like";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

export const PostCardHeader = ({
  user: { thumbnail, profilePicture, username } = {},
}) => {
  const navigate = useNavigate();
  return (
    <header
      className={styles.PostCardHeader}
      onClick={() => navigate(`/profile/${username}/gallery`)}
    >
      <img src={thumbnail || profilePicture} alt="profile" />
      <p>{username}</p>
    </header>
  );
};
export const Component = ({
  post = {},
  header = true,
  withinGrid,
  withinSmallGrid = false,
}) => {
  return (
    <section className={styles.PostCard}>
      {header && <PostCardHeader user={post.author} />}
      <main>
        <Link to={`/post/${post._id}`}>
          <img
            alt={post.title}
            src={post.image}
            className={
              withinSmallGrid
                ? styles.PostCardImageWithinGrid
                : styles.PostCardImage
            }
          />
        </Link>
      </main>
      <footer>
        <Like post={post} />
        <CommentList
          limit={4}
          comments={post.comments}
          postId={post._id}
          withinGrid={withinGrid}
        />
      </footer>
    </section>
  );
};

const PostCard = ({ post, header, withinGrid, withinSmallGrid }) => {
  return (
    <Component
      post={post}
      header={header}
      withinGrid={withinGrid}
      withinSmallGrid={withinSmallGrid}
    />
  );
};

export default PostCard;
