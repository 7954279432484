import React from "react";
import styles from "./styles.module.scss";
import FeatherIcon from "feather-icons-react";
import { useNavigate, useToggle } from "hooks";
import Originals from "./originals.png";
import Influencers from "./influencers.png";
import Independents from "./independents.png";
import Designers from "./designers.png";
import Footer from "Footer";

export const initialState = [
  { name: "Home", link: "/" },
  {
    name: "Shop",
    link: "/shop",
  },
  {
    name: "Collections",
    open: false,
    items: [
      {
        name: "Originals",
        link: "/shop/collections/originals",
        image: Originals,
      },
      {
        name: "Influencers",
        link: "/shop/collections/influencers",
        image: Influencers,
      },
      {
        name: "Independents",
        link: "/shop/collections/independents",
        image: Independents,
      },
      {
        name: "Designers",
        link: "/shop/collections/designers",
        image: Designers,
      },
    ],
  },
  {
    name: "Ending soon",
    link: "/shop/ending-soon",
  },
  {
    name: "Follow",
    link: "/follow/gallery",
  },
  {
    name: "About",
    link: "/about-us",
  },
  {
    name: "Sustainability",
    link: "/sustainability",
  },
  // {
  //   name: "Press",
  //   link: "/press",
  // },
];

export const Component = ({ navState, toggle }) => {
  const navigate = useNavigate();
  const onClick = (item) => () => {
    navigate(item.link);
    toggle();
  };
  const [collectionsIsOpen, toggleCollections] = useToggle();

  return (
    <>
      <button onClick={toggle} className={styles.X}>
        <FeatherIcon icon={"close"} size={18} />
      </button>
      <nav className={styles.Menu}>
        <ul>
          {navState.map((item, i) => {
            if (item.items && item.items.length > 0) {
              return (
                <li key={`${item.name}_${i}`}>
                  <button onClick={() => toggleCollections(item.name)}>
                    {item.name}
                    <FeatherIcon
                      icon={collectionsIsOpen ? "chevron-up" : "chevron-down"}
                      size={16}
                    />
                  </button>
                  {item.items && collectionsIsOpen ? (
                    <ul className={styles.SubMenu}>
                      {item.items.map((subItem) => {
                        return (
                          <li
                            style={{ backgroundImage: `url(${subItem.image})` }}
                          >
                            <button onClick={onClick(subItem)}>
                              {subItem.name}
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <></>
                  )}
                </li>
              );
            } else {
              return (
                <li key={`${item.name}_${i}`}>
                  <button onClick={onClick(item)}>{item.name}</button>
                </li>
              );
            }
          })}
        </ul>
        <div className={styles.FooterContainer}>
          <Footer logo={false} join={false} />
        </div>
      </nav>
    </>
  );
};

const Menu = ({ open, toggle }) => {
  return <Component navState={initialState} toggle={toggle} open={open} />;
};

export default Menu;
