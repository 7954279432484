import React from "react";
import {
  useCollection,
  useEndingSoonProducts,
  useProducts,
  useSortAndFilter,
} from "hooks";
import { Route, useParams } from "react-router";
import Layout from "Grid";

const subtitle = "Top threads from the design community";

export const ProductGrid = () => {
  const products = useProducts();
  const { sortByOptions, filterOptions } = useSortAndFilter();

  return <Layout productSet={products} title={"Shop"} subtitle={subtitle} />;
};

export const EndingSoon = () => {
  const products = useEndingSoonProducts();
  const { sortByOptions, filterOptions } = useSortAndFilter();

  const endingSoonProducts = products.sort(
    (p1, p2) => new Date(p2.ends_at) - new Date(p1.ends_at)
  );
  return (
    <Layout
      productSet={endingSoonProducts}
      title={"Ending soon"}
      subtitle={subtitle}
    />
  );
};

export const Collection = () => {
  const { name } = useParams();
  const collection = useCollection(name);
  const { sortByOptions, filterOptions, query } = useSortAndFilter();

  return <Layout productSet={collection} title={name} subtitle={subtitle} />;
};

const ShopRouter = () => {
  return (
    <>
      <Route exact path={"/shop"} component={ProductGrid} />
      <Route exact path={"/shop/ending-soon"} component={EndingSoon} />
      <Route exact path={"/shop/collections/:name"} component={Collection} />
    </>
  );
};

export default ShopRouter;
