export const initialState = { appUpdateAvailable: false };

const actionHandlers = {
  APP_UPDATE_AVAILABLE: state => ({ ...state, appUpdateAvailable: true }),
  HIDE_UPDATE_BANNER: state => ({ ...state, appUpdateAvailable: false })
};

export const reducer = (state = initialState, { type, payload }) =>
  actionHandlers[type] ? actionHandlers[type](state, payload) : state;

export default reducer;
