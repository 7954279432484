import Comment from "Comment";
import CommentInput from "CommentInput";
import React, { useEffect, useState } from "react";
import List from "Shared/List";
import styles from "./styles.module.scss";

export const Component = ({
  comments = [],
  desktop,
  postId,
  setComments,
  setAllComments,
  withinGrid,
}) => {
  const items = comments.map((c) => (
    <Comment
      comment={c}
      setComments={setComments}
      setAllComments={setAllComments}
      postId={postId}
    />
  ));
  return (
    <div
      className={
        withinGrid
          ? styles.CommentListGrid
          : desktop
          ? styles.CommentListDesktop
          : styles.CommentList
      }
    >
      <List
        components={items}
        style={{ overflow: withinGrid ? "hidden" : "initial" }}
      />
      <CommentInput postId={postId} setComments={setComments} />
    </div>
  );
};

const CommentList = ({
  comments = [],
  limit,
  desktop = false,
  postId,
  withinGrid = false,
}) => {
  const [slice, setSlice] = useState(
    limit ? comments.slice(0, limit) : comments
  );
  useEffect(() => {
    setSlice(limit ? comments.slice(comments.length - limit) : comments);
  }, [comments]);

  const setComments = (comment) =>
    setSlice((comments) =>
      limit
        ? [...comments, comment].slice(comments.length - limit)
        : [...comments, comment]
    );

  return (
    <Component
      comments={slice}
      desktop={desktop}
      postId={postId}
      setComments={setComments}
      setAllComments={(comments) =>
        setSlice(limit ? comments.slice(comments.length - limit) : comments)
      }
      withinGrid={withinGrid}
    />
  );
};

export default CommentList;
