import React, { useState } from "react";
import styles from "./styles.module.scss";
import { useDispatch } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import { likePost, unlikePost } from "actions";
import { useAuthToken, useNavigate, useProfile } from "hooks";

export const Component = ({
  isLikedByUser = false,
  toggleLikedPost = () => {},
  numberOfComments,
  numberOfLikes,
  postId,
  viewAll = true,
}) => {
  return (
    <div className={styles.Like}>
      <div className={styles.LikeLeft}>
        <button
          onClick={toggleLikedPost}
          className={isLikedByUser ? styles.Liked : styles.NotLiked}
        >
          <FeatherIcon icon={"heart"} size={20} />
        </button>
        <div>
          <b>{isLikedByUser ? "Liked" : "Like?"}</b>
          <span>{numberOfLikes} like this</span>
        </div>
      </div>
      {viewAll && (
        <Link to={`/post/${postId}`}>
          {numberOfComments > 2
            ? `view all ${numberOfComments} comments`
            : "View post"}
        </Link>
      )}
    </div>
  );
};

const Like = ({ post = { likes: [], comments: [] }, viewAll = true }) => {
  // const post = useSelector((state) => state.posts.find((p) => p._id == postId));
  const postId = post._id;
  const dispatch = useDispatch();
  const token = useAuthToken();
  const isGuest = !Boolean(token);
  const profile = useProfile();
  //needs a subscription in state to posts.

  const isLikedByUser = isGuest
    ? false
    : Boolean(post.likes && post.likes.includes(profile._id));
  const [liked, setLiked] = useState(isLikedByUser);

  const navigate = useNavigate();
  const redirectToLogin = () => navigate("/login");

  const toggleLikedPost = isGuest
    ? redirectToLogin
    : !liked
    ? () => {
        dispatch(likePost({ id: postId, token }));
        setLiked(true);
      }
    : () => {
        dispatch(unlikePost({ id: postId, token }));
        setLiked(false);
      };

  const numberOfLikes = post.likes
    ? liked
      ? post.likes.length + 1
      : post.likes.length
    : 0;
  const numberOfComments = post.comments ? post.comments.length : 0;

  return (
    <Component
      numberOfComments={numberOfComments}
      numberOfLikes={numberOfLikes}
      postId={postId}
      isLikedByUser={liked}
      viewAll={viewAll}
      toggleLikedPost={toggleLikedPost}
    />
  );
};

export default Like;
