export const DEFAULT_PRICE_OF_A_CAMPAIGN = 2800;
export const NUMBER_SOLD_THRESHOLD = 50;
export const COST_OF_SHIPPING_IN_PENCE = 299;
export const COST_OF_INTERNATIONAL_SHIPPING_IN_PENCE = 690;

export const campaignStyles = {
  unisex_relaxed: "Unisex relaxed",
  mens_fitted: "Mens' fitted",
  womens_fitted: "Womens' fitted",
  one_style: "One style",
};

export const NEW_SHIPPING_COUNTRIES = {
  "United Kingdom": 299,
  "United States": 1599,
  Australia: 1599,
  Canada: 1599,
  France: 799,
  Germany: 799,
  Greece: 799,
  "Ireland {Republic}": 799,
  Italy: 799,
  Poland: 799,
  Portugal: 799,
  Spain: 799,
};

export const campaignStyleKeys = Object.keys(campaignStyles);

export const possibleSizes = ["xs", "s", "m", "l", "xl", "xxl", "3xl", "3-4", "5-6", "7-8", "9-11", "12-14"];

export const PRINT_TYPES = ["printed", "embroidered", "flocked"];

export const CAMPAIGN_TYPES = ["standard", "charity"];

export const PRODUCT_TYPES = [
  "tshirts",
  "jumpers",
  "hoodies",
  "joggers",
  "shorts",
  "bags",
  "hats",
];

export const PRODUCT_TYPE_LABELS = {
  tshirts: "T shirts",
  jumpers: "Jumpers",
  hoodies: "Hoodies",
  joggers: "Joggers",
  shorts: "Shorts",
  bags: "Bags",
  hats: "Hats",
};

export const FREE_DELIVERY_THRESHOLD = 5000;
