import { createStore, applyMiddleware, compose } from "redux";
import App from "./reducers";
import ReduxThunk from "redux-thunk";
import promiseMiddleware from "redux-promise-middleware";
import persistSessionState from "redux-sessionstorage";
import persistState from "redux-localstorage";
import { createTracker } from "redux-segment";
// import * as serviceWorker from "serviceWorker";

const Segment = createTracker();

const middlewares = [ReduxThunk, promiseMiddleware, Segment];

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 10,
    })) ||
  compose;

const enhancers = composeEnhancers(
  applyMiddleware(...middlewares),
  persistSessionState(),
  persistState()
);

export function createDefaultStore() {
  return createStore(App, enhancers);
}

let Store = createStore(App, enhancers);

// serviceWorker.register({
//   onUpdate: () => Store.dispatch({ type: "APP_UPDATE_AVAILABLE" })
// });

// hidden for covid19 campaign

// setTimeout(function() {
//   Store.dispatch({ type: "SHOW_OFFER_POPUP" });
// }, 10000);

export default Store;
