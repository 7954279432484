import { useLoggedInStatus } from "hooks";
import React from "react";
import styles from "./styles.module.scss";
import FeatherIcon from "feather-icons-react";
import { Link, useLocation } from "react-router-dom";

export const Component = ({ isLoggedIn = false, email = "", orderId = "" }) => {
  return (
    <section className={styles.Receipt}>
      <main>
        <div className={styles.Tick}>
          <FeatherIcon icon={"check"} size={30} />
        </div>
        <h2>Order confirmed</h2>
        <b>Order no.: #{orderId}</b>
        <p>
          We will send confirmation of your order to <b>{email}</b> shortly.
        </p>
        <Link to="/shop" className={styles.ContinueShopping}>
          <FeatherIcon icon={"arrow-left"} size={16} />
          continue shopping
        </Link>
      </main>
      {!isLoggedIn && (
        <footer className={styles.Join}>
          <h2>Join the Make Thread community</h2>
          <p>
            We’re a sustainable social commerce platform on a mission to make a
            difference to the planet.
          </p>
          <div className={styles.Buttons}>
            <Link to="/sign-up" className={styles.ButtonPrimary}>
              Join Make Thread
            </Link>
            <Link to="/shop" className={styles.ButtonSecondary}>
              Continue shopping
            </Link>
          </div>
        </footer>
      )}
    </section>
  );
};

const Receipt = () => {
  const isLoggedIn = useLoggedInStatus();
  const { state } = useLocation();
  const { email, orderId } = state;
  return <Component isLoggedIn={isLoggedIn} email={email} orderId={orderId} />;
};

export default Receipt;
