import { Input } from "FormStuff";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import styles from "./styles.module.scss";
import FeatherIcon from "feather-icons-react";
import { post } from "App/api";

const BeenSubmitted = ({ email = "" }) => {
  return (
    <main className={styles.Submitted}>
      <FeatherIcon icon={"check-circle"} size={72} />
      <div>
        <div>Thank you!</div>
        <div>
          You've been added to the newsletter <b>{email}</b>
        </div>
      </div>
    </main>
  );
};

export const Component = ({ handleSubmit, submitted }) => {
  return (
    <section className={styles.MailingList}>
      <header>
        <h1>
          WANT THE LATEST SLOW FASHION NEWS, STYLING AND ADVICE STRAIGHT TO YOUR
          INBOX?
        </h1>
      </header>
      {submitted ? (
        <BeenSubmitted email={submitted} />
      ) : (
        <main>
          <label>Email address</label>
          <Field name="email" component={Input} />
          <button onClick={handleSubmit} className={styles.ButtonSecondary}>
            Sign me up
          </button>
        </main>
      )}
    </section>
  );
};

const validate = (values) => {
  const errors = {};
  if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)) {
    errors.email = "Enter a valid email address";
  }
  return errors;
};

const MailingList = () => {
  const [submitted, setSubmitted] = useState("");

  const signUpToMailingList = async (body) => {
    const response = await post({
      url: "/mailing-list",
      body,
    });
    if (response) {
      setSubmitted(body.email);
    } else {
    }
  };

  return (
    <Form onSubmit={signUpToMailingList} validate={validate}>
      {({ values, handleSubmit, errors }) => (
        <Component
          submitted={submitted}
          values={values}
          handleSubmit={handleSubmit}
          errors={errors}
        />
      )}
    </Form>
  );
};

export default MailingList;
