import React from "react";
import styles from "./styles.module.scss";

export const Returns = () => (
  <div className={styles.Returns}>
    <h1>STRIVING TO ACHIEVE ZERO-WASTE</h1>
    <h2>
      “Our vision is to reduce wasted and unsold fashion items which would
      normally end up in landfills or being burned.”
    </h2>
    <p>
      At Make Thread we do things a little differently by taking an ethical
      stance in the way we consume fashion.
    </p>
    <p>
      We aim to strive for a zero-waste policy whereby all of our products are
      wanted and loved items which are manufactured on-demand. Every product
      made has been purchased through a pre-order system within a campaign that
      does not over-manufacture any designs, ensuring there are no wasted or
      unsold items for each campaign.
    </p>
    <p>
      Removing deadstock from our everyday workings means our products are not
      willfully destroyed, burned or shipped to landfills.
    </p>
    <h2>RETURNS AND REFUNDS</h2>
    <p>Our cancellation and refund policy only applies in the case of:</p>
    <ul>
      <li>items being faulty; and</li>
      <li>
        items being poor in quality due to manufacturing errors as a result of
        being made on-demand.
      </li>
      <li>
        {" "}
        We do not accept returns or issue refunds for unwanted items as we
        manufacture garments on-demand, creating exclusive designs. A thorough
        size guide can be accessed prior to purchase of the garment. Please use
        this guide as a reference for sizing and style of the garment.{" "}
      </li>
      <li>
        If you have returned the purchased garment/item which are faulty or poor
        in quality, we will refund the price of the Goods together with any
        postage costs and will refund you on the credit card or debit card used
        by you to pay. You have 30 days to return your order, beginning from the
        day you (or another person assigned by you) received it.{" "}
      </li>
    </ul>

    <h2>RETURNS OF FAULTY ITEMS ONLY </h2>
    <p>
      In the rare case of receiving a faulty or poor quality item from us
      contact info@makethread.com to arrange a return of your faulty item.{" "}
    </p>
    <h2>
      MAKE THREAD AIMS TO HELP SAVE OUR PLANET BY CONSUMING SMARTER AND BETTER.
    </h2>
  </div>
);

export default Returns;
