import { get, post, deleteRequest, uploadFiles } from "App/api";
import { reset } from "redux-form";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { useDispatch } from "react-redux";
import { useAuthToken } from "hooks";
import { useState } from "react";

export const useFollowActions = (id) => {
  const dispatch = useDispatch();
  const token = useAuthToken();

  const follow = () =>
    dispatch({
      type: "FOLLOW",
      payload: post({
        url: "/follow",
        body: { id },
        token,
      }),
    });

  const unfollow = () =>
    dispatch({
      type: "UNFOLLOW",
      payload: post({
        url: "/unfollow",
        body: { id },
        token,
      }),
    });
  return { follow, unfollow };
};

export const follow = (id, token) => (dispatch) =>
  dispatch({
    type: "FOLLOW",
    payload: post({
      url: "/follow",
      body: { id },
      token,
    }),
  });

export const unfollow = (id) => (dispatch, getState) =>
  dispatch({
    type: "UNFOLLOW",
    payload: post({
      url: "/unfollow",
      body: { id },
      token: getState().auth.token,
    }),
  });

export const deletePostAction = (id, history, token, redirect) => ({
  type: "DELETE_POST",
  payload: post({
    url: "/posts/delete",
    body: { id },
    token,
  }).then((data) => {
    history.push(redirect);
    return data;
  }),
});

export const getProfile = (username) => () => ({
  type: "GET_PROFILE",
  payload: get({
    url: `/profile/${username}`,
  }),
});

export const getPostById = (id) => (dispatch, getState) =>
  dispatch({
    type: "GET_POST",
    payload: get({
      url: `/post/${id}`,
    }),
  });

export const getUserById = (id) => (dispatch, getState) =>
  dispatch({
    type: "GET_USER",
    payload: get({
      url: `/user/${id}`,
    }),
  });

export const getAdminData = () => (dispatch, getState) =>
  dispatch({
    type: "GET_ADMIN_DATA",
    payload: get({
      url: `/admin/data`,
      token: getState().auth.token,
    }),
  });

export const getOrderById = (orderId) => (dispatch, getState) =>
  dispatch({
    type: "GET_ORDER",
    payload: get({
      url: `/admin/orders/${orderId}`,
      token: getState().auth.token,
    }),
  });

export const clearError = () => (dispatch) =>
  dispatch({
    type: "CLEAR_ERROR",
  });

export const changeImages = (dispatch) => (event) =>
  dispatch({
    type: "CHANGE_IMAGES",
    payload: Array.from(event.target.files).map(URL.createObjectURL),
  });

export const clearFiles = (event) => (dispatch) =>
  dispatch({
    type: "CHANGE_IMAGES",
    payload: [],
  });

export const changeImage = (event) => (dispatch) =>
  dispatch({
    type: "CHANGE_IMAGE",
    payload: URL.createObjectURL(event.target.files[0]),
  });

export const clearFile = (event) => (dispatch) =>
  dispatch({
    type: "CHANGE_IMAGE",
    payload: "",
  });

export const toggleMenu = () => (dispatch) =>
  dispatch({
    type: "TOGGLE_MENU",
  });

export const hideMenu = () => (dispatch) =>
  dispatch({
    type: "HIDE_MENU",
  });

export const updateFeed = () => (dispatch, getState) =>
  dispatch({
    type: "UPDATE_FEED",
    payload: get({
      url: "/feed",
      token: getState().auth.token,
    }),
  });

export const getAboutUs = () => (dispatch, getState) =>
  dispatch({
    type: "GET_ABOUT_US",
    payload: get({
      url: "/about-us",
    }),
  });

export const getTermsAndConditions = () => (dispatch, getState) =>
  dispatch({
    type: "GET_TERMS_AND_CONDITIONS",
    payload: get({
      url: "/terms-and-conditions",
    }),
  });

export const getCommentsForPost = (postId) => (dispatch, getState) =>
  dispatch({
    type: "GET_COMMENTS",
    payload: get({
      url: `/comments/${postId}`,
    }),
  });

export const createToken = (stripe, history) => (dispatch, getState) => {
  stripe
    .createToken({
      name: getState().profile.username || `guest${Math.random()}`,
    })
    .then(({ token }) => {
      if (token) {
        dispatch({
          type: "SET_STRIPE_TOKEN",
          payload: token,
        });
        history.push("/checkout");
        return;
      } else {
        dispatch({
          type: "SET_STRIPE_TOKEN_REJECTED",
          payload:
            "Your card details could not be recognised as valid. If you're sure they're right contact your bank, or email us at info@makethread.com",
        });
      }
    })
    .catch((error) => {});
};

export const payment =
  ({ paymentMethod, history, validate }) =>
  (dispatch, getState) => {
    const { basket } = getState();

    if (!paymentMethod && !paymentMethod.card) {
      return dispatch({
        type: "PAYMENT_REJECTED",
        payload: { error: "You need to add a card before you can pay" },
      });
    }

    const deliveryDetails = getState().form.DeliveryDetails.values;
    const errors = validate(deliveryDetails);
    if (JSON.stringify(errors["delivery-address"]) !== "{}") {
      return dispatch({
        type: "PAYMENT_REJECTED",
        payload: {
          error:
            "There are some errors with your delivery details, please make sure you've filled everything in",
        },
      });
    }
    const { canTrackUser, uuid } = getState().analytics;

    let voucherCode = getState().applyVoucher.voucherIsValid
      ? getState().form.vouchers.values.voucherCode
      : "";

    dispatch({
      type: "PAYMENT",
      payload: post({
        url: "/order",
        body: {
          paymentMethod,
          basket: Object.values(basket).map(
            ({ _id, quantity, style, size }) => ({
              // TODO: change to productId
              campaignId: _id,
              quantity,
              style,
              size,
            })
          ),
          deliveryDetails,
          voucherCode,
        },
        token: getState().auth.token,
      }).then((data) => {
        setTimeout(function () {
          history.push("/receipt");
        }, 200);
        dispatch(reset("DeliveryDetails"));
        dispatch(reset("vouchers"));

        if (canTrackUser) {
          const receipt = data.data.receipt;
          ReactGA.plugin.execute("ecommerce", "addTransaction", {
            id: uuid,
            revenue: receipt.price / 100,
          });
          ReactGA.plugin.execute("ecommerce", "send");
          ReactPixel.track("Purchase", {
            content_type: "product",
            value: receipt.price / 100,
            contents: receipt.basket,
            currency: "GBP",
          });
        }
        return data;
      }),
    });
  };

export const getPaymentSecret =
  ({ paymentMethod, validate, stripe, card, history }) =>
  (dispatch, getState) => {
    const button = document.getElementById("payment-submit");
    button.disabled = true;

    const { basket } = getState();

    if (!card) {
      return dispatch({
        type: "PAYMENT_REJECTED",
        payload: { error: "You need to add a card before you can pay" },
      });
    }

    const deliveryDetails = getState().form.DeliveryDetails.values;
    const errors = validate(deliveryDetails);
    if (JSON.stringify(errors["delivery-address"]) !== "{}") {
      return dispatch({
        type: "PAYMENT_REJECTED",
        payload: {
          error:
            "There are some errors with your delivery details, please make sure you've filled everything in",
        },
      });
    }

    let voucherCode = getState().applyVoucher.voucherIsValid
      ? getState().form.vouchers.values.voucherCode
      : "";

    const { canTrackUser, uuid } = getState().analytics;

    dispatch({
      type: "GET_PAYMENT_SECRET",
      payload: post({
        url: "/order-products",
        body: {
          paymentMethod,
          basket: Object.values(basket).map(
            ({ _id, quantity, style, size }) => ({
              productId: _id,
              quantity,
              style,
              size,
            })
          ),
          deliveryDetails,
          voucherCode,
        },
        token: getState().auth.token,
      }),
    })
      .then(async (data) => {
        const clientSecret = data.value.secret;
        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: { card },
        });
        if (result.error) {
          throw result.error;
        } else {
          return data;
        }
      })
      .then((data) =>
        pushAndTrack(data, history, dispatch, getState, canTrackUser, uuid)
      )
      .catch((e) => {
        const message = e && e.message ? e.message : e;
        dispatch({
          type: "PAYMENT_REJECTED",
          payload: message,
        });
      });
  };

export const pushAndTrack = (
  data,
  history,
  dispatch,
  getState,
  canTrackUser,
  uuid
) => {
  setTimeout(function () {
    history.push("/receipt");
  }, 200);
  dispatch(reset("DeliveryDetails"));
  dispatch(reset("vouchers"));
  dispatch({ type: "PURCHASE_COMPLETED" });

  if (canTrackUser) {
    const receipt = data.value.receipt;
    ReactGA.plugin.execute("ecommerce", "addTransaction", {
      id: uuid,
      revenue: receipt.price / 100,
    });
    ReactGA.plugin.execute("ecommerce", "send");
    ReactPixel.track("Purchase", {
      content_type: "product",
      value: receipt.price / 100,
      contents: receipt.basket,
      currency: "GBP",
    });
  }
  return data;
};

export const deactivateUser = (id) => (dispatch, getState) =>
  dispatch({
    type: "DEACTIVATE_USER",
    payload: post({
      url: "/admin/deactivate-user",
      body: { id },
      token: getState().auth.token,
    }),
  });

export const reactivateUser = (id) => (dispatch, getState) =>
  dispatch({
    type: "REACTIVATE_USER",
    payload: post({
      url: "/admin/reactivate-user",
      body: { id },
      token: getState().auth.token,
    }),
  });

export const approveApplication = (id, history, role) => (dispatch, getState) =>
  dispatch({
    type: "APPROVE_APPLICATION",
    payload: post({
      url: "/admin/approve-application",
      body: { id, role },
      token: getState().auth.token,
    }).then((data) => {
      history.push("/admin");
      return data;
    }),
  });

export const rejectApplication = (id, history) => (dispatch, getState) =>
  dispatch({
    type: "REJECT_APPLICATION",
    payload: post({
      url: "/admin/reject-application",
      body: { id },
      token: getState().auth.token,
    }).then((data) => {
      history.push("/admin");
      return data;
    }),
  });

export const hideMenuOrGoBack = (history) => (dispatch, getState) => {
  if (getState().menu) {
    return dispatch({
      type: "HIDE_MENU",
    });
  } else {
    history.goBack();
  }
};

export const getVoucherCodeData = () => (dispatch, getState) =>
  dispatch({
    type: "GET_VOUCHER_CODE_DATA",
    payload: get({
      url: "/voucher-data",
    }),
  });

export const getStarPicks = () => (dispatch, getState) =>
  dispatch({
    type: "GET_STAR_PICKS",
    payload: get({
      url: "/star-picks",
    }),
  });

// export const tryToApplyVoucher = (body) => (dispatch, getState) => {
//   const state = getState();
//   const voucherCode = state.form.vouchers.values
//     ? state.form.vouchers.values.voucherCode
//     : "";
//   const email = state.form.DeliveryDetails.values
//     ? state.form.DeliveryDetails.values.email
//     : "";
//   dispatch({
//     type: "APPLY_VOUCHER",
//     payload: post({
//       url: "/apply-voucher",
//       body: {
//         voucherCode,
//         email,
//       },
//     }),
//   });
// };

export const likeProduct = (id) => (dispatch, getState) =>
  dispatch({
    type: "LIKE_PRODUCT",
    payload: post({
      url: "/products/like",
      body: { id },
      token: getState().auth.token,
    }),
  });

export const unlikeProduct = (id) => (dispatch, getState) =>
  dispatch({
    type: "UNLIKE_PRODUCT",
    payload: post({
      url: "/products/unlike",
      body: { id },
      token: getState().auth.token,
    }),
  });

export const publishProduct = (
  values,
  token,
  history,
  isReissue,
  files,
  photos
) => {
  const body = new FormData();
  for (const key in values) {
    if (key === "photos") {
      continue;
    }
    if (key === "sizes") {
      const sizes = Object.entries(values[key])
        .filter((x) => x[1])
        .map((x) => x[0]);
      body.append("sizes", JSON.stringify(sizes));
    }

    body.append(key, values[key]);
  }

  photos.forEach((photo) => {
    body.append("photos", photo);
  });
  // const files = Array.from(document.querySelector('input[type="file"]').files);

  if (files && files.length > 0) {
    files.forEach((file) => {
      body.append("files", file);
    });
  }
  body.append("isReissue", isReissue ? 1 : 0);

  return {
    type: "PUBLISH_PRODUCT",
    payload: uploadFiles({
      url: "/admin/publish-product",
      body,
      token,
    }).then((data) => {
      history.push(`/products/${values._id}`);
      return data;
    }),
  };
};

export const unpublishProduct = (id) => (dispatch, getState) =>
  dispatch({
    type: "UNPUBLISH_PRODUCT",
    payload: post({
      url: "/admin/unpublish-product",
      body: { id },
      token: getState().auth.token,
    }),
  });

export const updateProduct = (productId) => (dispatch, getState) =>
  dispatch({
    type: "UPDATE_PRODUCT",
    payload: post({
      url: `/admin/products/${productId}/update`,
      body: getState().form.edit.values,
      token: getState().auth.token,
    }),
  });

export const setStarPick =
  ({ product, position }) =>
  (dispatch, getState) =>
    dispatch({
      type: "SET_STAR_PICKS",
      payload: post({
        url: "/admin/star-picks",
        body: { product, position },
        token: getState().auth.token,
      }).then((data) => {
        window.scrollTo(0, 0);
        return data;
      }),
    });

export const getLiveProducts = () => ({
  type: "GET_LIVE_PRODUCTS",
  payload: get({
    url: "/live-products",
  }),
});

export const getEndingSoonProducts = () => ({
  type: "GET_LIVE_PRODUCTS",
  payload: get({
    url: "/live-products?endingSoon=true",
  }),
});

export const getProductById = (id) => ({
  type: "GET_PRODUCT",
  payload: get({
    url: `/product/${id}`,
  }),
});

export const getCollection = (name) => () => ({
  type: "GET_COLLECTION",
  payload: get({
    url: `/collections/${name}`,
  }),
});

export const getDesigners = () => ({
  type: "GET_DESIGNERS",
  payload: get({
    url: "/designers",
  }),
});

export const getPosts = (page) => () => ({
  type: "GET_POSTS",
  payload: get({
    // TO ALLOW IN PUBLIC ROUTES
    // url: `/posts?page=${page}`,
    url: "/posts",
  }),
});

export const getMyProfile = (token) => () => ({
  type: "GET_MY_PROFILE",
  payload: get({
    url: `/profile`,
    token,
  }),
});

export const likePost =
  ({ id, token }) =>
  () => ({
    type: "LIKE_POST",
    payload: post({
      url: "/posts/like",
      body: { id },
      token,
    }),
  });

export const unlikePost =
  ({ id, token }) =>
  () => ({
    type: "UNLIKE_POST",
    payload: post({
      url: "/posts/unlike",
      body: { id },
      token,
    }),
  });

export const moveToBag = (dispatch, product, navigate) => () => {
  dispatch({
    type: "REMOVE_FROM_SAVED_LIST",
    payload: product,
  });
  dispatch({
    type: "ADD_TO_BASKET",
    payload: product,
  });
  navigate("/basket");
};

export const commentOnPost = ({
  dispatch,
  token,
  postId,
  newComment,
  callback = (x) => x,
}) => {
  dispatch({
    type: "COMMENT_ON_POST",
    payload: post({
      url: "/posts/comment",
      token,
      body: { comment: newComment, id: postId },
    }).then(callback),
  });
};

export const deleteComment =
  ({ dispatch, token, postId, commentId, callback }) =>
  () =>
    dispatch({
      type: "DELETE_COMMENT",
      payload: post({
        url: "/delete-comment",
        token,
        body: { postId, commentId },
      }).then(callback),
    });

export const deleteProduct =
  ({ dispatch, token, productId }) =>
  () =>
    dispatch({
      type: "DELETE_PRODUCT",
      payload: deleteRequest({
        url: `/admin/products/${productId}`,
        token,
      }),
    });

export const useDeleteProduct = (productId, cb) => {
  const dispatch = useDispatch();
  const token = useAuthToken();
  const [{ error }, setError] = useState({});
  const [{ message }, setMessage] = useState({});

  const deleteProduct = () =>
    dispatch({
      type: "DELETE_PRODUCT",
      payload: deleteRequest({
        url: `/admin/products/${productId}`,
        token,
      })
        .then((data) => {
          setMessage(data);
          return data;
        })
        .catch(setError),
    });
  return { deleteProduct, error, message };
};
