import React from "react";
import styles from "./styles.module.scss";
import FeatherIcon from "feather-icons-react";

export const CheckBox = ({
  name,
  selected,
  label,
  value,
  input = {},
  meta,
  id,
  ...rest
}) => (
  <label className={styles.CheckBoxLabel} htmlFor={name}>
    {input.checked ? (
      <div className={styles.CheckBox}>
        <FeatherIcon icon={"check"} size={16} />
      </div>
    ) : (
      <div className={styles.EmptyCheckBox}></div>
    )}
    <input id={id} name={name} type="checkbox" value={value} {...input} />
    {label}
  </label>
);

// TODO
export const Radio = ({
  name,
  selected,
  label,
  value,
  input = {},
  meta,
  id,
  ...rest
}) => (
  <label className={styles.CheckBoxLabel} htmlFor={name}>
    {input.checked ? (
      <div className={styles.CheckBox}>
        <FeatherIcon icon={"check"} size={16} />
      </div>
    ) : (
      <div className={styles.EmptyCheckBox}></div>
    )}
    <input id={id} name={name} type="radio" value={value} {...input} />
    {label}
  </label>
);

export default CheckBox;
