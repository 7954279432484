export const initialState = [];

const actionHandlers = {
  LOGOUT: (state, payload) => initialState,
  LOGIN_FULFILLED: (state, { data: { profile } }) => [
    ...state,
    ...profile.posts,
  ],
  GET_ADMIN_DATA_FULFILLED: (state, { data: { posts } }) => [...posts],
  UPDATE_PROFILE_FULFILLED: (state, { data: { posts } }) => {
    const postsById = {};
    for (let i = 0; i < state.length; i++) {
      postsById[state[i]._id] = state[i];
    }
    for (let j = 0; j < posts.length; j++) {
      postsById[posts[j]._id] = posts[j];
    }
    return Object.values(postsById).sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
  },
  GET_PROFILE_FULFILLED: (state, { posts }) => {
    const postsById = {};
    for (let i = 0; i < state.length; i++) {
      postsById[state[i]._id] = state[i];
    }
    for (let j = 0; j < posts.length; j++) {
      postsById[posts[j]._id] = posts[j];
    }
    return Object.values(postsById).sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
  },
  SEARCH_FULFILLED: (state, { results }) => {
    const postsById = {};
    for (let i = 0; i < state.length; i++) {
      postsById[state[i]._id] = state[i];
    }
    const resultsThatArePosts = results.filter((r) => Boolean(r.author));
    for (let j = 0; j < resultsThatArePosts.length; j++) {
      postsById[resultsThatArePosts[j]._id] = resultsThatArePosts[j];
    }
    return Object.values(postsById).sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
  },
  UPDATE_FEED_FULFILLED: (state, { feed }) => {
    const postsById = {};
    for (let i = 0; i < state.length; i++) {
      postsById[state[i]._id] = state[i];
    }
    const resultsThatArePosts = feed.filter((r) => Boolean(r.author));
    for (let j = 0; j < resultsThatArePosts.length; j++) {
      postsById[resultsThatArePosts[j]._id] = resultsThatArePosts[j];
    }
    return Object.values(postsById).sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
  },
  CREATE_POST_FULFILLED: (state, { data: post }) => [...state, post],
  GET_COMMENTS_FULFILLED: (state, { comments, postId }) => {
    const newState = [...state];
    for (var i = 0; i < newState.length; i++) {
      if (newState[i]._id === postId) {
        newState[i].comments = comments;
        break;
      }
    }
    return newState;
  },
  COMMENT_ON_POST_FULFILLED: (state, { postId, comments }) => {
    const newState = [...state];
    for (var i = 0; i < newState.length; i++) {
      if (newState[i]._id === postId) {
        newState[i].comments = comments;
        break;
      }
    }
    return newState;
  },
  LIKE_POST_FULFILLED: (state, { data }) => {
    const newState = [...state];
    for (var i = 0; i < newState.length; i++) {
      if (newState[i]._id === data.postId) {
        newState[i].likes = data.postLikedBy;
        break;
      }
    }
    return newState;
  },
  UNLIKE_POST_FULFILLED: (state, { data }) => {
    const newState = [...state];
    for (var i = 0; i < newState.length; i++) {
      if (newState[i]._id === data.postId) {
        newState[i].likes = data.postLikedBy;
        break;
      }
    }
    return newState;
  },
  DELETE_POST_FULFILLED: (state, { postId }) => {
    const newState = state.filter((p) => p._id !== postId);
    return newState;
  },
  DELETE_COMMENT_FULFILLED: (state, { postId, comments }) => {
    const newState = [...state];
    for (var i = 0; i < newState.length; i++) {
      if (newState[i]._id === postId) {
        const newPost = { ...newState[i], comments };
        newState[i] = newPost;
        break;
      }
    }
    return newState;
  },
  GET_POSTS_FULFILLED: (state, posts) => posts,
};

export const reducer = (state = initialState, { type, payload }) =>
  actionHandlers[type] ? actionHandlers[type](state, payload) : state;

export default reducer;
