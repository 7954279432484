import React from "react";
import { connect } from "react-redux";
import List from "Shared/List";
import UserCard from "UserCard";
import { withRouter } from "react-router-dom";

export const Following = ({ following, match: { params } }) => (
  <List
    components={following.map((user) => (
      <UserCard user={user} />
    ))}
  />
);

export default withRouter(
  connect(({ profile: { following }, users }, { match: { params } }) => ({
    following: params.username ? users[params.username].following : following,
  }))(Following)
);
