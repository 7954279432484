export const initialState = [];

// TODO: on FOLLOW and UNFOLLOW, add or remove posts from the feed.

const actionHandlers = {
  LOGOUT: (state, payload) => initialState,
  UPDATE_FEED_FULFILLED: (state, { feed }) => {
    const feedWithDuplicates = [...state, ...feed];
    const feedById = {};
    for (var i = 0; i < feedWithDuplicates.length; i++) {
      let id = feedWithDuplicates[i]._id;
      feedById[id] = feedWithDuplicates[i];
    }
    // TODO: sort by timestamp?
    return Object.values(feedById).sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
  },
  GET_COMMENTS_FULFILLED: (state, { comments, postId }) => {
    const newState = [...state];
    for (var i = 0; i < newState.length; i++) {
      if (newState[i]._id === postId) {
        newState[i].comments = comments;
        break;
      }
    }
    return newState;
  },
  DELETE_POST_FULFILLED: (state, { postId }) => {
    const newState = state.filter(p => p._id !== postId);
    return newState;
  }
};

export const reducer = (state = initialState, { type, payload }) =>
  actionHandlers[type] ? actionHandlers[type](state, payload) : state;

export default reducer;
