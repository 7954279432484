import React, { useState } from "react";
import styles from "./styles.module.scss";
import BillingAddress from "./BillingAddress";
// import ExpressCheckout from "./ExpressCheckout";
import PaymentDetails from "./PaymentDetails";
import Basket from "./Basket";
import YourDetails from "./YourDetails";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Elements } from "@stripe/react-stripe-js";
import DeliveryAddress from "./DeliveryAddress";
import {
  useAuthToken,
  useBasket,
  useNavigate,
  usePaymentRequest,
  useProfile,
  useVoucherCode,
} from "hooks";
import { Form } from "react-final-form";
import validate from "./validate";
import { placeOrder } from "./actions";
import { useSelector, useDispatch } from "react-redux";

export const Component = ({
  paymentRequest = {},
  elements = {},
  stripe = {},
  setEmail,
  email,
  values = {},
  handleSubmit,
  voucherError,
  voucherData,
  setVoucherCode,
  loading,
}) => {
  const applyVoucher = () => setVoucherCode(values.voucherCode);

  return (
    <div className={styles.Container}>
      <section className={styles.Page}>
        <div className={styles.Left}>
          {/* <ExpressCheckout paymentRequest={paymentRequest} /> */}
          <YourDetails
            setEmail={setEmail}
            email={email}
            values={values}
            loading={loading}
          />
          <DeliveryAddress loading={loading} />
          <BillingAddress
            billingAddressSame={values.billingAddressSame}
            loading={loading}
          />
          <PaymentDetails
            paymentRequest={paymentRequest}
            elements={elements}
            stripe={stripe}
            handleSubmit={handleSubmit}
            loading={loading}
          />
        </div>
        <aside>
          <Basket
            applyVoucher={applyVoucher}
            voucherError={voucherError}
            voucherData={voucherData}
            country={values["delivery-address"].country}
          />
        </aside>
      </section>
    </div>
  );
};

const PaymentPage = (props) => {
  const stripeProps = usePaymentRequest();
  const { email: emailFromProfile } = useProfile();
  const [email, setEmail] = useState(emailFromProfile);
  const { canTrackUser, uuid } = useSelector((state) => state.analytics);
  const token = useAuthToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { basket } = useBasket();
  const [loading, setLoading] = useState(false);

  const confirmPayment = (values) => {
    props.handleSubmit({ ...stripeProps, values });
  };

  const {
    voucherCode,
    setVoucherCode,
    voucherData,
    // setVoucherData,
    voucherError,
    // setVoucherError,
  } = useVoucherCode(email);

  return (
    <Form
      keepDirtyOnReinitialize={true}
      onSubmit={(values) =>
        placeOrder({
          values,
          dispatch,
          stripe: stripeProps.stripe,
          elements: stripeProps.elements,
          canTrackUser,
          uuid,
          navigate,
          basket,
          token,
          setLoading,
        })
      }
      validate={validate}
      initialValues={{
        billingAddressSame: true,
        email,
        "delivery-address": {
          country: "United Kingdom",
        },
      }}
    >
      {(formProps) => (
        <Component
          {...stripeProps}
          email={email}
          setEmail={setEmail}
          {...props}
          handleSubmit={confirmPayment}
          voucherCode={voucherCode}
          voucherData={voucherData}
          voucherError={voucherError}
          setVoucherCode={setVoucherCode}
          loading={loading}
          {...formProps}
        />
      )}
    </Form>
  );
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const WithStripe = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentPage {...props} />
    </Elements>
  );
};

export default WithStripe;
