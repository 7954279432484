import React from "react";
import { connect } from "react-redux";
import styles from "./styles.module.scss";
import Layout from "Grid";
import { useProductDashboardData, useOrderDashboard } from "hooks";

export const CampaignDashboard = ({
  liveCampaigns,
  endedCampaigns,
  numberLive,
}) => {
  const { numberSold, numberOfOrders } = useProductDashboardData();
  const data = useOrderDashboard();

  return (
    <div className={styles.Container}>
      <section className={styles.DesignerDashboard}>
        {/* <h2 className={styles.Box}>{numberSold} garments sold</h2>
    <h2 className={styles.Box}>{numberOfOrders} total orders</h2> */}
        <h2>{numberLive} live products</h2>
        <Layout productSet={liveCampaigns} />
        <h2>{endedCampaigns.length} Ended</h2>
        <Layout productSet={endedCampaigns} />
      </section>
    </div>
  );
};

const mapStateToProps = ({ profile: { products = [] } }) => {
  let numberOfOrders = 0;
  let numberSold = 0;
  const liveCampaigns = [],
    endedCampaigns = [];

  for (var i = 0; i < products.length; i++) {
    numberOfOrders += products[i].orders.length;
    numberSold += products[i].numberSold;
    if (products[i].live && new Date(products[i].ends_at) > new Date()) {
      liveCampaigns.push(products[i]);
    }

    if (Boolean(new Date(products[i].ends_at) < new Date())) {
      endedCampaigns.push(products[i]);
    }
  }

  return {
    numberOfOrders,
    numberSold,
    numberLive: liveCampaigns.length,
    liveCampaigns,
    endedCampaigns,
  };
};

export default connect(mapStateToProps)(CampaignDashboard);
