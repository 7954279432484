import React from "react";
import styles from "./styles.module.scss";
import FeatherIcon from "feather-icons-react";
import { Select } from "FormStuff";
import Bookmark from "Bookmark";
import { useDispatch } from "react-redux";
import { sku, toCurrency } from "helpers";
import { Field, Form } from "react-final-form";
import QuantityPicker from "QuantityPicker";

const RemoveFromBag = ({ remove }) => (
  <button onClick={remove} style={{ padding: "0.5em" }}>
    <FeatherIcon icon={"trash"} size={18} />
    <div>remove</div>
  </button>
);

export const Component = ({
  product = {},
  readOnly = false,
  remove,
  changeQuantity,
  changeSize,
}) => {
  const imgsrc = product.photos ? product.photos[0] : product.design;
  const { quantity } = product;
  return (
    <section className={styles.BagItem}>
      <img src={imgsrc} />
      <div className={styles.Middle}>
        <h3>{product.title}</h3>
        <span>Size: {product.size}</span>
        <span>{toCurrency(product.price)}</span>
        <div className={styles.Inputs}>
          <label>
            <span
              className={styles.HideOnMobile}
              // style={{ marbo: "0.5em" }}
            >
              quantity:&nbsp;
            </span>
            <span>
              {readOnly ? (
                product.quantity
              ) : (
                <QuantityPicker
                  value={quantity}
                  onIncrement={(e) => {
                    e.preventDefault();
                    changeQuantity(quantity + 1);
                  }}
                  onDecrement={(e) => {
                    e.preventDefault();
                    changeQuantity(Math.max(quantity - 1, 1));
                  }}
                />
              )}
            </span>
          </label>
          {readOnly ? (
            <></>
          ) : (
            <label className={styles.HideOnMobile}>
              <span>size:&nbsp;</span>
              <span className={styles.SelectContainer}>
                <Field
                  name="size"
                  component={Select}
                  options={product.sizes.map((s) => ({ value: s, label: s }))}
                  onChange={changeSize}
                />
              </span>
            </label>
          )}
        </div>
      </div>

      {!readOnly && (
        <div className={styles.Buttons}>
          <Bookmark withText={true} product={product} />
          <RemoveFromBag remove={remove} />
        </div>
      )}
    </section>
  );
};

const BagItem = ({ product, readOnly }) => {
  const dispatch = useDispatch();
  const remove = () =>
    dispatch({ type: "REMOVE_FROM_BASKET", payload: sku(product) });

  const changeQuantity = (quantity) =>
    dispatch({
      type: "UPDATE_BASKET",
      payload: {
        oldProduct: product,
        newProduct: { ...product, quantity },
      },
    });

  const changeSize = (e) =>
    dispatch({
      type: "UPDATE_BASKET",
      payload: {
        oldProduct: product,
        newProduct: { ...product, size: e.target.value },
      },
    });

  return (
    <Form
      initialValues={{ quantity: product.quantity, size: product.size }}
      onSubmit={() => {}}
      render={(props) => (
        <Component
          product={product}
          readOnly={readOnly}
          remove={remove}
          changeQuantity={changeQuantity}
          changeSize={changeSize}
          {...props}
        />
      )}
    />
  );
};
export default BagItem;
