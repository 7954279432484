import React from "react";
import { useLocation, Link } from "react-router-dom";
import styles from "./styles.module.scss";

const labels = {
  "/": "Home",
};
export const Component = ({ crumbs = [] }) => {
  return (
    <ul className={styles.Breadcrumbs}>
      {crumbs.map((crumb, i) => (
        <>
          {i > 0 && <li>{">"}</li>}
          <li>
            <Link to={"/" + crumb}>{labels["/" + crumb] || crumb}</Link>
          </li>
        </>
      ))}
    </ul>
  );
};

const Breadcrumbs = () => {
  const { pathname } = useLocation();
  const split = pathname.split("/");
  const crumbs =
    split.length > 3 ? [split[0], split[1], split[split.length - 1]] : split;
  return <Component crumbs={crumbs} />;
};

export default Breadcrumbs;
