import ProfileHeader from "ProfileHeader";
import React from "react";
import styles from "./styles.module.scss";
import { useParams } from "react-router";
import { useAction } from "hooks";
import { getProfile } from "actions";
import ProfileComponent from "ProfileComponent";
import { useSelector } from "react-redux";

export const Component = ({ profile = {}, isOwnProfile }) => {
  return (
    <section className={styles.Profile}>
      <ProfileHeader profile={profile} isOwnProfile={isOwnProfile} />
      <ProfileComponent profile={profile} />
    </section>
  );
};

const useProfile = (username) => {
  const profile = useSelector((state) => state.users[username]);
  useAction(getProfile(username), username);
  return profile || {};
};

const Profile = () => {
  const { username } = useParams();
  const userProfile = useSelector((state) => state.profile);

  const profile = useProfile(username || userProfile.username);
  let isOwnProfile = profile.username === userProfile.username;
  return <Component profile={profile} isOwnProfile={isOwnProfile} />;
};

export default Profile;
