export const initialState = {
  showBanner: false,
  showOfferPopup: true,
  notifications: []
};

const actionHandlers = {
  LOGOUT: (state, payload) => initialState,
  NOTIFICATION: (state, payload) => ({
    ...state,
    showBanner: true,
    notifications: [...state.notifications, payload]
  }),
  HIDE_NOTIFICATION_BANNER: state => ({ ...state, showBanner: false }),
  SHOW_OFFER_POPUP: state => ({ ...state, showOfferPopup: true }),
  HIDE_OFFER_POPUP: state => ({ ...state, showOfferPopup: false })
};

export const reducer = (state = initialState, { type, payload }) =>
  actionHandlers[type] ? actionHandlers[type](state, payload) : state;

export default reducer;
