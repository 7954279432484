import React from "react";
import styles from "./styles.module.scss";
import { connect } from "react-redux";
import Fetch from "App/api/Fetch";
import {
  getProductById,
  setStarPick,
  publishProduct,
  unpublishProduct,
} from "actions";
import { Link } from "react-router-dom";
import CardStatus from "CardStatus";

const SetAsStarPick = ({ onSetStarPick, campaign }) => (
  <div className={styles.StarPickButtons}>
    {[0, 1, 2].map((i) =>
      campaign.isStarPick !== i ? (
        <button
          onClick={() => onSetStarPick({ product: campaign, position: i })}
          key={i}
        >
          Set as star pick #{i + 1}
        </button>
      ) : (
        <p key={i}>This is star pick #{campaign.isStarPick + 1}</p>
      )
    )}
  </div>
);

const PublishOptions = ({ onPublish, onUnpublish, campaign }) =>
  campaign.live ? (
    <>
      {" "}
      <button onClick={onUnpublish} className="secondary small">
        remove from sale
      </button>
      <Link to={`/products/edit/${campaign._id}`} className="secondary small">
        edit
      </Link>
    </>
  ) : (
    <Link to={`/products/publish/${campaign._id}`} className="secondary small">
      New drop
    </Link>
  );

export const CampaignCard = ({
  campaign,
  onFetch,
  onPublish,
  onUnpublish,
  userIsAdmin,
  onSetStarPick,
  adminOptions = [],
}) => (
  <Fetch
    actions={typeof campaign === "string" ? [() => onFetch(campaign)] : []}
  >
    <div className={styles.CampaignCard}>
      <Link to={`/products/${campaign._id}`}>
        <img
          src={campaign.design || campaign.photos[0]}
          alt={campaign.title}
          style={{ maxWidth: "10em" }}
        />
        <div>{campaign.title}</div>
      </Link>
      {adminOptions.indexOf("starPicks") > -1 ? (
        <SetAsStarPick onSetStarPick={onSetStarPick} campaign={campaign} />
      ) : null}
      {adminOptions.indexOf("publish") > -1 ? (
        <PublishOptions
          onPublish={() => onPublish(campaign._id)}
          onUnpublish={() => onUnpublish(campaign._id)}
          campaign={campaign}
        />
      ) : null}
      {userIsAdmin ? (
        campaign.live ? (
          <CardStatus color="green" text="live" />
        ) : (
          <CardStatus color="orange" text="draft" />
        )
      ) : null}
    </div>
  </Fetch>
);

const mapStateToProps = ({ campaigns, profile: { roles } }, { campaign }) => {
  return {
    campaign:
      typeof campaign === "string"
        ? Object.values(campaigns).filter((u) => u._id === campaign)[0]
        : campaign,
    userIsAdmin: roles && roles.indexOf("admin") >= 0,
  };
};

export default connect(mapStateToProps, {
  onFetch: getProductById,
  onSetStarPick: setStarPick,
  onPublish: publishProduct,
  onUnpublish: unpublishProduct,
})(CampaignCard);
