import React from "react";
import { useDispatch } from "react-redux";
import { post } from "App/api";
import { useAdmin, useAuthToken, useNavigate, useStarPicks } from "hooks";
import { Link } from "react-router-dom";
import styles from "../styles.module.scss";
import { useDeleteProduct } from "actions";

export const AdminOptions = ({ product = {} }) => {
  const isAdmin = useAdmin();
  const { setStarPick } = useStarPicks();
  const token = useAuthToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { deleteProduct, error, message } = useDeleteProduct(product._id);

  if (!isAdmin) {
    return <></>;
  }

  const setStarPick1 = () => {
    setStarPick({ product, position: 1 });
  };
  const setStarPick2 = () => {
    setStarPick({ product, position: 2 });
  };
  const setStarPick3 = () => {
    setStarPick({ product, position: 3 });
  };

  const removeFromSale = () => {
    dispatch({
      type: "UNPUBLISH_PRODUCT",
      payload: post({
        url: "/admin/unpublish-product",
        body: { _id: product._id },
        token,
      }),
    }).then((data) => {
      navigate("/admin");
      return data;
    });
  };

  return (
    <header className={styles.AdminOptions}>
      <h1 style={{ marginTop: "0" }}>
        Admin Panel -{" "}
        {product.live ? "This product is live" : "This product is not live"}
      </h1>
      {error && <div className={styles.ErrorBox}>{error}</div>}
      {message && <div className={styles.GreenBox}>{message}</div>}
      <button onClick={setStarPick1} className={styles.ButtonSecondary}>
        Set StarPick1
      </button>
      <button onClick={setStarPick2} className={styles.ButtonSecondary}>
        Set StarPick2
      </button>

      <button onClick={setStarPick3} className={styles.ButtonSecondary}>
        Set StarPick3
      </button>

      {product.live ? (
        <button onClick={removeFromSale} className={styles.ButtonSecondary}>
          Remove from sale
        </button>
      ) : (
        <Link
          to={`/products/publish/${product._id}`}
          className={styles.ButtonSecondary}
        >
          New drop
        </Link>
      )}
      <Link
        to={`/products/edit/${product._id}`}
        className={styles.ButtonSecondary}
      >
        edit
      </Link>
      <button
        // to={`/products/delete/${product._id}`}
        onClick={deleteProduct}
        className={styles.ButtonSecondary}
      >
        delete
      </button>
    </header>
  );
};

export default AdminOptions;
