const initialState = {
  term: "",
  results: [],
  context: ""
};

const actionHandlers = {
  LOGOUT: state => initialState,
  SEARCH: (state, { term, context }) => ({
    ...state,
    term,
    context
  }),
  SEARCH_FULFILLED: (state, { results }) => ({ ...state, results }),
  CLEAR_SEARCH: state => initialState
};

export const reducer = (state = initialState, { type, payload }) =>
  actionHandlers[type] ? actionHandlers[type](state, payload) : state;

export default reducer;
