import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import StarPickCard from "./StarPickCard";
import LazyLoad, { lazyload } from "react-lazyload";
import { Swipeable } from "react-swipeable";
import { useStarPicks } from "hooks";
import FeatherIcon from "feather-icons-react";
import Grid from "Grid";
import MailingList from "MailingList";
import DesignerSpotlight from "DesignerSpotlight";
import WornBy from "WornBy";
import { useLandingPage } from "useContentful";
import step1 from "./svg/step1.svg";
import step2 from "./svg/step2.svg";
import step3 from "./svg/step3.svg";

export const LandingPage = () => {
  const data = useLandingPage();
  return (
    <div className={styles.LandingPage}>
      <Hero {...data} />
      <p className={styles.Spiel}>
        We're passionate about breaking down barriers in the fashion industry, making community-driven fashion accessible to all. By collaborating with creatives, we make meaningful pieces for communities to love. Shop, create, and connect with Make Thread – where fashion meets community and creativity.
      </p>
      <LazyLoad offset={200}>
        <StarPicks />
      </LazyLoad>
      <LazyLoad offset={200}>
        <Info {...data} />
      </LazyLoad>
      <LazyLoad offset={200}>
        <DesignerSpotlight {...data} />
      </LazyLoad>
      <LazyLoad offset={200}>
        <WornBy {...data} />
      </LazyLoad>
      <LazyLoad offset={200}>
        <LearnMore {...data} />
      </LazyLoad>
      <LazyLoad offset={200}>
        <MailingList />
      </LazyLoad>
    </div>
  );
};

const Info = ({
  bannerParagraphLeft,
  bannerTitleLeft,
  bannerTitleMiddle,
  bannerTitleRight,
  bannerParagraphMiddle,
  bannerParagraphRight,
}) => {
  return (
    <div className={styles.InfoContainer}>
      <div className={styles.Info}>
        <section>
          <img src={step1} />
          <h2>{bannerTitleLeft}</h2>
          <main>{bannerParagraphLeft}</main>
        </section>
        <section>
          <img src={step2} />

          <h2>{bannerTitleMiddle}</h2>
          <main>{bannerParagraphMiddle}</main>
        </section>
        <section>
          <img src={step3} />
          <h2>{bannerTitleRight}</h2>
          <main>{bannerParagraphRight}</main>
        </section>
      </div>
    </div>
  );
};

const Hero = ({
  heroImage,
  title,
  subtitle,
  mainButtonText,
  mainButtonLink,
}) => {
  return (
    <section className={styles.HeroContainer}>
      <img className={styles.Hero} src={heroImage} />
      <div className={styles.HeroCopy}>
        <header>
          <h4>Just landed</h4>
          <h1>{title}</h1>
        </header>
        <p>{subtitle}</p>
        <Link className={styles.ButtonSecondary} to={mainButtonLink || "/shop"}>
          {mainButtonText}
        </Link>
      </div>
    </section>
  );
};

export const MobileStarPicks = ({ starPicks = [] }) => {
  const [active, setActive] = useState(starPicks[0]);
  useEffect(() => {
    if (starPicks.length > 0) {
      setActive(starPicks[0]);
    }
  }, [starPicks]);
  const previousStarPick = (sp) => {
    const index = starPicks.indexOf(sp);
    const newIndex = (index + starPicks.length - 1) % starPicks.length;
    setActive(starPicks[newIndex]);
  };
  const nextStarPick = (sp) => {
    const index = starPicks.indexOf(sp);
    const newIndex = (index + 1) % starPicks.length;
    setActive(starPicks[newIndex]);
  };
  if (starPicks.length === 0) {
    return <></>;
  }

  return (
    <div className={styles.CarouselContainer}>
      <div className={styles.ActiveStarPick}>
        <Swipeable
          onSwipeRight={() => nextStarPick(active)}
          onSwipeLeft={() => previousStarPick(active)}
        >
          <StarPickCard starPick={active} />
        </Swipeable>
      </div>
      <div className={styles.LittlePicksContainer}>
        <button onClick={() => previousStarPick(active)}>
          <span className="material-icons">arrow_left</span>
        </button>
        <div className={styles.LittlePicks}>
          {starPicks.map((item, index) => (
            <img
              src={item.design}
              alt={item.title}
              onClick={() => setActive(item)}
              key={item._id}
            />
          ))}
        </div>
        <button onClick={() => nextStarPick(active)}>
          <span className="material-icons">arrow_right</span>
        </button>
      </div>
    </div>
  );
};

const StarPicks = () => {
  const { starPicks } = useStarPicks();
  return (
    <Grid
      largeMobile={true}
      productSet={starPicks}
      title={"top selling favourites"}
      filters={false}
      breadcrumbs={false}
    />
  );
};

const LearnMoreBlock = ({ img, header, secondHeader, p, linkText, linkTo }) => {
  return (
    <div className={styles.Block}>
      <img src={img} alt={"test"} />
      <h2>{header}</h2>
      <p>{p}</p>
      <Link to={linkTo} className={styles.ButtonSecondary}>
        {linkText}
      </Link>
    </div>
  );
};

const LearnMore = ({
  learnMoreLeftPhoto,
  learnMoreMiddlePhoto,
  learnMoreRightPhoto,
  learnMoreLeftTitle,
  learnMoreMiddleTitle,
  learnMoreRightTitle,
  learnMoreMiddleBody,
  learnMoreLeftBody,
  learnMoreRightBody,
  learnMoreLeftLink,
  learnMoreMiddleLink,
  learnMoreRightLink,
}) => {
  return (
    <LazyLoad once offset={100} height={500}>
      <section className={styles.LearnMore}>
        <LearnMoreBlock
          img={learnMoreLeftPhoto}
          header={learnMoreLeftTitle}
          p={learnMoreLeftBody}
          linkText={"Join the community"}
          linkTo={learnMoreLeftLink}
        />
        <LearnMoreBlock
          img={learnMoreMiddlePhoto}
          header={learnMoreMiddleTitle}
          p={learnMoreMiddleBody}
          linkText={"Apply & start selling"}
          linkTo={learnMoreMiddleLink}
        />
        <LearnMoreBlock
          img={learnMoreRightPhoto}
          header={learnMoreRightTitle}
          p={learnMoreRightBody}
          linkText={"Find out more"}
          linkTo={learnMoreRightLink}
        />
      </section>
    </LazyLoad>
  );
};

export default LandingPage;
