import React, { useState } from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import Bookmark from "Bookmark";
import { useTimeLeft } from "hooks";
import { Select } from "FormStuff";
import { toCurrency } from "helpers";
import MoveToBag from "MoveToBag";
import FeatherIcon from "feather-icons-react";
import Lazy from "react-lazyload";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";

const RemoveFromSavedList = ({ removeFromSavedList }) => {
  return (
    <button onClick={removeFromSavedList} className={styles.ButtonWithIcon}>
      <FeatherIcon icon={"trash"} size={12} />
      <span>Remove</span>
    </button>
  );
};
const ProductCardHeader = ({ designer = {} }) => (
  <header>
    <Link to={`/profile/${designer.username}/products`}>
      <img src={designer.profilePicture} />
      <span className={styles.Username}>{designer.username}</span>
    </Link>
  </header>
);

const sizeOptions = [
  { value: "xs", label: "extra small" },
  { valeu: "s", label: "small" },
  { value: "m", label: "medium" },
  { value: "l", label: "large" },
  { value: "xl", label: "extra large" },
  { value: "xxl", label: "extra extra large" },
];

export const Component = ({
  product,
  productSku,
  daysLeft,
  updateSize,
  moveToBag = false,
  largeMobile = false,
  remove,
}) => {
  const { photos, title, price, total_days_in_campaign, _id, designer } =
    product;

  return (
    <section
      className={
        largeMobile ? styles.ProductCardLargeMobile : styles.ProductCard
      }
      data-cy="product-card"
    >
      <ProductCardHeader designer={designer} />
      <main>
        <img src={product.design || (product.photos ? photos[0] : "")} />
        <Link
          to={`/products/${_id}`}
          className={styles.ViewItemButton}
          data-cy="view-item"
        >
          View item
        </Link>
        <div className={styles.Bookmark}>
          <Bookmark product={product} />
        </div>
      </main>

      <footer>
        <div className={styles.Title}>{title}</div>
        <div className={styles.Slider}>
          {/* <div className={styles.Background} /> */}
          {/* Do i need a dropSrtartedAt? TODO make this variable not 30. */}
          <div
            className={styles.Foreground}
            style={{
              width: `${100 * (1 - daysLeft / 30)}%`,
            }}
          />
        </div>
        <div>
          <span className={styles.Price}>{toCurrency(price)}</span>
          <span className={styles.TimeRemaining}>
            {daysLeft} {daysLeft === 1 ? "day" : "days"} left
          </span>
        </div>
        {moveToBag && (
          <div className={styles.MoveToBagContainer}>
            <Field
              options={sizeOptions.filter((s) =>
                product.sizes.includes(s.value)
              )}
              name={"size"}
              selected={productSku.size}
              onChange={updateSize}
              component={Select}
            />
            <MoveToBag product={productSku} />
            <RemoveFromSavedList
              product={product}
              removeFromSavedList={remove}
            />
          </div>
        )}
      </footer>
    </section>
  );
};

const ProductCard = ({ product, moveToBag, largeMobile }) => {
  const { days } = useTimeLeft(product.ends_at);

  const [productSku, setProductSku] = useState({
    ...product,
    quantity: 1,
    size: "xs",
    style: "one_style",
  });

  const updateSize = (e) => {
    const size = e.target.value;
    setProductSku((sku) => ({ ...sku, size }));
  };
  const dispatch = useDispatch();
  const remove = () =>
    dispatch({ type: "REMOVE_FROM_SAVED_LIST", payload: product });

  return (
    <Lazy once offset={400}>
      <Form onSubmit={() => {}}>
        {(props) => (
          <Component
            product={product}
            daysLeft={days}
            moveToBag={moveToBag}
            updateSize={updateSize}
            productSku={productSku}
            largeMobile={largeMobile}
            remove={remove}
          />
        )}
      </Form>
    </Lazy>
  );
};

export default ProductCard;
