export const initialState = { community_tab: "designers" };

const actionHandlers = {
  LOGOUT: (state, payload) => initialState,
  COMMUNITY_TAB_UPDATED: (state, payload) => ({
    ...state,
    community_tab: payload
  })
};

export const reducer = (state = initialState, { type, payload }) =>
  actionHandlers[type] ? actionHandlers[type](state, payload) : state;

export default reducer;
