import React from "react";
import { connect } from "react-redux";
import List from "Shared/List";
import PostCard from "PostCard";
import CampaignCard from "CampaignCard";

export const MyLikes = ({ likedPosts, likedCampaigns }) => {
  const list = likedPosts
    .map((p) => <PostCard post={p} />)
    .concat(likedCampaigns.map((c) => <CampaignCard campaign={c} />));
  return <List components={list} />;
};

const mapStateToProps = ({ profile: { likedCampaigns, likedPosts } }) => ({
  likedPosts,
  likedCampaigns,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MyLikes);
