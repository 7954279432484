import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./styles.module.scss";
import { uploadFiles } from "../App/api";
import { useAuthToken, useFiles } from "hooks";
import { Form, Field } from "react-final-form";
import { Input, TextArea } from "FormStuff";
import { useHistory } from "react-router";
import MultiPhotoSelect from "MultiPhotoSelect";

const createProduct = ({ history, dispatch, token, files = [], values }) => {
  if (files.length < 1) {
    return dispatch({
      type: "UPLOAD_REJECTED",
      payload: {
        error:
          "All posts need at least a picture, click the upload button to take a photo",
      },
    });
  }
  if (files.length > 5) {
    return dispatch({
      type: "UPLOAD_REJECTED",
      payload: {
        error: "You may only upload 5 images",
      },
    });
  }
  const HALF_A_MEGABYTE = 500000;
  for (let index = 0; index < files.length; index++) {
    const file = files[index];
    if (file.size > HALF_A_MEGABYTE) {
      return dispatch({
        type: "UPLOAD_REJECTED",
        payload: {
          error: "Max file size is 500KB",
        },
      });
    }
  }

  const body = new FormData();
  for (let index = 0; index < files.length; index++) {
    body.append("files", files[index]);
  }

  const { title, story } = values;
  if (!title || !story) {
    return dispatch({
      type: "UPLOAD_REJECTED",
      payload: {
        error: "Enter a title and description",
      },
    });
  }
  body.append("title", title);
  body.append("story", story);

  dispatch({
    type: "CREATE_PRODUCT",
    payload: uploadFiles({
      url: "/products/new",
      token,
      body,
    }).then((responseData) => {
      history.push("/draft-products");
      return responseData;
    }),
  });
};

export const NewProduct = ({
  handleSubmit,
  pristine,
  submitting,
  photos,
  setPhotos,
  refs,
}) => {
  return (
    <section className={styles.NewProduct}>
      <h1>Upload your design</h1>
      <section className={styles.WarningText}>
        <header>Important</header>
        <ul>
          <li>You can upload up to 5 photos of your design</li>
          <li>They will appear on the site in the order you upload them</li>
          <li>
            You will not be able to edit this after you submit your design
          </li>
          <li>
            Your design will be reviewed by the makethread team. A decision will
            get made within 3 days
          </li>
        </ul>
      </section>
      <h3>Select some photos for your design</h3>
      <MultiPhotoSelect photos={photos} setPhotos={setPhotos} refs={refs} />
      <fieldset>
        <h3>
          <label htmlFor="title">Title</label>
        </h3>
        <Field name="title" type="text" component={Input} />
        <h3>
          <label htmlFor="story">Story</label>
        </h3>
        <Field name="story" type="textarea" component={TextArea} />
        <p>
          The RRP will vary depending on your chosen method of printing. We set
          this when your design goes live.
        </p>
      </fieldset>
      <h1>I understand, submit my design for&nbsp;approval</h1>
      <button
        onClick={handleSubmit}
        className={styles.ButtonPrimary}
        style={{ width: "10em" }}
        disabled={pristine || submitting}
      >
        Ok
      </button>
    </section>
  );
};

const Container = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useAuthToken();
  const [photos, setPhotos] = useState(["", "", "", "", ""]);
  const refs = useRef([]);

  const onSubmit = (values) => {
    createProduct({
      history,
      dispatch,
      token,
      files: refs.current.filter((x) => x.files.length).map((r) => r.files[0]),
      values,
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      {(props) => (
        <NewProduct
          {...props}
          history={history}
          photos={photos}
          setPhotos={setPhotos}
          refs={refs}
        />
      )}
    </Form>
  );
};

export default Container;
