import Accordion from "Accordion";
import Bookmark from "Bookmark";
import Countdown from "Countdown";
import { toCurrency } from "helpers";
import { useAuthToken, useBasket, useProductById } from "hooks";
import MailingList from "MailingList";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import RelatedProducts from "RelatedProducts";
import SizePicker from "SizePicker";
import styles from "./styles.module.scss";
import ReactSimplyCarousel from "react-simply-carousel";
import Planet from "Planet";
import AdminOptions from "./AdminOptions";
import { COST_OF_SHIPPING_IN_PENCE } from "config";
import FeatherIcon from "feather-icons-react";
import { useDispatch } from "react-redux";
import { deletePostAction } from "actions";

const Price = ({ price }) => (
  <div>
    <div className={styles.BigPrice}>{toCurrency(price)}</div>
    <div className={styles.PriceCopy}>
      Plus £{(COST_OF_SHIPPING_IN_PENCE / 100).toFixed(2)} shipping to mainland
      UK
    </div>
  </div>
);

export const ViewProfile = ({
  profilePicture,
  username,
  deletePost = false,
  postId,
}) => {
  const token = useAuthToken();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <div className={styles.ViewProfile}>
      <Link to={`/profile/${username}/products`} className={styles.BlankLink}>
        <img src={profilePicture} />
        <span>{username}</span>
      </Link>
      {deletePost ? (
        <button
          onClick={() =>
            dispatch(
              deletePostAction(
                postId,
                history,
                token,
                `/profile/${username}/gallery`
              )
            )
          }
        >
          Delete Post
        </button>
      ) : (
        <Link
          to={`/profile/${username}/products`}
          className={styles.ButtonSecondary}
        >
          view profile
        </Link>
      )}
    </div>
  );
};

const chevronLeft = {
  alignSelf: "center",
  background: "#f2f2f2",
  border: "none",
  borderRadius: "50%",
  color: "black",
  cursor: "pointer",
  fontSize: "20px",
  lineHeight: 1,
  textAlign: "center",
  width: "2em",
  height: "2em",
  position: "absolute",
  left: "5%",
  top: "45%",
  zIndex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const chevronRight = {
  alignSelf: "center",
  background: "#f2f2f2",
  border: "none",
  borderRadius: "50%",
  color: "black",
  cursor: "pointer",
  fontSize: "20px",
  width: "2em",
  height: "2em",
  lineHeight: 1,
  textAlign: "center",
  position: "absolute",
  right: "5%",
  top: "45%",
  zIndex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const Carousel = ({ imgs }) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  if (!imgs || imgs.length <= 0) {
    return <></>;
  }
  return (
    <div className={styles.Carousel}>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        speed={400}
        easing="linear"
        // dotsNav={{show: true, containerProps: {background: 'red'}}}
        containerProps={{
          position: "relative",
          height: "50vh",
        }}
        forwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: chevronRight,
          children: (
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 0,
              }}
            >
              {/* <FeatherIcon icon={"chevron-right"} /> */}
              &gt;
            </span>
          ),
        }}
        backwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: chevronLeft,
          children: (
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 0,
              }}
            >
              &lt;
            </span>
          ),
        }}
      >
        {imgs.map((src, i) => (
          <img
            src={src}
            key={`${src}_${i}`}
            style={{ width: "100vw", height: "100vw" }}
            className={styles.CarouselImage}
          />
        ))}
      </ReactSimplyCarousel>
    </div>
  );
};
export const Component = ({
  product = { designer: {}, photos: [] },
  setselectedSize,
  addToBag,
  selectedPhoto,
  setSelectedPhoto,
}) => {
  const { username, thumbnail, profilePicture } = product.designer;

  const morePhotos = product.photos.slice(0, 5);
  const cycleLeft = () => {
    if (product.photos.length <= 1) return;
    setSelectedPhoto(
      product.photos[
        (product.photos.indexOf(selectedPhoto) - 1 + product.photos.length) %
          product.photos.length
      ]
    );
  };
  const cycleRight = () => {
    if (product.photos.length <= 1) return;
    setSelectedPhoto(
      product.photos[
        (product.photos.indexOf(selectedPhoto) + 1) % product.photos.length
      ]
    );
  };

  return (
    <section className={styles.ProductPage}>
      <AdminOptions product={product} />
      <main>
        <div className={styles.Left}>
          <div className={styles.ImageContainer}>
            <div className={styles.DesktopImageContainer}>
              <button onClick={cycleLeft} style={chevronLeft}>
                <FeatherIcon
                  icon={"chevron-left"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              </button>
              <img src={selectedPhoto} className={styles.DesktopImage} />
              <button onClick={cycleRight} style={chevronRight}>
                <FeatherIcon
                  icon={"chevron-right"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              </button>
            </div>
            <Carousel
              imgs={
                product.photos && product.photos.length > 0
                  ? product.photos
                  : [product.design]
              }
            />
            <Countdown product={product} />
          </div>
          <div className={styles.MoreImages}>
            {morePhotos.map((p) => (
              <img
                src={p}
                onClick={() => setSelectedPhoto(p)}
                className={selectedPhoto === p ? styles.MoreImagesActive : ""}
              />
            ))}
          </div>
        </div>
        <div className={styles.Right}>
          <ViewProfile
            profilePicture={thumbnail || profilePicture}
            username={username}
          />
          <div className={styles.Row}>
            <h1 className={styles.Title}>{product.title}</h1>
            <div>{product.body}</div>
            <Price price={product.price} />
          </div>
          <SizePicker
            onChange={setselectedSize}
            sizes={product.sizes}
            dropNumber={product.dropNumber}
            sizeGuide={product.size_guide}
          />

          <div className={styles.Buttons}>
            <button onClick={addToBag} className={styles.ButtonPrimary}>
              Add to bag
            </button>
            <Bookmark product={product} withText={true} />
          </div>
          <Planet />
          <Accordion product={product} />
        </div>
      </main>
      <footer>
        <RelatedProducts to={product} />
        <MailingList />
      </footer>
    </section>
  );
};

const ProductPage = () => {
  const { id } = useParams();
  const product = useProductById(id);
  const [selectedSize, setselectedSize] = useState(
    product && product.sizes ? product.sizes[0] : undefined
  );
  const [selectedPhoto, setSelectedPhoto] = useState("");
  useEffect(() => {
    setSelectedPhoto(product.design || product.photos[0]);
  }, [product]);
  const { addToBag } = useBasket();

  return (
    <Component
      product={product}
      setselectedSize={setselectedSize}
      selectedPhoto={selectedPhoto}
      setSelectedPhoto={setSelectedPhoto}
      addToBag={() =>
        addToBag({
          ...product,
          size: selectedSize,
          style: "one_style",
          quantity: 1,
        })
      }
    />
  );
};

export default ProductPage;
