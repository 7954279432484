import React, { lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import AuthRequired from "AuthRequired";
import ResetPasswordWithToken from "App/Auth/PasswordReset/Reset";
import Apply from "Apply";

const Login = lazy(() => import("Login"));

const SignUp = lazy(() => import("SignUp"));
const PasswordReset = lazy(() => import("App/Auth/PasswordReset"));
const PasswordResetConfirm = lazy(() =>
  import("App/Auth/PasswordReset/Confirm")
);

const fallback = (Component) => () =>
  (
    <Suspense fallback={<div>Loading... </div>}>
      <Component />
    </Suspense>
  );

export const AuthRoutes = () => (
  <>
    <Route exact path={"/sign-up"} component={fallback(SignUp)} />
    <Route exact path={"/login"} component={fallback(Login)} />
    <Route exact path={"/password-reset"} component={fallback(PasswordReset)} />
    <Route
      exact
      path={"/password-reset/:email/:resetToken"}
      component={ResetPasswordWithToken}
    />
    <Route
      exact
      path={"/password-reset-confirm"}
      component={fallback(PasswordResetConfirm)}
    />
    <Route
      exact
      path={"/apply"}
      render={() => <AuthRequired component={Apply} />}
    />
  </>
);

export default AuthRoutes;
