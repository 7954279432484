import React, { useMemo } from "react";
import styles from "./styles.module.scss";
import FeatherIcon from "feather-icons-react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SearchBar from "SearchBar";
import { useLoggedInStatus, useNavigate, useProfile, useToggle } from "hooks";
import Menu from "Menu";

const MenuIcon = () => {
  const [open, toggle] = useToggle(false);
  return open ? (
    <Menu open={open} toggle={toggle} />
  ) : (
    <div className={styles.MenuIcon} onClick={toggle}>
      <FeatherIcon icon="menu" size={18} />
      Menu
    </div>
  );
};

const SavedListIcon = () => (
  <Link to="/saved-list" className={styles.SavedListIcon}>
    <FeatherIcon size={16} icon="bookmark" className={styles.Bookmark} />
  </Link>
);

const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };
  return logout;
};
const ProfileIcon = () => {
  const isLoggedIn = useLoggedInStatus();
  const { username, roles } = useProfile();

  const designer =
    roles && (roles.includes("designer") || roles.includes("admin"));
  const isAdmin = roles && roles.includes("admin");
  const [open, toggle] = useToggle(false);
  const logout = useLogout();

  if (!isLoggedIn) {
    return (
      <Link to={"/login"}>
        <FeatherIcon size={16} icon="user" />
      </Link>
    );
  }
  if (open) {
    return (
      <div className={styles.ProfileMenu} data-cy="profile-icon">
        <button onClick={toggle}>
          <FeatherIcon size={16} icon="user" />
        </button>
        <nav>
          <ul>
            <li onClick={toggle}>
              <Link to="/my-profile">my profile</Link>
            </li>
            <li onClick={toggle}>
              <Link to="/settings">settings</Link>
            </li>
            <li onClick={toggle}>
              <Link to="/feed">news feed</Link>
            </li>
            <li onClick={toggle}>
              <Link to="/camera">new post</Link>
            </li>
            {!isAdmin && !designer && (
              <li onClick={toggle}>
                <Link to="/apply">become a designer</Link>
              </li>
            )}
            {designer && (
              <>
                <li onClick={toggle}>
                  <Link to="/new-product">add a product</Link>
                </li>
                {/* <li>
                  <Link to="/product-dashboard">product dashboard</Link>
                </li> */}
                <li onClick={toggle}>
                  <Link to="/draft-products">my drafts</Link>
                </li>
              </>
            )}
            {isAdmin && (
              <>
                <li onClick={toggle}>
                  <Link to="/admin">admin dashboard</Link>
                </li>
              </>
            )}
            <li>
              <button onClick={logout}>log out</button>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
  return (
    <button onClick={toggle} data-cy="profile-icon">
      <FeatherIcon size={16} icon="user" />
    </button>
  );
};

const Bag = () => {
  const bag = useSelector(({ basket }) => basket);
  const quantityInBag = useMemo(
    () =>
      Object.values(bag).reduce((total, item) => (total += item.quantity), 0),
    [bag]
  );
  return (
    <Link to={"/basket"} className={styles.Bag}>
      <FeatherIcon icon="shopping-bag" size={16} />
      {quantityInBag > 0 && (
        <span className={styles.QuantityInBag}>{quantityInBag}</span>
      )}
    </Link>
  );
};

export const Component = ({ searchIsOpen, toggleSearch }) => {
  return (
    <header className={styles.Header}>
      <div className={styles.Left}>
        {(window.innerWidth > 600 || searchIsOpen) && (
          <SearchBar open={searchIsOpen} toggle={toggleSearch} />
        )}
        <MenuIcon />
      </div>
      <div className={styles.Title}>
        <Link to="/" className={styles.HomeLink}>
          <h1>Make Thread</h1>
        </Link>
        <h2>Social commerce platform</h2>
      </div>
      <section className={styles.Icons}>
        <div>
          <FeatherIcon
            size={16}
            icon="search"
            className={styles.SearchIcon}
            onClick={toggleSearch}
            data-cy="search-icon"
          />
        </div>
        <SavedListIcon />
        <ProfileIcon />
        <Bag />
      </section>
    </header>
  );
};

const Header = () => {
  const [searchIsOpen, toggleSearch] = useToggle(false);

  return <Component searchIsOpen={searchIsOpen} toggleSearch={toggleSearch} />;
};

export default Header;
