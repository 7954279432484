import React, { Fragment } from "react";
import Grid from "Grid";

export const Component = ({ products = [] }) => {
  if (products.length <= 0) {
    return Fragment;
  }
  return (
    <Grid
      productSet={products}
      filters={false}
      breadcrumbs={false}
      title={"Related Items"}
    />
  );
};

const RelatedProducts = ({ to = {} }) => {
  const productsRelatedTo = (product) => {
    return [];
  };
  const products = productsRelatedTo(to);

  return <Component products={products} />;
};

export default RelatedProducts;
