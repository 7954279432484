import { useAuthToken, useProfile, useToggle } from "hooks";
import React from "react";
import { useDispatch } from "react-redux";
import styles from "./styles.module.scss";
import FeatherIcon from "feather-icons-react";
import { deleteComment } from "actions";
import { useParams } from "react-router-dom";

export const Component = ({
  author = {},
  body,
  daysSince,
  onDelete,
  isOpen = false,
  toggle,
  commentIsMine,
}) => {
  return (
    <>
      <div className={styles.Comment}>
        <img src={author.thumbnail || author.profilePicture} />
        <div>
          <div className={styles.Username}>@{author.username}</div>
          <p className={styles.Body}>{body}</p>
        </div>
        <div className={styles.SmallDate}>{daysSince} days ago</div>
        {commentIsMine ? (
          <button className={styles.More} onClick={toggle}>
            <FeatherIcon icon={"more-horizontal"} size={18} color="#333" />
          </button>
        ) : (
          <></>
        )}
      </div>
      {isOpen ? (
        <div className={styles.OpenDrawer}>
          <button className={styles.ButtonWarning} onClick={onDelete}>
            Delete comment
          </button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const Comment = ({ comment, postId, setAllComments }) => {
  const daysSince =
    new Date(
      new Date().getTime() - new Date(comment.created_at).getTime()
    ).getUTCDate() - 1;
  const dispatch = useDispatch();
  const [isOpen, toggle] = useToggle(false);
  const token = useAuthToken();

  const callback = (data) => {
    setAllComments(data.comments);
    return data;
  };
  const onDelete = () => {
    deleteComment({
      dispatch,
      token,
      postId,
      commentId: comment._id,
      callback,
    })();
    toggle();
  };
  const { username } = useProfile();
  const commentIsMine = username === comment.author.username;
  return (
    <Component
      {...comment}
      daysSince={daysSince}
      onDelete={onDelete}
      isOpen={isOpen}
      toggle={toggle}
      commentIsMine={commentIsMine}
    />
  );
};

export default Comment;
