export const initialState = [];

const setLikes = (state, { data: { campaignLikedBy = [], campaignId } }) => {
  const newState = [...state];
  for (var i = 0; i < newState.length; i++) {
    if (newState[i]._id === campaignId) {
      newState[i].likes = campaignLikedBy;
    }
  }
  return newState;
};

const updateProductArray = (state, results) => {
  const campaignsById = {};
  for (let i = 0; i < state.length; i++) {
    campaignsById[state[i]._id] = state[i];
  }
  const resultsThatAreCampaigns = results.filter((r) => Boolean(r.design));
  for (let j = 0; j < resultsThatAreCampaigns.length; j++) {
    campaignsById[resultsThatAreCampaigns[j]._id] = resultsThatAreCampaigns[j];
  }
  return Object.values(campaignsById);
};

const actionHandlers = {
  LOGOUT: (state, payload) => initialState,
  LOGIN_FULFILLED: (state, { data: { profile } }) => [
    ...state,
    ...profile.products,
  ],
  GET_LIVE_PRODUCTS_FULFILLED: (state, products) => [...products],
  GET_ADMIN_DATA_FULFILLED: (state, { data: { products } }) => [...products],
  CREATE_PRODUCT_FULFILLED: (state, { data }) => [...state, data],
  LIKE_PRODUCT_FULFILLED: setLikes,
  UNLIKE_PRODUCT_FULFILLED: setLikes,
  SEARCH_FULFILLED: (state, { results }) => updateProductArray(state, results),
  UPDATE_FEED_FULFILLED: (state, { feed }) => updateProductArray(state, feed),
  SET_STAR_PICKS_FULFILLED: (state, { data }) =>
    updateProductArray(state, data),
  PUBLISH_PRODUCT_FULFILLED: (state, { product }) =>
    updateProductArray(state, [product]),
  UNPUBLISH_PRODUCT_FULFILLED: (state, { product }) =>
    updateProductArray(state, [product]),
  GET_PRODUCT_FULFILLED: (state, { data }) => {
    const newState = state.filter((c) => c._id !== data._id);
    newState.push(data);
    return newState;
  },
  UPDATE_PRODUCT_FULFILLED: (state, campaign) => {
    const newState = state.filter((c) => c._id !== campaign._id);
    newState.push(campaign);
    return newState;
  },
  GET_STAR_PICKS_FULFILLED: (state, { data }) => {
    const newState = state.filter((c) => c.isStarPick < 0);
    return newState.concat(data);
  },
};

export const reducer = (state = initialState, { type, payload }) =>
  actionHandlers[type] ? actionHandlers[type](state, payload) : state;

export default reducer;
