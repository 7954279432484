import React, { Suspense } from "react";
import { Route, useLocation } from "react-router";
import ProductGrid, { GenericGrid } from "Grid";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import BreadcrumbBanner from "BreadcrumbBanner";
import PostCard from "PostCard";
import { useLoggedInStatus } from "hooks";
import { useDispatch } from "react-redux";

const ProfileShop = ({ products = [] }) => {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <ProductGrid productSet={products} breadcrumbs={false} filters={false} />
    </Suspense>
  );
};

const ProfileGallery = ({ posts = [] }) => {
  return (
    <GenericGrid small={true} breadcrumbs={false}>
      {posts.map((p) => (
        <PostCard post={p} header={false} key={p._id} withinSmallGrid={true} />
      ))}
    </GenericGrid>
  );
};

export const Component = ({
  profile = { products: [], posts: [] },
  selected = "gallery",
  logout,
  isLoggedIn,
}) => {
  return (
    <section className={styles.ProfileComponent}>
      <BreadcrumbBanner />
      <header className={styles.TabHeader}>
        <Link
          to={`/profile/${profile.username}/products`}
          className={selected === "products" ? styles.Active : ""}
        >
          Items
        </Link>
        <Link
          to={`/profile/${profile.username}/gallery`}
          className={selected === "gallery" ? styles.Active : ""}
        >
          Gallery
        </Link>
      </header>
      <Route
        path="/profile/:username/gallery"
        component={() => <ProfileGallery posts={profile.posts} />}
      />
      <Route
        path="/profile/:username/products"
        component={() => <ProfileShop products={profile.products} />}
      />
    </section>
  );
};

const ProfileComponent = ({ profile }) => {
  const { pathname } = useLocation();
  const isLoggedIn = useLoggedInStatus();
  const dispatch = useDispatch();
  const logout = () => dispatch({ type: "LOGOUT" });

  const selected = pathname.includes("gallery") ? "gallery" : "products";
  return (
    <Component
      profile={profile}
      selected={selected}
      isLoggedIn={isLoggedIn}
      logout={logout}
    />
  );
};

export default ProfileComponent;
