import React from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";

export const AuthRequired = ({ component: Component, isLoggedIn, ...props }) =>
  isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />;

const mapStateToProps = ({ auth: { token } }) => ({
  isLoggedIn: Boolean(token)
});

export default connect(mapStateToProps)(withRouter(AuthRequired));
