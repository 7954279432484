import React from "react";
import styles from "./styles.scss";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";

export const PhotoGrid = ({ images, alt = "fashion product", columns = 6 }) => (
  <div className={styles.PhotoGrid}>
    {images.map(({ image, link, label, soldOut }, i) => (
      <LazyLoad once offset={1200} height={500} key={i}>
        <Link to={link}>
          <img src={image} alt={alt} />
          {label ? <label>{label}</label> : <></>}
        </Link>
      </LazyLoad>
    ))}
  </div>
);

export default PhotoGrid;
