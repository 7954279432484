import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

export const Component = ({
  profilePhoto = "",
  title = "",
  body = "",
  photo = "",
  link = "/",
  buttonText = "",
}) => {
  return (
    <div className={styles.DesignerSpotlightContainer}>
      <section className={styles.DesignerSpotlight}>
        <header>
          <h1 className={styles.LandingPageTitle}>Designer Spotlight</h1>
          <h1>Make thread talks to the design community</h1>
        </header>
        <main>
          <div className={styles.Left}>
            <img src={profilePhoto} />
            <h1>{title}</h1>
            <p>{body}</p>
            <Link to={link} className={styles.ButtonSecondary}>
              {buttonText}
            </Link>
          </div>
          <div className={styles.Right}>
            <img src={photo} />
          </div>
        </main>
      </section>
    </div>
  );
};

export const defaultProps = {
  profilePicture:
    "https://s3-eu-west-1.amazonaws.com/makethread-development-local/1588706535031_thumbnail_D753069A-F94F-4118-87EE-08B6DC600359.jpeg",
  title: "Katie McNeals: Designing for sustainability",
  body: "Katie is an illustrator and animator from Liverpool, UK. In vitae ultricies ligula. Pellentesque eu felis rhoncus, lobortis turpis lobortis, sollicitudin enim. ",
  link: "/profile/makethread/products",
  buttonText: "View profile",
  photo:
    "https://images.ctfassets.net/k05clop0362m/5hSYiaL7pFd5jPty9gnkb7/e1d24711a6686f01222d1685e99f6dfa/Live_well_edited_35.jpg",
};

const DesignerSpotlight = (props = {}) => {
  return (
    <Component
      profilePhoto={props.spotlightProfilePicture}
      photo={props.spotlightPhoto}
      title={props.spotlightTitle}
      body={props.spotlightBody}
      link={props.spotlightProfileLink}
      buttonText="View profile"
    />
  );
};

export default DesignerSpotlight;
