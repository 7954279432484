import React, { useRef } from "react";
import styles from "./styles.module.scss";
import FeatherIcon from "feather-icons-react";
import { useAuthToken, useLoggedInStatus, useProfile } from "hooks";
import { commentOnPost } from "actions";
import { useDispatch } from "react-redux";
import { Field, Form } from "react-final-form";
import { Link } from "react-router-dom";

export const Input = (props) => {
  return <Field {...props} component="input" />;
};

export const Component = ({
  handleSubmit,
  sendOnEnter,
  isLoggedIn,
  form,
  ...rest
}) => {
  return (
    <div className={styles.CommentInput}>
      {isLoggedIn ? (
        <>
          <Input
            name={"newComment"}
            onKeyPress={(e) => {
              sendOnEnter(e, form.change);
            }}
          />
          <button
            onClick={(e) => {
              handleSubmit(e, form.change);
            }}
            className={styles.Icon}
          >
            <FeatherIcon icon={"send"} size={20} />
          </button>
        </>
      ) : (
        <Link
          to="/login"
          className={styles.ButtonSecondary}
          style={{ width: "100%", margin: "0 auto", textAlign: "center" }}
        >
          Sign in to comment
        </Link>
      )}
    </div>
  );
};

const CommentInput = ({ postId, setComments }) => {
  const dispatch = useDispatch();
  const token = useAuthToken();
  const isLoggedIn = useLoggedInStatus();

  const send = ({ newComment }, change) => {
    const callback = (post) => {
      setComments(post.comments[post.comments.length - 1]);
      return post;
    };
    if (!newComment) {
      return;
    }
    commentOnPost({ dispatch, token, postId, newComment, callback });
    change("newComment", "");
  };

  const sendOnEnter = (e, change) => {
    if (e.key !== "Enter") {
      return;
    }
    send({ newComment: e.target.value }, change);
  };

  return (
    <Form onSubmit={send}>
      {(props) => (
        <Component
          sendOnEnter={sendOnEnter}
          name="newComment"
          {...props}
          isLoggedIn={isLoggedIn}
        />
      )}
    </Form>
  );
};

export default CommentInput;
