import React from "react";
import styles from "./styles.module.scss";
import useContentful from "../useContentful";
import { getContentfulImage } from "helpers";

export const Component = ({
  title,
  mainParagraph,
  mainImage,
  missionImage,
  garmentsImage,
  packagingImage,
  bottomImage,
  missionTitle,
  missionParagraph,
  leftCardTitle,
  leftCardParagraph,
  rightCardTitle,
  rightCardParagraph,
  garmentsParagraph2,
  garmentsParagraph1,
  packagingParagraph,
  bottomParagraph1,
  bottomParagraph2,
  bottomParagraph3,
  bottomParagraph4,
  garmentTitle,
}) => {
  return (
    <div className={styles.Sustainability}>
      <section className={styles.Main}>
        <div>
          <h1>{title}</h1>
          <p>{mainParagraph}</p>
        </div>
        <img src={getContentfulImage(mainImage)} />
      </section>

      <section className={styles.Mission}>
        <img src={getContentfulImage(missionImage)} />
        <div>
          <h2>{missionTitle}</h2>
          <p>{missionParagraph}</p>
        </div>
      </section>

      <section className={styles.Cards}>
        <section className={styles.LeftCard}>
          <h2>{leftCardTitle}</h2>
          <p>{leftCardParagraph}</p>
        </section>
        <section className={styles.RightCard}>
          <h2>{rightCardTitle}</h2>
          <p>{rightCardParagraph}</p>
        </section>
      </section>

      <section className={styles.Garments}>
        <div>
          <h2>{garmentTitle}</h2>
          <p>{garmentsParagraph1}</p>
          <p>{garmentsParagraph2}</p>
        </div>
        <img src={getContentfulImage(garmentsImage)} />
      </section>

      <section className={styles.Packaging}>
        <img src={getContentfulImage(packagingImage)} />
        <div>
          <h2>Our packaging</h2>
          <p>{packagingParagraph}</p>
        </div>
      </section>

      <section className={styles.End}>
        <img src={getContentfulImage(bottomImage)} />
        <div>
          <p>{bottomParagraph1}</p>
          <p>{bottomParagraph2}</p>
          <p>{bottomParagraph3}</p>
          <p>{bottomParagraph4}</p>
        </div>
      </section>
    </div>
  );
};

export const Sustainability = () => {
  const data = useContentful("sustainabilityPage");
  return <Component {...data} />;
};

export default Sustainability;
