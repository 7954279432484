export const initialState = {
  voucherIsValid: false,
  voucherData: {}
};

const actionHandlers = {
  LOGOUT: (state, payload) => initialState,
  APPLY_VOUCHER_FULFILLED: (state, data) => data,
  APPLY_VOUCHER_REJECTED: (state, data) => initialState,
  PURCHASE_COMPLETED: (state, data) => initialState
};

export const reducer = (state = initialState, { type, payload }) =>
  actionHandlers[type] ? actionHandlers[type](state, payload) : state;

export default reducer;
