const initialState = "";

export const reducer = (state = initialState, { type, payload }) => {
  if (new RegExp(/.*REJECTED/g).test(type)) {
    window.scrollTo(0, 0);
    if (!payload) {
      return "Something went wrong";
    }
    if (typeof payload === "string") {
      return payload;
    }
    console.error(payload.error || payload.msg || "Something went wrong");
    if (payload.error || payload.msg) {
      return payload.error || payload.msg;
    }
  } else if (new RegExp(/.*FULFILLED/g).test(type) || type === "CLEAR_ERROR") {
    return initialState;
  }
  return state;
};

export default reducer;
