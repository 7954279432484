import React from "react";
import { useSelector } from "react-redux";
import Grid from "Grid";
import styles from "../EmptyBasket/styles.module.scss";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

export const Empty = () => {
  return (
    <section className={styles.EmptyBasket}>
      <main>
        <FeatherIcon icon={"heart"} size={36} />
        <h1>Your saved list is empty!</h1>
        <p>
          Continue shopping and add some items to your bag or save items in your
          wish list for later
        </p>

        <Link to="/basket" className={styles.ButtonPrimary}>
          Go to bag
        </Link>
        <Link to="/shop" className={styles.ContinueShopping}>
          <FeatherIcon icon={"arrow-left"} size={16} />
          Continue shopping
        </Link>
      </main>
    </section>
  );
};

export const Component = ({ savedProducts = [] }) => {
  if (savedProducts.length === 0) {
    return <Empty />;
  }
  return (
    <Grid
      productSet={savedProducts}
      title={"Saved list"}
      moveToBag={true}
      largeMobile={true}
    />
  );
};

const useSavedList = () => {
  const items = useSelector((state) => Object.values(state.savedList));
  return items;
};

const SavedList = () => {
  const savedProducts = useSavedList();
  return <Component savedProducts={savedProducts} />;
};

export default SavedList;
