import React, { lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import AuthRequired from "AuthRequired";
import AuthRoutes from "./AuthRoutes";

// Public
import LandingPage from "LandingPage";
import AboutUs from "AboutUs";
import Terms from "Terms";
import Returns from "Returns";
import Profile from "Profile";
import Gallery from "Gallery";
import PostPage from "PostPage";
import Sustainability from "Sustainability";

// user routes
import MyLikes from "MyLikes";
import MyFollowers from "MyFollowers";
import Following from "Following";
import Camera from "Camera";
import Settings from "Settings";
import UserDashboard from "UserDashboard";
import Feed from "Feed";

// designer routes
import DraftCampaigns from "DraftCampaigns";
import CampaignDashboard from "CampaignDashboard";
import CampaignArchive from "CampaignArchive";
import NewProduct from "NewCampaign";

// Admin routes
import AdminLogin from "AdminLogin";
import AdminApplications from "AdminApplications";
import Publish from "Publish";

//shop stuff
import Shop from "Shop";
import ProductPage from "ProductPage";
import SavedList from "SavedList";
import Payment from "Payment";
import Receipt from "Receipt";
// import PurchaseHistory from "PurchaseHistory";
import BasketPage from "BasketPage";
import AdminEnded from "AdminEnded";

export const Routes = () => (
  <>
    <Route exact path={"/"} component={LandingPage} />
    <Route
      exact
      path={"/feed"}
      render={() => <AuthRequired component={Feed} />}
    />

    <Route path={"/profile/:username"} component={Profile} />
    <Route exact path={"/my-profile"} component={Profile} />

    {/*  */}

    <Route exact path={"/checkout"} component={Payment} />
    <Route exact path={"/receipt"} component={Receipt} />
    {/* <Route
      exact
      path={"/purchase-history"}
      render={() => <AuthRequired component={PurchaseHistory} />}
    /> */}
    <Route exact path="/basket" component={BasketPage} />

    {/* Static or contentful */}
    <Route exact path={"/returns"} component={Returns} />
    <Route exact path={"/sustainability"} component={Sustainability} />
    <Route exact path={"/terms-and-conditions"} component={Terms} />

    {/*  */}
    <AuthRoutes />
    <AdminRoutes />
    <ProfileRoutes />
    <ProductRoutes />
    <PostRoutes />
  </>
);

// TODO: add password reset, email confirmation, etc.

// <Route
//   exact
//   path={"/admin/settings"}
//   render={() => <AuthRequired component={AdminSettings} />}
//   />
const ProductRoutes = () => (
  <>
    {/* <Route exact path={"/follow/designers"} component={Follow} /> */}
    <Route path={"/shop"} component={Shop} />
    <Route exact path={"/products/:id"} component={ProductPage} />
    <Route exact path={"/saved-list"} component={SavedList} />
  </>
);

const fallback = (Component) => () =>
  (
    <Suspense fallback={<div>Loading... </div>}>
      <Component />
    </Suspense>
  );
const Admin = fallback(lazy(() => import("Admin")));

export const AdminRoutes = () => (
  <>
    <Route exact path={"/admin/login"} component={AdminLogin} />
    <Route path={"/admin"} render={() => <AuthRequired component={Admin} />} />
    <Route
      path={"/products/publish/:id"}
      render={() => (
        <AuthRequired
          component={(props) => <Publish {...props} isReissue={true} />}
        />
      )}
    />
    <Route
      path={"/products/edit/:id"}
      render={() => (
        <AuthRequired
          component={(props) => <Publish {...props} isReissue={false} />}
        />
      )}
    />
    <Route
      exact
      path={"/admin/applications/:username"}
      render={() => <AuthRequired component={AdminApplications} />}
    />
  </>
);

const ProfileRoutes = () => (
  <>
    <Route
      exact
      path={"/my-likes"}
      render={() => <AuthRequired component={MyLikes} />}
    />
    <Route
      exact
      path={"/my-followers"}
      render={() => <AuthRequired component={MyFollowers} />}
    />
    <Route
      exact
      path={"/following"}
      render={() => <AuthRequired component={Following} />}
    />
    <Route exact path={"/followers/:username"} render={() => <MyFollowers />} />
    <Route exact path={"/following/:username"} render={() => <Following />} />
    <Route exact path={"/about-us"} component={AboutUs} />
    <Route
      exact
      path={"/new-product"}
      render={() => <AuthRequired component={NewProduct} />}
    />
    <Route
      exact
      path={"/draft-products"}
      render={() => <AuthRequired component={DraftCampaigns} />}
    />
    <Route
      exact
      path={"/product-dashboard"}
      render={() => <AuthRequired component={CampaignDashboard} />}
    />

    <Route
      path={"/settings"}
      render={() => <AuthRequired component={Settings} />}
    />
    <Route
      exact
      path={"/camera"}
      render={() => <AuthRequired component={Camera} />}
    />
    <Route
      exact
      path={"/dashboard"}
      render={() => <AuthRequired component={UserDashboard} />}
    />
    <Route exact path={"/archive"} component={CampaignArchive} />
  </>
);

const PostRoutes = () => (
  <>
    <Route
      exact
      path={"/post/:id"}
      // render={() => <AuthRequired component={PostPage} />}
      component={PostPage}
    />
    <Route
      exact
      path={"/follow/gallery"}
      // render={() => <AuthRequired component={Gallery} />}
      component={Gallery}
    />
  </>
);

export default Routes;
