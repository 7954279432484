import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as loading } from "../Loading/reducer";
import { reducer as error } from "../Errors/reducer";
import { reducer as auth } from "App/Auth/reducer";
import { reducer as stripe } from "App/Stripe/reducer";
import { reducer as files } from "App/Files/reducer";
import { reducer as socket } from "App/Socket/reducer";
import { reducer as basket } from "App/_Checkout/Basket/reducer";

import { reducer as notifications } from "NotificationBanner/reducer";
import { reducer as search } from "Search/reducer";
import { reducer as posts } from "Posts/reducer";
import { reducer as products } from "Campaigns/reducer";
import { reducer as admin } from "Admin/reducer";
import { reducer as profile } from "Profile/reducer";
import { reducer as users } from "Users/reducer";
import { reducer as receipt } from "Receipt/reducer";
import { reducer as menu } from "Menu/reducer";
import { reducer as feed } from "Feed/reducer";
import { reducer as orders } from "Orders/reducer";
import { reducer as staticFiles } from "StaticFiles/reducer";
import { reducer as appUpdate } from "AppUpdateBanner/reducer";
import { reducer as flags } from "Flags";
import { reducer as analytics } from "App/Analytics/reducer";
import { reducer as vouchers } from "Vouchers/reducer";
import { reducer as applyVoucher } from "Vouchers/apply";
import { reducer as collections } from "Collections/reducer";
import { reducer as savedList } from "SavedList/reducer";

const reducer = combineReducers({
  form: formReducer,
  auth,
  stripe,
  socket,
  files,
  search,
  admin,
  error,
  loading,
  notifications,
  //
  basket,
  orders,
  posts,
  products,
  profile,
  users,
  receipt,
  feed,
  menu,
  staticFiles,
  flags,
  appUpdate,
  analytics,
  vouchers,
  applyVoucher,
  collections,
  savedList,
});

export default reducer;
