import React, { useState } from "react";
import { connect } from "react-redux";
import styles from "./styles.module.scss";

export const CookieBanner = ({ consentToTracking, showBanner }) => {
  const optIn = () => consentToTracking(true);

  return showBanner ? (
    <div className={styles.CookieBanner}>
      <div>
        <p>
          This website uses cookies, which enable us to enhance your experience.
        </p>
        <p>
          By clicking <b>Accept all</b> you agree to the storing of cookies on
          your device to enhance site navigation, analyze usage, and assist
          marketing efforts. If you need to know more see our{" "}
          <a
            href={
              "https://makethread.s3.eu-west-1.amazonaws.com/privacy_policy.pdf"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy&nbsp;policy.
          </a>
        </p>
      </div>
      <button onClick={optIn}>Accept&nbsp;all</button>
    </div>
  ) : null;
};

const mapStateToProps = ({ analytics }) => ({
  showBanner: analytics.showCookieBanner,
});

const mapDispatchToProps = {
  consentToTracking: (canTrackUser) => (dispatch) =>
    dispatch({
      type: "USER_CONSENTED_TO_TRACKING",
      payload: canTrackUser,
    }),
};

export default connect(mapStateToProps, mapDispatchToProps)(CookieBanner);
