import React from "react";
import { connect } from "react-redux";
import styles from "./styles.module.scss";

const Errors = ({ error, white }) =>
  error ? (
    <div className={styles.Container}>
      <div className={styles.Error}>
        <i className="fa fa-exclamation-triangle" />
        {typeof error == "string"
          ? error
          : JSON.stringify(error.message || error)}
      </div>
    </div>
  ) : null;

export default connect(({ error }) => ({ error }))(Errors);
