import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./styles.module.scss";
import FeatherIcon from "feather-icons-react";

export const Component = ({
  isOnSavedList,
  add,
  remove,
  withText = false,
  size = 20,
}) => {
  return (
    <button
      className={
        withText
          ? isOnSavedList
            ? styles.BookmarkIconWithTextSaved
            : styles.BookmarkIconWithTextEmpty
          : isOnSavedList
          ? styles.BookmarkIconSaved
          : styles.BookmarkIconEmpty
      }
      onClick={isOnSavedList ? remove : add}
    >
      <FeatherIcon icon={"bookmark"} size={size} />
      {withText && <div>{isOnSavedList ? "Saved" : "Save it"}</div>}
    </button>
  );
};

const Bookmark = ({ product, withText }) => {
  const productId = product._id;
  const isOnSavedList = useSelector((state) =>
    Boolean(state.savedList[productId])
  );
  // const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const add = () => dispatch({ type: "ADD_TO_SAVED_LIST", payload: product });
  const remove = () =>
    dispatch({ type: "REMOVE_FROM_SAVED_LIST", payload: product });

  return (
    <Component
      isOnSavedList={isOnSavedList}
      add={add}
      remove={remove}
      withText={withText}
    />
  );
};

export default Bookmark;
