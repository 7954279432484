import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import { post } from "App/api";

export const placeOrder = async ({
  elements,
  dispatch,
  stripe,
  navigate,
  basket,
  voucherCode,
  canTrackUser,
  uuid,
  token,
  values,
  setLoading,
}) => {
  if (!stripe || !elements) {
    return dispatch({
      type: "PAYMENT_REJECTED",
      payload: {
        error: "Stripe has not loaded yet. please wait or contact us.",
      },
    });
  }
  const cardElement = elements.getElement("card");

  if (!cardElement) {
    return dispatch({
      type: "PAYMENT_REJECTED",
      payload: { error: "You need to add a card before you can pay" },
    });
  }
  const { error, paymentMethod } = await stripe.createPaymentMethod({
    type: "card",
    card: cardElement,
  });

  if (error) {
    console.error("[error]", error);
    return dispatch({ type: "STRIPE_REJECTED", payload: error.message });
  }

  setLoading(true);

  dispatch({
    type: "GET_PAYMENT_SECRET",
    payload: post({
      url: "/order-products",
      body: {
        paymentMethod,
        basket,
        deliveryDetails: values,
        voucherCode,
      },
      token,
    }),
  })
    .then(async (data) => {
      const clientSecret = data.value.secret;
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: { card: cardElement },
      });
      if (result.error) {
        throw result.error;
      } else {
        return data;
      }
    })
    .then((data) =>
      pushAndTrack(data, navigate, dispatch, canTrackUser, uuid, setLoading)
    )
    .catch((e) => {
      const message = e && e.message ? e.message : e;
      dispatch({
        type: "PAYMENT_REJECTED",
        payload: message,
      });
      window.scrollTo(0, 0);
    });
};

export const pushAndTrack = (
  data,
  navigate,
  dispatch,
  canTrackUser,
  uuid,
  setLoading
) => {
  const receipt = data.value.receipt;

  setTimeout(() => {
    setLoading(false);
    navigate("/receipt", {
      email: receipt.deliveryDetails.email,
      orderId: receipt.orderId,
    });
  }, 1);

  dispatch({ type: "PURCHASE_COMPLETED" });

  if (canTrackUser) {
    ReactGA.plugin.execute("ecommerce", "addTransaction", {
      id: uuid,
      revenue: receipt.price / 100,
    });
    ReactGA.plugin.execute("ecommerce", "send");
    ReactPixel.track("Purchase", {
      content_type: "product",
      value: receipt.price / 100,
      contents: receipt.basket,
      currency: "GBP",
    });
  }
  return data;
};
