import React from "react";
import AppContainer from "./Provider";
import Router from "./Router";
import BrokenDisplay from "BrokenDisplay";
import "./Analytics";

const AppComponent = () => (
  <AppContainer>
    <Router />
  </AppContainer>
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(e) {
    console.error(e);
    this.setState({ error: e });
  }

  render() {
    return this.state.error ? <BrokenDisplay /> : <AppComponent />;
  }
}

export default App;
