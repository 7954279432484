export const initialState = {
  campaigns: [],
  followers: [],
  following: [],
  posts: [],
  products: [],
  roles: [],
};

const setLikedCampaigns = (state, { data: { likedProducts = [] } }) => ({
  ...state,
  likedProducts,
});
const setLikedPosts = (state, { data: { likedPosts = [] } }) => ({
  ...state,
  likedPosts,
});

const actionHandlers = {
  LOGOUT: (state, payload) => initialState,
  GET_MY_PROFILE_FULFILLED: (state, profile) => ({ ...state, ...profile }),
  LOGIN_FULFILLED: (state, { data: { profile } }) => ({ ...profile }),
  REGISTER_FULFILLED: (state, { data: { profile } }) => ({ ...profile }),
  UPDATE_PROFILE_FULFILLED: (state, { data }) => ({ ...data }),
  FOLLOW_FULFILLED: (state, { data }) => ({
    ...state,
    following: data,
  }),
  UNFOLLOW_FULFILLED: (state, { data }) => ({
    ...state,
    following: data,
  }),
  LIKE_PRODUCT_FULFILLED: setLikedCampaigns,
  UNLIKE_PRODUCT_FULFILLED: setLikedCampaigns,
  LIKE_POST_FULFILLED: setLikedPosts,
  UNLIKE_POST_FULFILLED: setLikedPosts,
  CREATE_POST_FULFILLED: (state, { data: post }) => ({
    ...state,
    posts: [...state.posts, post],
  }),
  CREATE_PRODUCT_FULFILLED: (state, { data }) => ({
    ...state,
    products: [...state.products, data],
  }),
  APPLY_TO_BECOME_DESIGNER_FULFILLED: (state, data) => ({
    ...state,
    ...data,
  }),
  DELETE_POST_FULFILLED: (state, { postId }) => {
    const newState = {
      ...state,
      posts: state.posts.filter((p) => p._id !== postId),
    };
    return newState;
  },
};

export const reducer = (state = initialState, { type, payload }) =>
  actionHandlers[type] ? actionHandlers[type](state, payload) : state;

export default reducer;
