import React from "react";
import CheckoutSection from "Payment/CheckoutSection";
import BagItem from "BagItem";
import List from "Shared/List";
import { sku } from "helpers";
import { useBasket } from "hooks";

export const Component = ({ products = [], readOnly = false }) => {
  const components = products.map((p, i) => (
    <BagItem product={p} readOnly={readOnly} key={sku(p)} />
  ));
  return (
    <CheckoutSection Header={() => <div>My bag</div>} expandable={false}>
      <List components={components} />
    </CheckoutSection>
  );
};

const Bag = () => {
  const { basket } = useBasket();
  // TODO MEMO
  return <Component products={basket} />;
};

export default Bag;
