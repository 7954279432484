import React from "react";
import styles from "./styles.module.scss";

export const renderField = ({
  input,
  type,
  meta: { touched, error, warning },
  theme,
  ...rest
}) => (
  <div className={styles.InputAndErrorWrapper}>
    {type === "textarea" ? (
      <textarea {...input} type={type} {...rest} />
    ) : (
      <input {...input} type={type} {...rest} />
    )}
    {touched &&
      ((error && (
        <span className={styles.FormError}>
          <i className="fa fa-exclamation-triangle" />
          {error}
        </span>
      )) ||
        (warning && (
          <span className={styles.FormWarning}>
            <i className="fa fa-exclamation" />
            {warning}
          </span>
        )))}
  </div>
);

export default renderField;
