export const initialState = {};

const actionHandlers = {
  LOGOUT: (state, payload) => initialState,
  GET_PAYMENT_SECRET_FULFILLED: (state, { receipt }) => ({ ...receipt })
};

export const reducer = (state = initialState, { type, payload }) =>
  actionHandlers[type] ? actionHandlers[type](state, payload) : state;

export default reducer;
