import { get } from "App/api";
import { GenericGrid } from "Grid";
import { useAuthToken } from "hooks";
import PostCard from "PostCard";
import React, { useState, useEffect } from "react";

export const Component = ({ posts = [] }) => {
  return (
    <GenericGrid
      title={"Feed"}
      subtitle={"live posts from people you follow"}
      breadcrumbs={false}
    >
      {posts.map((p) => (
        <PostCard post={p} />
      ))}
    </GenericGrid>
  );
};

const useMostRecentPosts = () => {
  const [posts, setPosts] = useState([]);
  const token = useAuthToken();
  useEffect(() => {
    get({ url: "/feed", token }).then(setPosts);
  }, []);

  return posts;
};

const Feed = () => {
  const posts = useMostRecentPosts();
  return <Component posts={posts} />;
};

export default Feed;
