export const initialState = {
  basket: { items: [] },
  deliveryDetails: { "delivery-address": {} },
};

const actionHandlers = {
  LOGOUT: (state, payload) => initialState,
  GET_ORDER_FULFILLED: (state, order) => order,
};

export const reducer = (state = initialState, { type, payload }) =>
  actionHandlers[type] ? actionHandlers[type](state, payload) : state;

export default reducer;
