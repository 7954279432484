import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { clearStripe } from "../../Stripe/actions";
import { clearError, clearFile, hideMenu } from "actions";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";

class OnRouteChange extends React.Component {
  constructor(props) {
    super(props);
    // this.props.clearStripe();
  }

  componentDidUpdate(previousProps) {
    if (this.props.location.pathname !== previousProps.location.pathname) {
      this.props.error && this.props.clearError();
      this.props.file && this.props.clearFile();
      this.props.menuIsOpen && this.props.hideMenu();
      window.scrollTo(0, 0);
      ReactGA.pageview(window.location.pathname + window.location.search);

      if (this.props.canTrackUser) {
        ReactPixel.pageView(window.location.pathname + window.location.search);
      }
    }
  }

  render() {
    return this.props.children;
  }
}

const mapStateToProps = ({ auth: token, file, error, menu, analytics }) => ({
  isLoggedIn: Boolean(token),
  file,
  error,
  menuIsOpen: menu,
  canTrackUser: analytics.canTrackUser,
});

export default withRouter(
  connect(mapStateToProps, { clearStripe, clearError, clearFile, hideMenu })(
    OnRouteChange
  )
);
