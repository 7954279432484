import { follow, unfollow, useFollowActions } from "actions";
import Follow from "Follow";
import { useAuthToken, useLoggedInStatus } from "hooks";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import styles from "./styles.module.scss";

const StatsBlock = ({ numberOfFollowers, numberFollowing, numberOfLikes }) => (
  <footer className={styles.StatsBlock}>
    <div>
      <div className={styles.Stat}>{numberOfFollowers}</div>
      <div>Followers</div>
    </div>
    <div>
      <div className={styles.Stat}>{numberFollowing}</div>
      <div>Following</div>
    </div>
    <div>
      <div className={styles.Stat}>{numberOfLikes}</div>
      <div>Likes</div>
    </div>
  </footer>
);

const ProfileButtons = ({ isOwnProfile, id }) => {
  const isFollowing = useSelector((state) =>
    state.profile.following.includes(id)
  );
  const isLoggedIn = useLoggedInStatus();
  const { follow, unfollow } = useFollowActions(id);

  if (!isLoggedIn) {
    return (
      <span style={{ marginTop: "1em" }}>
        <Link className={styles.ButtonSecondary} to={"/sign-up"}>
          Follow
        </Link>
      </span>
    );
  }

  if (isOwnProfile) {
    return (
      <span style={{ marginTop: "1em" }}>
        <Link className={styles.ButtonSecondary} to="/settings/edit">
          Edit profile
        </Link>
      </span>
    );
  }

  if (isFollowing) {
    return (
      <span style={{ marginTop: "1em" }}>
        <button className={styles.ButtonSecondary} onClick={unfollow}>
          Unfollow
        </button>
      </span>
    );
  }

  return (
    <span style={{ marginTop: "1em" }}>
      <button className={styles.ButtonSecondary} onClick={follow}>
        Follow
      </button>
    </span>
  );
};

export const Component = ({
  profilePicture,
  fullName,
  username,
  bio,
  numberFollowing,
  numberOfFollowers,
  numberOfLikes,
  isOwnProfile,
  id,
}) => {
  return (
    <section className={styles.ProfileHeader}>
      <main>
        <aside>
          <img src={profilePicture} className={styles.ProfilePicture} />
        </aside>
        <div className={styles.Content}>
          <h1>{fullName}</h1>
          <div className={styles.Username}>@{username}</div>
          <div className={styles.Bio}>{bio}</div>
          <ProfileButtons id={id} isOwnProfile={isOwnProfile} />
        </div>
      </main>
      <StatsBlock
        numberFollowing={numberFollowing}
        numberOfLikes={numberOfLikes}
        numberOfFollowers={numberOfFollowers}
      />
    </section>
  );
};

const ProfileHeader = ({ profile, isOwnProfile }) => {
  return <Component {...profile} isOwnProfile={isOwnProfile} />;
};

export default ProfileHeader;
