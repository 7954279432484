import React from "react";
import { connect } from "react-redux";
import List from "Shared/List";
import UserCard from "UserCard";
import { withRouter } from "react-router-dom";

export const MyFollowers = ({ followers, match: { params } }) => (
  <List
    components={followers.map((u) => (
      <UserCard user={u} />
    ))}
  />
);

export default withRouter(
  connect(({ profile: { followers }, users }, { match: { params } }) => ({
    followers: params.username ? users[params.username].followers : followers,
  }))(MyFollowers)
);
