import React from "react";
import { useBannerText } from "useContentful";
import styles from "./styles.module.scss";

export const Component = () => {
  const text = useBannerText();
  return (
    <aside className={styles.Banner}>
      <p>{text}</p>
    </aside>
  );
};

const Banner = () => {
  return <Component />;
};

export default Banner;
