import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styles from "./styles.module.scss";
import { Field, reduxForm } from "redux-form";
import { uploadFiles } from "App/api";
import { changeImage } from "actions";
import renderField from "App/Forms";
import Loading from "App/Loading";
import { useFiles } from "hooks";

const uploadPhoto = (history) => (dispatch, getState) => {
  const file = document.querySelector('input[type="file"]').files[0];
  if (!file) {
    return dispatch({
      type: "UPLOAD_REJECTED",
      payload: {
        error:
          "All posts need at least a picture, click the upload button to take a photo",
      },
    });
  }

  const values = getState().form.photo.values;
  if (!values || !values.body) {
    return dispatch({
      type: "UPLOAD_REJECTED",
      payload: {
        error: "Your post needs a caption before you can upload it.",
      },
    });
  }

  const body = new FormData();
  body.append("file", file);

  const tags = Array.from(document.querySelectorAll("#tags label")).map(
    (l) => l.textContent
  );
  body.append("tags", JSON.stringify(tags));
  body.append("body", values.body);

  dispatch({
    type: "CREATE_POST",
    payload: uploadFiles({
      url: "/posts/new",
      token: getState().auth.token,
      body,
    }).then((responseData) => {
      history.push("/my-profile");
      return responseData;
    }),
  });
};

const ImageComponent = ({ onImageSelect }) => (
  <label htmlFor="profile-photo" className={styles.ChooseImage}>
    <h2>Choose file</h2>
    <input
      name="post"
      id="post"
      type="file"
      accept=".jpg, .png, .jpeg"
      onChange={onImageSelect}
    />
  </label>
);

export const PhotoUploadContainer = ({
  files = [],
  clearFiles,
  isPublish = false,
  multiple = true,
}) => (
  <section className={styles.PhotoUploadContainer}>
    <main
      className={styles.ImageContainer}
      tabIndex="0"
      onClick={() => document.querySelector('input[type="file"]').click()}
      onKeyPress={(e) =>
        e.key === "Enter" &&
        document.querySelector('input[type="file"]').click()
      }
    >
      {files.length > 0 ? (
        files.map((f) => <img key={f} src={f} alt={"you are uploading this"} />)
      ) : (
        <></>
      )}
    </main>
    <button
      className={styles.ButtonSecondary}
      onClick={() => document.querySelector('input[type="file"]').click()}
    >
      {files.length > 0 ? "Select different photo" : "Choose photo"}
      <i className="fa fa-camera fa-5x" />
    </button>
    {isPublish && (
      <p>
        Note: if you upload photos here, they will REPLACE the ones the designer
        has uploaded
      </p>
    )}
    {files.length > 0 && (
      <button
        onClick={clearFiles}
        className={styles.ButtonSecondary}
        style={{ marginLeft: "1em", marginTop: "1em" }}
      >
        Reset
      </button>
    )}
  </section>
);

export const Camera = ({ onSubmit, imageToShow, history }) => {
  const { files, clearFiles, onImageSelect } = useFiles();

  return (
    <Loading message="Posting..." regex={new RegExp(/CREATE_POST_PENDING/)}>
      <div className={styles.Camera}>
        <div className="accent--pink">
          <p>
            Followers and designers share your latest looks to your fashion
            following community&nbsp;here!
          </p>
          <p>
            They will show in your profile gallery and within the
            fashion&nbsp;community.
          </p>
        </div>
        <PhotoUploadContainer
          files={files}
          imageToShow={imageToShow}
          clearFiles={clearFiles}
        />
        <fieldset>
          <Field
            name="profile_photo"
            component={ImageComponent}
            onImageSelect={onImageSelect}
          />
          <h3>
            <label htmlFor="body">
              Caption
              <Field name="body" type="text" component={renderField} />
            </label>
          </h3>
        </fieldset>

        <button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            onSubmit(history);
          }}
          className="primary"
        >
          Upload
        </button>
      </div>
    </Loading>
  );
};

const mapStateToProps = ({ files }) => ({
  imageToShow: files,
});

const Form = reduxForm({ form: "photo" })(Camera);
const FormWithRouter = withRouter(Form);

export default connect(mapStateToProps, {
  onImageSelect: changeImage,
  onSubmit: uploadPhoto,
})(FormWithRouter);
