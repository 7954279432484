import React from "react";
import styles from "./styles.module.scss";
import useContentful from "../useContentful";
import MailingList from "MailingList";
import { getContentfulImage } from "helpers";
import step1 from "./1.svg";
import step2 from "./2.svg";
import step3 from "./3.svg";
import { Link } from "react-router-dom";

export const Component = ({
  title,
  headerPhoto,
  headerParagraph,
  headerParagraphLabel,
  howItWorksSubtitle,
  howItWorksPhoto,
  weTakeCareOfTheRest = [],
  whoWeAreSubtitle,
  whoWeAreParagraph,
  whoWeArePhoto,
  ourMissionPhoto,
  ourMissionSubtitle,
  ourMissionParagraph,
  differencePicture,
  differenceParagraph,
  differenceSubtitle,
  communityPhoto,
  communityLabel,
  communityParagraph,
  futureParagraph,
}) => {
  return (
    <div className={styles.AboutUs}>
      <section className={styles.HeroContainer}>
        <img className={styles.Hero} src={getContentfulImage(headerPhoto)} />
        <div className={styles.HeroCopy}>
          <header>
            <p className={styles.AboutSubTitle}>About</p>
            <h1 className={styles.AboutMainTitle}>{title}</h1>
            <p>{headerParagraph}</p>
            <p className={styles.Bold}>{headerParagraphLabel}</p>
          </header>
        </div>
      </section>
      <section className={styles.HowItWorks}>
        <h1 className={styles.AboutTitle}>How it works</h1>
        <h2 className={styles.AboutSubTitle}>{howItWorksSubtitle}</h2>
        <div className={styles.Steps}>
          <div className={styles.Icons}>
            <div>
              <img src={step1} />
              <p>Create a profile</p>
            </div>
            <div>
              <img src={step2} />
              <p>Upload your designs</p>
            </div>
            <div>
              <img src={step3} />
              <p>Share your story</p>
            </div>
          </div>
          <Link to="/sign-up" className={styles.ButtonPrimary}>
            Join the community
          </Link>
        </div>
        <h2 className={styles.TakeCareHeader}>We take care of the rest</h2>
        <ul className={styles.TakeCareList}>
          {weTakeCareOfTheRest.map((w) => (
            <li>{w}</li>
          ))}
        </ul>
      </section>
      <section>
        <div className={styles.TwoColumnGridImageRight}>
          <h1 className={styles.AboutSubTitle}>Who we are</h1>
          <h2 className={styles.AboutTitle}>{whoWeAreSubtitle}</h2>
          <img
            className={styles.SquareImg}
            src={getContentfulImage(whoWeArePhoto)}
          />
          <p className={styles.HideWeTakeCare}>We take care of the rest</p>
          <p className={styles.center}>{whoWeAreParagraph}</p>
        </div>
      </section>
      <section className={styles.OurMission}>
        <div className={styles.TwoColumnGridImageLeft}>
          <h1 className={styles.AboutSubTitle}>Our Mission</h1>
          <h2 className={styles.AboutTitle}>{ourMissionSubtitle}</h2>
          <img
            className={styles.SquareImg}
            src={getContentfulImage(ourMissionPhoto)}
          />
          <p>{ourMissionParagraph}</p>
        </div>
      </section>
      <section>
        <div className={styles.TwoColumnGridImageRight}>
          <h1 className={styles.AboutTitle}>What makes us different?</h1>
          <h2 className={styles.AboutSubTitle}>{differenceSubtitle}</h2>
          <img
            className={styles.SquareImg}
            src={getContentfulImage(differencePicture)}
          />
          <p>{differenceParagraph}</p>
        </div>
      </section>
      <section className={styles.Community}>
        <div className={styles.TwoColumnGridImageLeft}>
          <h2 className={styles.AboutSubTitle}>{communityLabel}</h2>
          <h1 className={styles.AboutTitle}>Designed by the community</h1>
          <img
            className={styles.SquareImg}
            src={getContentfulImage(communityPhoto)}
          />
          <p>{communityParagraph}</p>
        </div>
      </section>
      <section className={styles.Future}>
        <div>
          <h1 className={styles.AboutTitle}>The future of makethread</h1>
          <p>{futureParagraph}</p>
          <h4 className={styles.GetInContact}>Get in contact</h4>
          <a href="mailto:info@makethread.com">info@makethread.com</a>
        </div>
      </section>
      <section>
        <MailingList />
      </section>
    </div>
  );
};

export const AboutUs = () => {
  const data = useContentful("aboutUs");
  return <Component {...data} />;
};

export default AboutUs;
