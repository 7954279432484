import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styles from "./styles.module.scss";
import { approveApplication, rejectApplication } from "actions";
import { withRouter } from "react-router-dom";
import { useAuthToken } from "hooks";
import { get } from "App/api";

// TODO getUserApplication method
export const AdminApplications = ({
  username,
  onApprove,
  onReject,
  history,
}) => {
  const [user, setUser] = useState(null);
  const token = useAuthToken();

  useEffect(() => {
    get({
      url: `/admin/users/${username}`,
      token,
    }).then(setUser);
  }, [username]);

  if (!user) {
    return <div>Loading</div>;
  }

  return (
    <div className={styles.AdminApplications}>
      <img
        src={user.thumbnail || user.profilePicture}
        alt={`profile thumbnail`}
      />
      <dl>
        <dt>username</dt>
        <dd>{user.username}</dd>
        <dt>email</dt>
        <dd>{user.email}</dd>
        <dt>bio</dt>
        <dd>{user.bio}</dd>
        <dt>Why they want to join</dt>
        <dd>{user.reason_for_application}</dd>
        <dt>their work</dt>
        <dd>
          {user.exampleWork && user.exampleWork.map((w) => <p key={w}>{w}</p>)}
        </dd>
        {user.socialMediaHandles && (
          <>
            <dt>social</dt>
            <dd>
              <ul>
                <li>
                  <strong>twitter: </strong>
                  {user.socialMediaHandles.twitter}
                </li>
                <li>
                  <strong>facebook: </strong>
                  {user.socialMediaHandles.facebook}
                </li>
                <li>
                  <strong>instagram: </strong>
                  {user.socialMediaHandles.instagram}
                </li>
              </ul>
            </dd>
          </>
        )}
      </dl>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {user.application_status === "approved" ? (
          <h1>This designer's profile has been approved</h1>
        ) : (
          <>
            <button
              onClick={() => onApprove(user._id, history, "influencer")}
              className="primary"
              style={{ marginBottom: "1em" }}
            >
              Approve as influencer
            </button>
            <button
              onClick={() => onApprove(user._id, history, "independent")}
              className="primary"
              style={{ marginBottom: "1em" }}
            >
              Approve as independent
            </button>
            <button
              onClick={() => onApprove(user._id, history, "designer")}
              className="primary"
              style={{ marginBottom: "1em" }}
            >
              Approve as designer
            </button>
          </>
        )}
        <button
          onClick={() => onReject(user._id, history)}
          className="secondary"
        >
          Reject
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (
  {},
  {
    match: {
      params: { username },
    },
  }
) => ({
  username,
});

const mapDispatchToProps = {
  onApprove: approveApplication,
  onReject: rejectApplication,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminApplications));
