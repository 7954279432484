import { Input } from "FormStuff";
import React from "react";
import { Field } from "react-final-form";
import styles from "./styles.module.scss";

export const Component = ({ onClick, error = "", success = "" }) => {
  return (
    <section>
      <main className={styles.ApplyVoucher}>
        <Field
          component={Input}
          name={"voucherCode"}
          placeholder={"Gift card or discount code"}
        />
        <button className={styles.ButtonSecondary} onClick={onClick}>
          Apply
        </button>
      </main>
      <footer>
        {error && <div className={styles.ErrorMessage}>{error}</div>}
        {success && (
          <div className={styles.SuccessMessage}>Voucher code applied!</div>
        )}
      </footer>
    </section>
  );
};

const ApplyVoucher = (props) => {
  return <Component {...props} />;
};

export default ApplyVoucher;
