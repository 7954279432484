export const initialState = {
  currentFile: "terms",
  files: {},
  settings: {}
};

const actionHandlers = {
  LOGOUT: (state, payload) => initialState,
  CHANGE_FILE: (state, currentFile) => ({
    ...state,
    currentFile
  }),
  SAVE_STATIC_FILE_FULFILLED: (state, { file, body }) => ({
    ...state,
    files: { ...state.files, [file]: { body } }
  }),
  GET_ADMIN_DATA_FULFILLED: (state, { data: { files, settings } }) => {
    return {
      ...state,
      files: { ...files },
      settings: { ...settings.settings }
    };
  },
  UPDATE_SETTINGS_FULFILLED: (state, { settings }) => ({
    ...state,
    settings
  })
};

export const reducer = (state = initialState, { type, payload }) =>
  actionHandlers[type] ? actionHandlers[type](state, payload) : state;

export default reducer;
