import React from "react";
import CheckoutSection from "Payment/CheckoutSection";
import CheckBox from "CheckBox";
import { Field } from "react-final-form";
import { TwoColInput, Select } from "FormStuff";
import styles from "../DeliveryAddress/styles.module.scss";
import { COUNTRIES_FOR_SELECT } from "listOfCountries";

const Main = ({ billingAddressSame }) => {
  return (
    <div>
      <Field
        name="billingAddressSame"
        id="billingAddressSame"
        type="checkbox"
        label="Same as delivery address"
        component={CheckBox}
      />
      {!billingAddressSame && (
        <div className={styles.Main}>
          <br />
          <div className={styles.SelectContainer}>
            <Field
              component={Select}
              name="billing-address.country"
              options={COUNTRIES_FOR_SELECT}
            />
          </div>
          <div>
            <Field
              component={TwoColInput}
              name="billing-address.line-1"
              placeholder="Address line 1"
            />
          </div>
          <div>
            <Field
              component={TwoColInput}
              name="billing-address.line-2"
              placeholder="Address line 2"
            />
          </div>
          <div>
            <Field
              component={TwoColInput}
              name="billing-address.city"
              placeholder="City"
            />
          </div>
          <div>
            <Field
              component={TwoColInput}
              name="billing-address.postcode"
              placeholder="Postcode"
            />
          </div>
        </div>
      )}
    </div>
  );
};

const Header = () => {
  return <span>Billing Address</span>;
};

export const Component = (props) => {
  return (
    <CheckoutSection Header={Header}>
      <Main {...props} />
    </CheckoutSection>
  );
};

const BillingAddress = (props) => {
  return <Component {...props} />;
};

export default BillingAddress;
