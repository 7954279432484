import React from "react";
import styles from "./styles.module.scss";

export const List = ({ components = [] }) => (
  <ul className={styles.List}>
    {components.map((component, index) => (
      <li key={index}>{component}</li>
    ))}
  </ul>
);

export default List;
