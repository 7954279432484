import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import styles from "../styles.module.scss";
import { uploadFiles, makeFormDataWithFile } from "App/api";
import { changeImage } from "actions";
import Loading from "App/Loading";

const updateProfileSettings = (history) => (dispatch, getState) => {
  const body = makeFormDataWithFile(dispatch);
  const values = getState().form.Settings.values;
  body.append("firstName", values.firstName);
  body.append("secondName", values.secondName);
  body.append("bio", values.bio);
  body.append("username", values.username);

  return dispatch({
    type: "UPDATE_PROFILE",
    payload: uploadFiles({
      url: "/update-profile",
      token: getState().auth.token,
      body,
    }).then((data) => {
      history.push("/settings");
      return data;
    }),
  });
};

const ImageComponent = ({ onImageSelect }) => (
  <label htmlFor="profile-photo" className={styles.ChooseImage}>
    <h2>Upload a profile picture</h2>
    <input
      name="profile-photo"
      id="profile-photo"
      type="file"
      accept=".jpg, .png, .jpeg"
      onChange={onImageSelect}
    />
  </label>
);

export const Settings = ({ onSubmit, history, onImageSelect, file }) => (
  <Loading message="Updating profile...">
    <form
      className={styles.Settings}
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit(history);
      }}
    >
      <label htmlFor="username">username</label>
      <Field name="username" type="text" component="input" />
      <label htmlFor="bio">bio</label>
      <Field name="bio" type="text" component="textarea" />
      <label htmlFor="firstName">first name</label>
      <Field name="firstName" type="text" component="input" />
      <label htmlFor="secondName">second name</label>
      <Field name="secondName" type="text" component="input" />
      <img src={file} alt={"the file you are uploading"} />
      <Field
        name="profile-photo"
        component={ImageComponent}
        onImageSelect={onImageSelect}
      />
      <div>
        <button className={styles.ButtonPrimary} type="submit">
          Save
        </button>
      </div>{" "}
    </form>
  </Loading>
);

const mapStateToProps = ({ profile, file }) => ({
  initialValues: profile,
  file: file || profile.profilePicture,
});

const mapDispatchToProps = {
  onSubmit: updateProfileSettings,
  onImageSelect: changeImage,
};

const Form = reduxForm({ form: "Settings" })(Settings);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Form));
