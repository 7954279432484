import React from "react";
import { connect } from "react-redux";
import styles from "./styles.scss";

export const UserDashboard = () => (
  <div className={styles.UserDashboard}>
    <h2>10 sales this week</h2>
    <h2>most popular item of yours: "SOME ITEM"</h2>
  </div>
);

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(UserDashboard);
