export const initialState = false;

const actionHandlers = {
  LOGOUT: (state, payload) => initialState,
  HIDE_MENU: (state, payload) => initialState,
  TOGGLE_MENU: (state, payload) => !state
};

export const reducer = (state = initialState, { type, payload }) =>
  actionHandlers[type] ? actionHandlers[type](state, payload) : state;

export default reducer;
