import { useState, useEffect } from "react";
import { createClient } from "contentful";
import _ from "lodash";

const SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
const ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
const CONTENTFUL_URL = process.env.REACT_APP_CONTENTFUL_URL;

function useContentful(content_type, initialState = {}) {
  const [contentfulData, setContentfulData] = useState(initialState);
  useEffect(() => {
    if (!SPACE_ID || !ACCESS_TOKEN) {
      console.log("please configure .env file");
    }

    let contentful = createClient({
      space: SPACE_ID,
      accessToken: ACCESS_TOKEN,
      host:
        CONTENTFUL_URL || window.location.origin.includes("makethread.com")
          ? "cdn.contentful.com"
          : "preview.contentful.com",
    });

    const getData = async () => {
      let result = await contentful.getEntries({
        content_type,
        limit: 1,
      });
      setContentfulData(result.items[0].fields);
    };
    getData();
  }, [content_type]);

  return contentfulData;
}

export const useLandingPage = () => {
  const data = useContentful("landingPage");
  if (_.isEmpty(data)) {
    return;
  }
  const photoKeys = [
    "heroImage",
    "spotlightPhoto",
    "spotlightProfilePicture",
    "wornByBottomPhoto",
    "wornByLeftPhoto",
    "wornByTopPhoto",
    "learnMoreLeftPhoto",
    "learnMoreRightPhoto",
    "learnMoreMiddlePhoto",
  ];
  const photos = photoKeys.reduce((acc, next) => {
    acc[next] = data[next].fields.file.url;
    return acc;
  }, {});
  return {
    ...data,
    ...photos,
  };
};

export default useContentful;

export const useDocuments = () => {
  const data = useContentful("pdfDocuments");
  return data;
};

export const useBannerText = () => {
  const data = useContentful("bannerText");
  return (
    data?.text ||
    "Zero waste • Manufacture on demand • 100% sustainable clothing"
  );
};
