import React from "react";
import styles from "./styles.module.scss";
import FeatherIcon from "feather-icons-react";
import { useToggle } from "hooks";

export const Component = ({ open, toggle }) => {
  return (
    <div className={styles.Container}>
      <button className={styles.ShareButton} onClick={toggle}>
        <FeatherIcon size={12} icon={"share-2"} />
        Share
      </button>
      {open && (
        <div className={styles.ShareMenu}>
          <div>
            <FeatherIcon size={18} icon={"facebook"} />
          </div>
          <div>
            <FeatherIcon size={18} icon={"instagram"} />
          </div>
          <div>
            <FeatherIcon size={18} icon={"phone"} />
          </div>
          <div>
            <FeatherIcon size={18} icon={"mail"} />
          </div>
        </div>
      )}
    </div>
  );
};

const Share = () => {
  const [open, toggle] = useToggle(false);
  return <Component open={open} toggle={toggle} />;
};

export default Share;
