import Breadcrumbs from "Breadcrumbs";
import Share from "Share";
import React from "react";
import styles from "./styles.module.scss";
import Filters from "Filters";
import SortBy from "SortBy";

const BreadcrumbBanner = () => {
  return (
    <div className={styles.BreadcrumbsAndShare}>
      <Breadcrumbs />

      <div className={styles.Right}>
        <div className={styles.FiltersAndSortBy}>
          {/* <Filters /> */}
          {/* <SortBy /> */}
        </div>
        <Share />
      </div>
    </div>
  );
};
export default BreadcrumbBanner;
