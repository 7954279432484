import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import OnRouteChange from "./OnRouteChange";
import { connect } from "react-redux";
import Errors from "App/Errors";
import Popup from "Popup";
import CookieBanner from "CookieBanner";
import Header from "Header";
import Nav from "Nav";
import Banner from "Banner";
import Footer from "Footer";
import { ContentfulProvider, ContentfulClient } from "react-contentful";
import LazyLoad from "react-lazyload";
import BottomMenu from "BottomMenu";

const SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
const ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;

const contentfulClient = new ContentfulClient({
  accessToken: ACCESS_TOKEN,
  space: SPACE_ID,
});

export const Router = ({ isLoggedIn, onSwipeLeft }) => (
  <BrowserRouter>
    <ContentfulProvider client={contentfulClient}>
      <OnRouteChange>
        <Banner />
        <Popup />
        <Errors />
        <CookieBanner />
        <Header />
        <Nav />
        <Routes isLoggedIn={isLoggedIn} />
        <LazyLoad>
          <Footer />
        </LazyLoad>
        <BottomMenu />
      </OnRouteChange>
    </ContentfulProvider>
  </BrowserRouter>
);

const mapStateToProps = ({ auth }) => ({
  isLoggedIn: Boolean(auth.token),
});

export default connect(mapStateToProps)(Router);
