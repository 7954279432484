import { sku } from "helpers";

export const initialState = {};

const actionHandlers = {
  LOGOUT: (state) => initialState,
  ADD_TO_BASKET: (state, item) => {
    return { ...state, [sku(item)]: item };
  },
  UPDATE_BASKET: (state, { oldProduct, newProduct }) => {
    const newState = { ...state };
    delete newState[sku(oldProduct)];
    newState[sku(newProduct)] = newProduct;
    return newState;
  },
  REMOVE_FROM_BASKET: (state, id) => {
    const newState = { ...state };
    delete newState[id];
    return newState;
  },
  CLEAR_BASKET: (state) => initialState,
  PURCHASE_COMPLETED: (state) => initialState,
};

export const reducer = (state = initialState, { type, payload }) =>
  actionHandlers[type] ? actionHandlers[type](state, payload) : state;

export default reducer;
