import React from "react";
import CheckoutSection from "Payment/CheckoutSection";
import BagItem from "BagItem";
import List from "Shared/List";
import { Link } from "react-router-dom";
import ApplyVoucher from "ApplyVoucher";
import styles from "./styles.module.scss";
import { useBasket } from "hooks";
import { toCurrency } from "helpers";
import { NEW_SHIPPING_COUNTRIES } from "config";

const BasketTotal = ({ subTotal, discount, postage, total }) => {
  return (
    <section className={styles.BasketTotal}>
      <header>total</header>
      <main>
        <ul>
          <li>
            <span>Sub total:</span>
            <span className={styles.SmallPrice}>{toCurrency(subTotal)}</span>
          </li>
          <li>
            <span>Discount:</span>
            <span>{discount ? `-${toCurrency(discount)}` : ""}</span>
          </li>
          <li>
            <span>POSTAGE </span>
            {postage === 0 ? (
              <span>Free delivery</span>
            ) : (
              <span className={styles.SmallPrice}>{toCurrency(postage)}</span>
            )}
          </li>
          <li>
            <span>Total </span>
            <span className={styles.SmallPrice}>{toCurrency(total)}</span>
          </li>
        </ul>
      </main>
    </section>
  );
};

const Main = ({
  basket = [],
  total,
  discount,
  postage,
  subTotal,
  applyVoucher,
  voucherError,
  voucherData = {},
}) => (
  <div>
    <List
      components={basket.map((b) => (
        <BagItem product={b} readOnly={true} key={b._id} />
      ))}
    />
    <ApplyVoucher
      onClick={applyVoucher}
      error={voucherError}
      success={voucherData.voucherIsValid}
    />
    <BasketTotal
      basket={basket}
      total={total}
      discount={discount}
      postage={postage}
      subTotal={subTotal}
    />
  </div>
);

const Header = ({ quantity = 0 }) => {
  return (
    <>
      <span>{quantity} item(s)</span>
      <Link to="/basket">edit</Link>
    </>
  );
};

export const Component = ({ quantity, ...props }) => {
  return (
    <CheckoutSection
      Header={() => <Header quantity={quantity} />}
      expandable={false}
    >
      <Main {...props} />
    </CheckoutSection>
  );
};

const calculateDiscount = ({ voucherIsValid, voucherData } = {}, subTotal) => {
  if (!voucherIsValid) return 0;
  return (subTotal * (1 - voucherData.percentage)).toFixed(2);
};

const calclatePostage = (country, subTotal) => {
  const LIMIT = 50000;
  if (subTotal >= LIMIT) {
    return 0;
  }
  if (!country) {
    return;
  }
  return NEW_SHIPPING_COUNTRIES[country];
};

const Basket = (parentProps) => {
  const props = useBasket();
  const discount = calculateDiscount(parentProps.voucherData, props.subTotal);
  const postage = calclatePostage(parentProps.country, props.subTotal);
  const total = props.subTotal - discount + postage;

  return (
    <Component
      {...props}
      {...parentProps}
      discount={discount}
      total={total}
      postage={postage}
    />
  );
};

export default Basket;
